<div
  #tableHeader
  class="flex cursor-pointer flex-row items-center"
  tabindex="0"
  (click)="emitSort()">
  <ng-content></ng-content>
  @if (sortParams()) {
    <span class="material-symbols-rounded pl-1 text-inherit">
      {{
        sortState === SortOrder.ASC
          ? 'arrow_downward_alt'
          : sortState === SortOrder.DESC
            ? 'arrow_upward_alt'
            : 'height'
      }}
    </span>
  }
</div>

import { IGetGateway, ISaveGateway } from '../../gateways';
import { inject } from '@angular/core';
import { ClientService } from '../../client/client.service';
import { RestCallBuilder } from '../../../builder/rest/rest.builder';
import { Observable } from 'rxjs';
import {
  defaultLoginLogout,
  ILoginLogout,
  LoginLogoutSchema,
} from '../../../entities/login-logout.entity';

export class LoginLogoutGateway
  implements
    IGetGateway<null, ILoginLogout>,
    ISaveGateway<ILoginLogout, ILoginLogout>
{
  private clientService = inject(ClientService);
  private client = this.clientService.client;
  private restCallBuilder: RestCallBuilder<ILoginLogout> =
    new RestCallBuilder<ILoginLogout>(defaultLoginLogout);

  get(): Observable<ILoginLogout> {
    return this.restCallBuilder
      .get('/login-logout/' + this.client)
      .validate(LoginLogoutSchema)
      .handleErrors()
      .build();
  }

  save(query: ILoginLogout): Observable<ILoginLogout> {
    return this.restCallBuilder
      .post('/login-logout/', query)
      .validate(LoginLogoutSchema)
      .handleErrors()
      .build();
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'centsToEuros',
  standalone: true,
})
export class CentsToEurosPipe implements PipeTransform {
  transform(value: number, currency = '€'): string {
    const prefix = value < 0 ? '-' : '';
    const space = ' ';
    const separator = ',';
    if (!value || typeof value !== 'number') {
      return '0' + separator + '00' + space + currency;
    }
    //we only accept values in cents so we disregard further fractions
    const strippedValue = (value + '').includes('.')
      ? +(value + '').split('.')[0]
      : value;

    const centsString = strippedValue
      .toString()
      .replace('-', '')
      .replace('+', '');
    const cents =
      centsString.length > 1
        ? centsString[centsString.length - 2] +
          centsString[centsString.length - 1]
        : '0' + centsString;
    const euros = centsString.slice(0, centsString.length - 2) || '0';
    return prefix + euros + separator + cents + space + currency;
  }
}

import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from '../dialog/dialog.component';
import { ICombinedTicket } from '../../../services/tickets/combined-tickets.service';
import { Dialog } from '../dialog';

@Component({
  selector: 'app-ticket-delete-dialog',
  imports: [CommonModule, DialogComponent],
  templateUrl: './ticket-delete-dialog.component.html',
})
export class TicketDeleteDialogComponent extends Dialog<ICombinedTicket> {}

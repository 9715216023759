import { IText } from './text-entity';
import { ITicket } from './ticket-entity';

export interface ITicketsOverview {
  id?: string;
  client: string;
  headline: IText[];
  tickets: ITicket[];
}

export const defaultTicketsOverview: ITicketsOverview = {
  client: '',
  headline: [{ value: '', isoCode: 'deu' }],
  tickets: [],
};

import { inject, Injectable } from '@angular/core';
import { ProductGroup } from '../api/productGroups/productGroupsGateway';
import { ISubscription } from '../../entities/subscription-entity';
import { SortGroupsService } from '../api/sortGroups/sort-groups.service';
import { ProductGroupsService } from '../api/productGroups/product-groups.service';
import { SubscriptionsService } from '../api/subscriptions/subscriptions.service';
import { map, Observable, zip } from 'rxjs';
import { TicketsOverviewService } from '../api/ticketsOverview/tickets-overview.service';
import { ITicket } from '../../entities/ticket-entity';
import { ITicketsOverview } from '../../entities/tickets-overview-entity';

export enum TicketType {
  Subscription = 'Subscription',
  Direct = 'Direct-Ticket',
  All = 'Alle',
}

export enum TicketDataOption {
  Yes = 1,
  No = 0,
  All = 'Alle',
}

// combines sortGroup/productGroup, subscription and marketingdata(ITicket)
export interface ICombinedTicket {
  name: string;
  id: string;
  startDate?: string;
  endDate?: string;
  isActive: boolean;
  type: TicketType;
  data?: ITicket;
}

@Injectable({
  providedIn: 'root',
})
export class CombinedTicketsService {
  private sortGroupService: SortGroupsService = inject(SortGroupsService);
  private subscriptionsService: SubscriptionsService =
    inject(SubscriptionsService);
  private productGroupsService: ProductGroupsService =
    inject(ProductGroupsService);
  private ticketsOverviewService: TicketsOverviewService = inject(
    TicketsOverviewService
  );

  constructor() {
    this.subscriptionsService.list$.next({
      guid: '',
      includeFixedPriceTickets: true,
      includeInActive: true,
    });
    this.ticketsOverviewService.getTicketsOverview();
    this.sortGroupService.list$.next(null);
    this.productGroupsService.list$.next(null);
  }

  public $tickets: Observable<ICombinedTicket[]> = zip([
    this.subscriptionsService.$entities,
    this.productGroupsService.$productGroups,
    this.sortGroupService.$sortGroupNames,
    this.ticketsOverviewService.$entity,
  ]).pipe(
    map(([subscriptions, productGroups, sortGroups, ticketsOverview]) => {
      return sortGroups
        .map(sortGroup => this.getDirectTickets(sortGroup, productGroups))
        .reduce((acc, curr) => acc.concat(...curr), [])
        .concat(this.getSubscriptionTickets(subscriptions.data))
        .map(ticket => this.addTicketData(ticket, ticketsOverview));
    })
  );

  private getDirectTickets(
    sortGroup: string,
    productGroups: ProductGroup[]
  ): ICombinedTicket[] {
    const directMatches: ProductGroup[] = productGroups.filter(
      productGroup => productGroup.name === sortGroup
    );
    return directMatches.map((directTicket: ProductGroup) => {
      return {
        id: sortGroup,
        name: sortGroup,
        type: TicketType.Direct,
        startDate: directTicket.preSaleValidity.earliestDate,
        endDate: directTicket.preSaleValidity.latestDate,
        isActive:
          !!directTicket.preSaleValidity &&
          this.isTicketCurrentlyActive(
            new Date(),
            directTicket.preSaleValidity.earliestDate,
            directTicket.preSaleValidity.latestDate
          ),
      };
    });
  }

  private getSubscriptionTickets(
    subscriptions: ISubscription[]
  ): ICombinedTicket[] {
    return subscriptions.map(subscription => {
      return {
        id: subscription.guid,
        name: subscription.name,
        type: TicketType.Subscription,
        startDate: subscription.onlineSalePossibleFrom,
        isActive: subscription?.onlineSalePossibleFrom
          ? this.isTicketCurrentlyActive(
              new Date(),
              subscription.onlineSalePossibleFrom
            )
          : false,
      };
    });
  }

  private isTicketCurrentlyActive(
    now: Date,
    earliestDate: string | undefined = undefined,
    latestDate: string | undefined = undefined
  ): boolean {
    const errors = [];
    if (earliestDate && new Date(earliestDate).getTime() > now.getTime()) {
      errors.push('startInFuture');
    }
    if (latestDate && new Date(latestDate).getTime() < now.getTime()) {
      errors.push('endedInPast');
    }
    return errors.length === 0;
  }

  private addTicketData(
    currentTicket: ICombinedTicket,
    overview: ITicketsOverview
  ): ICombinedTicket {
    const match = overview.tickets.find(
      ticket => currentTicket.id === ticket.ticketId
    );
    return {
      ...currentTicket,
      data: {
        ...match,
        active:
          match?.active !== null && match?.active !== undefined
            ? match!.active
            : currentTicket.isActive,
        startDate: match?.startDate || currentTicket.startDate,
        endDate: match?.endDate || currentTicket.endDate,
        client: overview.client!,
        ticketId: currentTicket.name,
        overviewId: overview.id!,
      } as ITicket,
    };
  }
}

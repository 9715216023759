import { Injectable } from '@angular/core';
import {
  ProductGroup,
  ProductGroupResponse,
  ProductGroupsGateway,
} from './productGroupsGateway';
import { CRUDService } from '../../crud/crud.service';
import { map, Observable } from 'rxjs';
import { IWrapper } from '../../../entities/sort-groups-entity';

@Injectable({
  providedIn: 'root',
})
export class ProductGroupsService extends CRUDService<
  ProductGroup,
  IWrapper<ProductGroupResponse>,
  never,
  null,
  never,
  never
> {
  constructor() {
    super(new ProductGroupsGateway());
  }

  public $productGroups: Observable<ProductGroup[]> = this.$entities.pipe(
    map(productGroups => productGroups.data?.[0].productGroups ?? [])
  );
}

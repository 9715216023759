import { z, ZodSchema } from 'zod';
import { FormControl } from '@angular/forms';
import { IText, TextSchema } from './text-entity';

export const ImageSchema: ZodSchema = z.object({
  id: z.string().uuid().nullable(),
  filename: z.string(),
  fileExtension: z.string(),
});
export interface IImage {
  id?: string;
  filename: string;
  fileExtension: string;
}
export interface IImageForm {
  id: FormControl<string | null>;
  filename: FormControl<string>;
  fileExtension: FormControl<string>;
}
export const defaultImage = { filename: '', fileExtension: '' };

export const AccessibleImageSchema: ZodSchema = z.object({
  id: z.string().uuid().nullable(),
  imageAlts: z.array(TextSchema),
  image: ImageSchema.nullable(),
  url: z.string().nullable().optional(),
});
export interface IAccessibleImage {
  id?: string;
  imageAlts: IText[];
  image: IImage | null;
  url?: string;
}
export interface IAccessibleImageForm {
  id: FormControl<string | null>;
  imageAlts: FormControl<IText[]>;
  image: FormControl<IImage | null>;
  url: FormControl<string | null>;
}
export const defaultAccessibleImage: IAccessibleImage = {
  imageAlts: [],
  image: null,
};

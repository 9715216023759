@if (images()) {
  <div
    class="form-input__background"
    [ngClass]="{
      'form-input__disabled': ngControl.control!.disabled,
    }">
    @let hasError = ngControl.errors && ngControl.touched;
    <div class="form-input__container">
      <input
        [attr.data-testid]="id() | appendTestId: 'input'"
        autocomplete="off"
        [formControl]="ngControl.control! | toFormControl"
        class="form-input peer"
        [ngClass]="{
          'form-input__error-ring': hasError,
        }"
        [id]="id()"
        [placeholder]="label() ?? ''"
        (focus)="showList.set(true)"
        type="text" />
      <label
        [attr.data-testid]="id() | appendTestId: 'label'"
        class="form-input__floating-label"
        [ngClass]="{ 'form-input__error-text': hasError }"
        [for]="id()">
        {{ label() }}
      </label>
      @if (ngControl.value) {
        <div
          [attr.data-testid]="id() | appendTestId: 'value'"
          class="pointer-events-none absolute left-1 right-5 top-2 flex items-center gap-2 truncate bg-inherit px-1">
          <img
            [src]="getThumbnailPath(ngControl.value.id)"
            alt="Image Preview"
            class="h-6" />
          {{ ngControl.value.filename }}
        </div>
        <span
          class="material-symbols-rounded absolute bottom-0 right-2 top-0 z-10 flex cursor-pointer items-center bg-inherit text-xl"
          [ngClass]="{
            'pointer-events-none cursor-not-allowed':
              ngControl.control!.disabled,
          }"
          [attr.data-testid]="id() | appendTestId: 'clear'"
          (click)="ngControl.control!.setValue(undefined)">
          close
        </span>
      }
      <ul
        [attr.data-testid]="id() | appendTestId: 'options'"
        [ngClass]="{ hidden: !showList() }"
        class="form-input__dropdown-list">
        @for (image of images(); track image.id; let index = $index) {
          <li
            class="form-input__dropdown-list--hover flex cursor-pointer items-center gap-2"
            (click)="selectImage(image)">
            <img
              [src]="getThumbnailPath(image.id)"
              alt="Image Preview"
              class="h-10" />
            {{ image.filename }}
          </li>
        }
        @if (images()!.length === 0) {
          <div
            class="px-4 py-2"
            [attr.data-testid]="id() | appendTestId: 'option'">
            Keine passenden Ergebnisse...
          </div>
        }
      </ul>
      @if (hasError && !errorTemplate()) {
        <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
          <ng-content></ng-content>
        </app-error-message>
      }
      @if (hasError && errorTemplate()) {
        <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
          <ng-container *ngTemplateOutlet="errorTemplate()!"></ng-container>
        </app-error-message>
      }
    </div>
  </div>
}

<div
  class="absolute bottom-0 left-0 right-0 top-0 z-10 flex flex-row items-center justify-center bg-black/25">
  <div
    class="relative rounded-xl border-2 border-gray-500 bg-white sm:w-full md:w-8/12">
    <div class="flex flex-row justify-between p-4">
      <div class="text-xl font-semibold">{{ title() }}</div>

      <span
        class="material-symbols-rounded cursor-pointer text-3xl"
        (click)="cancel()">
        close
      </span>
    </div>
    @if (content()) {
      <div class="p-4">
        <ng-container *ngTemplateOutlet="content()!"></ng-container>
      </div>
    }
    <div class="flex flex-row justify-between p-4">
      @if (!footer()) {
        <ng-container>
          <app-button
            [type]="'button'"
            (click)="cancel()"
            [style]="'secondary'"
            [text]="'Abbrechen'"></app-button>
          <app-button
            [type]="'button'"
            (click)="submit()"
            [text]="'Weiter'"></app-button>
        </ng-container>
      } @else {
        <ng-container>
          <ng-container *ngTemplateOutlet="footer()!"></ng-container>
        </ng-container>
      }
    </div>
  </div>
</div>

<app-ticket-frame [styling]="styling().frame" [attr.data-testid]="id()">
  <div upper>
    <div
      class="pb-3 text-3xl font-medium"
      [attr.data-testid]="id() | appendTestId: 'title'"
      [attr.aria-label]="ticket().title">
      {{ ticket().title | language }}
    </div>
    <div class="pb-3">
      @if (ticket().viaBanner === 'chip') {
        <app-via-chip
          [via]="ticket().via"
          [attr.data-testid]="id() | appendTestId: 'via-chip'">
        </app-via-chip>
      } @else if (ticket().viaBanner === 'list') {
        <app-via-list
          [via]="ticket().via"
          [attr.data-testid]="id() | appendTestId: 'list'">
          ></app-via-list
        >
      } @else {
        <app-via-banner
          [attr.data-testid]="id() | appendTestId: 'banner'"
          [bannerOverflow]="styling().frame.borderWidth"
          [via]="ticket().via"></app-via-banner>
      }
    </div>
    @if (ticket().price) {
      <app-chip
        [id]="id() | appendTestId: 'price'"
        [text]="
          ticket().price! | centsToEuros: 'EUR' + ticket().afterPriceText
        ">
      </app-chip>
    }
    <div
      class="text-base"
      [attr.data-testid]="id() | appendTestId: 'description'"
      [attr.aria-label]="ticket().description">
      {{ ticket().description | language }}
    </div>
  </div>
  <div lower>
    @if (ticket().details.length > 0) {
      <app-extendable-text
        [title]="ticket().detailsTitle | language"
        [id]="id() | appendTestId: 'extendable-test'">
        <app-simple-list
          [id]="id() | appendTestId: 'benefits-list'"
          [items]="ticket().details"></app-simple-list>
      </app-extendable-text>
    }
    <button
      type="button"
      [attr.data-testid]="id() | appendTestId: 'button'"
      [style.background-color]="'#3E4C7C'"
      [style.color]="'#ffffff'"
      class="mt-3 flex w-full cursor-pointer items-center justify-center rounded-lg px-5 py-3 text-base">
      {{ ticket().buttonText | language }}
    </button>
  </div>
</app-ticket-frame>

import { Pipe, PipeTransform } from '@angular/core';
import { IButtonOption } from '../../components/form-inputs/button-group/button-group.component';

@Pipe({
  name: 'enumToButtonOptionArray',
  standalone: true,
})
export class EnumToButtonOptionArrayPipe implements PipeTransform {
  transform(enumObj: any): IButtonOption<any>[] {
    return Object.keys(enumObj).map(k => {
      return {
        label: k,
        value: enumObj[k],
      };
    });
  }
}

import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { ClientService } from '../../client/client.service';
import { IGetGateway, IListGateway, ISaveGateway } from '../../gateways';
import { RestCallBuilder } from '../../../builder/rest/rest.builder';
import {
  defaultTicket,
  ITicket,
  TicketSchema,
  TicketSchemas,
} from '../../../entities/ticket-entity';

export class TicketGateway
  implements
    IGetGateway<string, ITicket>,
    IListGateway<string, ITicket[]>,
    ISaveGateway<ITicket, ITicket>
{
  private clientService = inject(ClientService);
  private client = this.clientService.client;
  private restCallBuilder: RestCallBuilder<ITicket> =
    new RestCallBuilder<ITicket>(defaultTicket);
  private listRestCallBuilder: RestCallBuilder<ITicket[]> = new RestCallBuilder<
    ITicket[]
  >([]);

  get(): Observable<ITicket> {
    return this.restCallBuilder
      .get('/tickets/' + this.client)
      .validate(TicketSchema)
      .handleErrors()
      .build();
  }

  list(): Observable<ITicket[]> {
    return this.listRestCallBuilder
      .get('/tickets/' + this.client)
      .validate(TicketSchemas)
      .handleErrors()
      .build();
  }

  save(query: ITicket): Observable<ITicket> {
    return this.restCallBuilder
      .post('/tickets/', query)
      .validate(TicketSchema)
      .handleErrors()
      .build();
  }
}

import { Injectable } from '@angular/core';
import { SortGroupsGateway } from './sortGroupsGateway';
import { ISortGroups, IWrapper } from '../../../entities/sort-groups-entity';
import { CRUDService } from '../../crud/crud.service';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SortGroupsService extends CRUDService<
  ISortGroups,
  IWrapper<ISortGroups>,
  never,
  null,
  never,
  never
> {
  constructor() {
    super(new SortGroupsGateway());
  }

  public $sortGroupNames: Observable<string[]> = this.$entities.pipe(
    map(sortGroups => sortGroups.data?.[0]?.sortGroupNames || [])
  );
}

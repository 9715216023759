import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorMessageComponent } from '../../error-message/error-message.component';
import {
  ControlContainer,
  FormGroupDirective,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { FormInputComponent } from '../form-input.component';
import { ToFormControlPipe } from '../../../pipes/to-form-control/to-form-control.pipe';
import { AppendTestIdPipe } from '../../../pipes/append-test-id/append-test-id.pipe';

@Component({
  selector: 'app-checkbox',
  imports: [
    CommonModule,
    ErrorMessageComponent,
    ReactiveFormsModule,
    ToFormControlPipe,
    AppendTestIdPipe,
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  styleUrl: '../form-inputs.css',
  templateUrl: './checkbox.component.html',
})
export class CheckboxComponent extends FormInputComponent {
  constructor(public override ngControl: NgControl) {
    super(ngControl);
  }

  /*
   * Necessary so clicking the label also change the controls value
   * touched needs to be set too, since type=checkbox does not propagate it properly
   * */
  public toggle(): void {
    if (this.ngControl.control && !this.ngControl.control!.disabled) {
      this.ngControl.control!.setValue(!this.ngControl.value);
      this.ngControl.control!.markAsTouched();
    }
  }
}

<app-content-header></app-content-header>
@if (corporateDesign()) {
  <div class="p-6">
    <app-color-picker-input
      [formControl]="backgroundColor"
      [id]="'ticket-background-color-picker'"
      label="Ticket Background Color"></app-color-picker-input>
    <app-number-input
      [formControl]="borderWidth"
      [id]="'ticket-border-width-input'"
      label="Ticket Border Width"></app-number-input>
  </div>
  <div class="-m-1 flex flex-row flex-wrap px-6 py-8">
    @for (ticket of fakeTickets; track $index; let index = $index) {
      <app-ticket-tile
        class="w-6/12 p-1"
        [id]="'ticket-' + index"
        [ticket]="ticket"
        [styling]="ticketStyle"></app-ticket-tile>
    }
  </div>
  <div class="flex gap-5">
    <button
      (click)="testSortGroups()"
      class="border-1 rounded-{{ corporateDesign().buttonBorderRounding }}"
      type="button">
      Teste Sortengruppen
    </button>

    <button
      (click)="testSubscriptions()"
      class="bg-blue-600 text-white border-1 rounded-{{
        corporateDesign().buttonBorderRounding
      }}"
      type="button">
      Teste Abonnements
    </button>

    <button
      (click)="triggerError()"
      class="border-1 rounded-{{ corporateDesign().buttonBorderRounding }}"
      type="button">
      error trigger
    </button>
  </div>
}

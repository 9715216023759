import { Component, input } from '@angular/core';
import { NgClass } from '@angular/common';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-section',
  imports: [NgClass, ButtonComponent],
  templateUrl: './section.component.html',
})
export class SectionComponent {
  dark = input<boolean>(false);
  disabled = input<boolean>(false);
  collapsible = input<boolean>(false);

  isCollapsed = false;

  toggleCollapse(): void {
    if (this.collapsible()) {
      this.isCollapsed = !this.isCollapsed;
    }
  }
}

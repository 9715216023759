import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlContainer,
  FormGroupDirective,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { ErrorMessageComponent } from '../../error-message/error-message.component';
import {
  FormInputSelectComponent,
  SelectOption,
} from '../form-input-select.component';
import { ToSelectOptionAttributePipe } from '../../../pipes/to-select-option-attribute/to-select-option-attribute.pipe';
import { AppendTestIdPipe } from '../../../pipes/append-test-id/append-test-id.pipe';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-single-select',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    ToSelectOptionAttributePipe,
    AppendTestIdPipe,
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  styleUrl: '../form-inputs.css',
  templateUrl: './single-select.component.html',
})
export class SingleSelectComponent
  extends FormInputSelectComponent
  implements OnDestroy
{
  private readonly $handleKeyUpEvent: Subscription;

  constructor(public override ngControl: NgControl) {
    super(ngControl);
    this.$handleKeyUpEvent = fromEvent<
      KeyboardEvent & { target?: { id: string; value: SelectOption } }
    >(document, 'keyup').subscribe(event => {
      const index: number = +event.target?.id?.split('-')[2];
      const isCorrectTarget = event.target?.id === this.id() + '-' + index;
      if (event.key === 'Enter' && isCorrectTarget) {
        event.preventDefault();
        this.selectOption(this.options()[index]);
      }
    });
  }

  ngOnDestroy() {
    this.$handleKeyUpEvent.unsubscribe();
  }
}

import { Component, inject, OnDestroy, OnInit, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterService } from '../../../services/api/footer/footer.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import IFooter, {
  defaultFooter,
  IFooterForm,
} from '../../../entities/footer-entity';
import { toSignal } from '@angular/core/rxjs-interop';
import { SectionComponent } from '../../../components/section/section.component';
import { ButtonComponent } from '../../../components/button/button.component';
import { SectionHeaderComponent } from '../../../components/section/section-header/section-header.component';
import { SectionHeaderActionsComponent } from '../../../components/section/section-header/section-header-actions/section-header-actions.component';
import { SectionHeadlineComponent } from '../../../components/section/section-header/section-headline/section-headline.component';
import { ColorPickerInputComponent } from '../../../components/form-inputs/color-picker-input/color-picker-input.component';
import { ButtonGroupComponent } from '../../../components/form-inputs/button-group/button-group.component';
import { ToggleComponent } from '../../../components/form-inputs/toggle/toggle.component';
import { ImageSelectComponent } from '../../../components/form-inputs/image-select/image-select.component';
import { TextInputComponent } from '../../../components/form-inputs/text-input/text-input.component';
import { PaymentSelectComponent } from '../../../components/form-inputs/payment-select/payment-select.component';
import { Subscription, tap } from 'rxjs';
import { IAccessibleImageForm } from '../../../entities/image-entity';
import { ILinkForm } from '../../../entities/link-entity';
import { TranslationTextInputComponent } from '../../../components/form-inputs/translation-text-input/translation-text-input.component';
import { LanguageSwitcherComponent } from '../../../components/form-inputs/language-switcher/language-switcher.component';
import { TranslationTextAreaComponent } from '../../../components/form-inputs/translation-text-area/translation-text-area.component';
import { TranslationAccessibilityTextInputComponent } from '../../../components/form-inputs/translation-accessibility-text-input/translation-accessibility-text-input.component';
import { FooterFormsFactoryService } from '../../../factory/footer/footer-forms-factory.service';
import { EnumToButtonOptionArrayPipe } from '../../../pipes/enum-to-button-option-array/enum-to-button-option-array.pipe';
import { AccessibilityBannerComponent } from '../../../components/accessibility-banner/accessibility-banner-component';
import { PopupService } from '../../../services/popup/popup.service';
import { CMSBorderColors } from '../../../constants/buttonGroups.enums';
import { ButtonGroupSupportService } from '../../../services/support/button-group-support.service';

@Component({
  selector: 'app-footer-page',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SectionComponent,
    ButtonComponent,
    SectionHeaderComponent,
    SectionHeaderActionsComponent,
    SectionHeadlineComponent,
    ColorPickerInputComponent,
    ButtonGroupComponent,
    ToggleComponent,
    TextInputComponent,
    ImageSelectComponent,
    PaymentSelectComponent,
    TranslationTextInputComponent,
    LanguageSwitcherComponent,
    TranslationTextAreaComponent,
    TranslationAccessibilityTextInputComponent,
    EnumToButtonOptionArrayPipe,
    AccessibilityBannerComponent,
  ],
  templateUrl: './footer-page.component.html',
  styles: ``,
})
export class FooterPageComponent implements OnInit, OnDestroy {
  buttonGroupSupportService = inject(ButtonGroupSupportService);
  popupService = inject(PopupService);
  footerService = inject(FooterService);
  formBuilder = inject(FormBuilder);
  footerFormsFactory = inject(FooterFormsFactoryService);

  protected readonly footerBorderColors = CMSBorderColors;
  public readonly backgroundColorBtn: FormControl<string> =
    new FormControl<string>('') as FormControl<string>;
  public footerForm!: FormGroup<IFooterForm>;
  public languageForm: FormGroup = new FormGroup({
    singleSelect: new FormControl('deu', [Validators.required]),
  });

  footer: Signal<IFooter> = toSignal(
    this.footerService.$entity.pipe(
      tap(footer => {
        this.footerForm = this.footerFormsFactory.buildForm(footer);
        this.setBackgroundColorBtnState(footer);
      })
    )
  ) as Signal<IFooter>;
  savedFooter: Signal<IFooter> = toSignal(
    this.footerService.$savedEntity.pipe(
      tap(footer => {
        this.footerForm = this.footerFormsFactory.buildForm(footer);
        this.setBackgroundColorBtnState(footer);
      })
    )
  ) as Signal<IFooter>;

  protected showPopupChanges = false;
  protected showPopupToFactorySettings = false;
  private readonly sub = new Subscription();

  constructor() {
    this.footerService.get$.next(null);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  ngOnInit(): void {
    window.onbeforeunload = (event: BeforeUnloadEvent) => {
      if (this.footerForm.dirty) {
        event.preventDefault();
      }
    };
    this.sub.add(
      this.popupService.popupActionChanges$.subscribe(action => {
        this.resetFormChanges(action);
      })
    );
    this.sub.add(
      this.popupService.popupActionToFactory$.subscribe(() => {
        this.resetToFactorySettings();
      })
    );
  }

  addLogo() {
    this.footerForm.controls.logoArea.controls.accessibleImages.push(
      this.formBuilder.group<IAccessibleImageForm>({
        id: this.formBuilder.control(null),
        image: this.formBuilder.control(null),
        imageAlts: this.formBuilder.nonNullable.control([]),
        url: this.formBuilder.control<string>('', []),
      })
    );
  }

  deleteLogo(index: number) {
    this.footerForm.controls.logoArea.controls.accessibleImages.removeAt(index);
  }

  addPayment() {
    this.footerForm.controls.paymentArea.controls.accessibleImages.push(
      this.formBuilder.group<IAccessibleImageForm>({
        id: this.formBuilder.control(null),
        image: this.formBuilder.control(null),
        imageAlts: this.formBuilder.nonNullable.control([]),
        url: this.formBuilder.control<string>('', []),
      })
    );
  }

  deletePayment(index: number) {
    this.footerForm.controls.paymentArea.controls.accessibleImages.removeAt(
      index
    );
  }

  addLink() {
    this.footerForm.controls.linkArea.controls.links.push(
      this.formBuilder.group<ILinkForm>({
        id: this.formBuilder.control(null),
        urls: this.formBuilder.nonNullable.control([]),
        texts: this.formBuilder.nonNullable.control([]),
      })
    );
  }

  deleteLink(index: number) {
    this.footerForm.controls.linkArea.controls.links.removeAt(index);
  }

  onBackgroundColorChange(value: string): void {
    switch (value) {
      case CMSBorderColors.Benutzerdefiniert:
        this.footerForm.controls.backgroundColor.enable();
        break;
      case CMSBorderColors.Keine:
        this.footerForm.controls.backgroundColor.setValue(
          CMSBorderColors.Keine
        );
        this.footerForm.controls.backgroundColor.disable();
        break;
    }
  }

  setBackgroundColorBtnState(footer: IFooter): void {
    this.backgroundColorBtn.setValue(
      this.buttonGroupSupportService.findValueOnEnum(
        footer.backgroundColor,
        CMSBorderColors,
        CMSBorderColors.Benutzerdefiniert
      )
    );
    this.onBackgroundColorChange(this.backgroundColorBtn.value);
  }

  save(): void {
    this.footerService.save$.next(
      this.footerFormsFactory.buildEntity(this.footerForm)
    );
  }

  showPopUpChanges() {
    this.popupService.showPopupChanges();
  }

  resetToFactorySettings() {
    this.footerForm.reset({
      id: this.footer().id,
      ...defaultFooter,
    });
    this.showPopupToFactorySettings = false;
  }

  resetFormChanges(reset: boolean) {
    if (reset) {
      this.footerForm.reset({
        client: this.footer().client,
        backgroundColor: this.footer().backgroundColor,
        logoArea: this.footer().logoArea,
        addressArea: this.footer().addressArea,
        paymentArea: this.footer().paymentArea,
        linkArea: this.footer().linkArea,
      });
    }
    this.showPopupChanges = false;
  }
}

import { z, ZodSchema } from 'zod';

import { FormControl, FormGroup } from '@angular/forms';
import { ILogoArea, ILogoAreaForm, LogoAreaSchema } from './logo-area-entity';
import {
  AddressAreaSchema,
  IAddressArea,
  IAddressAreaForm,
} from './address-area-entity';
import {
  IPaymentArea,
  IPaymentAreaForm,
  PaymentAreaSchema,
} from './payment-area-entity';
import { ILinkArea, ILinkAreaForm, LinkAreaSchema } from './link-area-entity';

export const FooterSchema: ZodSchema = z.object({
  id: z.string().uuid().nullable(),
  client: z.string(),
  backgroundColor: z.string(),
  linkArea: LinkAreaSchema.nullable(),
  logoArea: LogoAreaSchema.nullable(),
  addressArea: AddressAreaSchema.nullable(),
  paymentArea: PaymentAreaSchema.nullable(),
});
export default interface IFooter {
  id?: string;
  client: string;
  backgroundColor: string;
  logoArea: ILogoArea;
  addressArea: IAddressArea;
  paymentArea: IPaymentArea;
  linkArea: ILinkArea;
}
export interface IFooterForm {
  id: FormControl<string | null>;
  client: FormControl<string>;
  backgroundColor: FormControl<string>;
  logoArea: FormGroup<ILogoAreaForm>;
  addressArea: FormGroup<IAddressAreaForm>;
  paymentArea: FormGroup<IPaymentAreaForm>;
  linkArea: FormGroup<ILinkAreaForm>;
}

export const defaultFooter: IFooter = {
  client: '',
  logoArea: {
    toggle: true,
    accessibleImages: [],
    headlines: [],
  },
  addressArea: {
    toggle: true,
    texts: [
      {
        isoCode: 'deu',
        value:
          'Dieser Ticketshop wird betrieben von:\nMusterunternehmen GmbH\nMusterstraße 29\n012378 Musterstadt ',
      },
    ],
    headlines: [{ value: 'Anschrift', isoCode: 'deu' }],
  },
  paymentArea: {
    toggle: true,
    accessibleImages: [],
    headlines: [{ value: 'Mögliche Zahlungsarten', isoCode: 'deu' }],
  },
  linkArea: {
    links: [
      {
        urls: [
          {
            isoCode: 'deu',
            value: 'shop.amcon.de/impressum',
          },
        ],
        texts: [
          {
            isoCode: 'deu',
            value: 'Impressum',
          },
        ],
      },
      {
        urls: [
          {
            isoCode: 'deu',
            value: 'shop.amcon.de/datenschutz',
          },
        ],
        texts: [
          {
            isoCode: 'deu',
            value: 'Datenschutzerklärung',
          },
        ],
      },
      {
        urls: [
          {
            isoCode: 'deu',
            value: 'shop.amcon.de/kontakt',
          },
        ],
        texts: [
          {
            isoCode: 'deu',
            value: 'Kontakt',
          },
        ],
      },
      {
        urls: [
          {
            isoCode: 'deu',
            value: 'www.verkehsunternehmen.de/faq',
          },
        ],
        texts: [
          {
            isoCode: 'deu',
            value: 'FAQ',
          },
        ],
      },
    ],
    headlines: [{ value: 'Links', isoCode: 'deu' }],
  },
  backgroundColor: '#FFFFFF',
};

import { Component, input, InputSignal } from '@angular/core';
import { ISidebarItem } from '../../../../entities/sidebar/item.entity';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styles: '',
  imports: [RouterLink, NgClass, RouterLinkActive],
})
export class SidebarItemComponent {
  item: InputSignal<ISidebarItem> = input.required<ISidebarItem>();
  expanded: InputSignal<boolean> = input.required<boolean>();
}

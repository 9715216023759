<div class="form-input__background flex-col">
  @let hasError = ngControl.errors && ngControl.touched;

  <div
    class="form-input__container group"
    [ngClass]="{
      'form-input__disabled': ngControl.control!.disabled,
    }">
    @for (option of options(); track $index; let index = $index) {
      @let isValueSelected =
        ngControl.control!.value?.includes(
          option | toSelectOptionAttribute: value()
        );
      <label
        [attr.data-testid]="id() + index | appendTestId: 'label'"
        (change)="selectOption(option)"
        class="my-3 flex cursor-pointer items-center rounded-md bg-inherit px-3 py-2"
        [ngClass]="{
          'pointer-events-none': ngControl.control!.disabled,
          'form-input__error-ring': hasError,
        }">
        <input
          [attr.data-testid]="id() + index | appendTestId: 'input'"
          type="checkbox"
          [id]="id() | appendTestId: index + ''"
          [name]="name() ? name()! + index : undefined"
          [checked]="isValueSelected"
          class="form-input__ring form-input__focus-text box-border h-5 w-5 cursor-pointer !rounded border-0 shadow-sm outline-none"
          [ngClass]="{
            'form-input__focus-ring':
              !hasError && !ngControl.control!.disabled && isValueSelected,
            'form-input__disabled-text form-input__disabled-background-color !cursor-not-allowed':
              ngControl.control!.disabled,
            'form-input__error-text form-input__error-ring': hasError,
          }" />
        <span
          class="pl-2"
          [attr.data-testid]="id() + index | appendTestId: 'option'"
          >{{ option | toSelectOptionAttribute: name() }}</span
        >
      </label>
    }
  </div>
  @if (hasError && !errorTemplate()) {
    <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
      <ng-content></ng-content>
    </app-error-message>
  }
  @if (hasError && errorTemplate()) {
    <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
      <ng-container *ngTemplateOutlet="errorTemplate()!"></ng-container>
    </app-error-message>
  }
</div>

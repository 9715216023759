import { FontSchema, IFont } from './font-entity';
import { z, ZodSchema } from 'zod';
import { IText, TextSchema } from './text-entity';
import { FormControl } from '@angular/forms';

export const CorporateDesignSchema: ZodSchema = z.object({
  id: z.string().uuid().nullable(),
  client: z.string(),
  primaryColor: z.string(),
  primaryFontColor: z.string(),
  primaryBorderColor: z.string(),
  primaryAlternativeColor: z.string(),
  primaryDarkColor: z.string(),
  primaryDarkFontColor: z.string(),
  secondaryColor: z.string(),
  secondaryFontColor: z.string(),
  secondaryBorderColor: z.string(),
  secondaryAlternativeColor: z.string(),
  linkIcon: z.string(),
  linkIconDark: z.string(),
  fontSizeTitle: z.number(),
  fontSizeText: z.number(),
  fontSizeSmall: z.number(),
  font: FontSchema.nullable(),
  tabs: z.array(TextSchema),
  buttonBorderRounding: z.string(),
  faviconFilename: z.string(),
  faviconFileExtension: z.string(),
});

export interface ICorporateDesign {
  [key: string]: unknown;
  id?: string;
  client: string;
  primaryColor: string;
  primaryFontColor: string;
  primaryBorderColor: string;
  primaryAlternativeColor: string;
  primaryDarkColor: string;
  primaryDarkFontColor: string;
  secondaryColor: string;
  secondaryFontColor: string;
  secondaryBorderColor: string;
  secondaryAlternativeColor: string;
  linkIcon: string;
  linkIconDark: string;
  fontSizeTitle: number;
  fontSizeText: number;
  fontSizeSmall: number;
  font: IFont | null;
  tabs: IText[];
  buttonBorderRounding: string;
  faviconFilename: string;
  faviconFileExtension: string;
}

export const defaultCorporateDesign: ICorporateDesign = {
  id: '',
  buttonBorderRounding: 'middle',
  client: 'bsvg',
  faviconFileExtension: 'ico',
  faviconFilename: 'favicon',
  font: null,
  fontSizeSmall: 14,
  fontSizeText: 16,
  fontSizeTitle: 32,
  linkIcon: '#FBBE01',
  linkIconDark: '#FCD14D',
  primaryAlternativeColor: '#000000',
  primaryBorderColor: '#e2e2e2',
  primaryColor: '#3E4C7C',
  primaryDarkColor: '#657096',
  primaryDarkFontColor: '#F9F9FB',
  primaryFontColor: '#FFFFFF',
  secondaryAlternativeColor: '#000000',
  secondaryBorderColor: '#977201',
  secondaryColor: '#FBBE01',
  secondaryFontColor: '#333333',
  tabs: [
    {
      value: 'Webshop',
      isoCode: 'deu',
    },
  ],
};

export interface ICorporateDesignForm {
  id: FormControl<string | null>;
  client: FormControl<string>;
  primaryColor: FormControl<string>;
  primaryFontColor: FormControl<string>;
  primaryBorderColor: FormControl<string>;
  primaryAlternativeColor: FormControl<string>;
  primaryDarkColor: FormControl<string>;
  primaryDarkFontColor: FormControl<string>;
  secondaryColor: FormControl<string>;
  secondaryFontColor: FormControl<string>;
  secondaryBorderColor: FormControl<string>;
  secondaryAlternativeColor: FormControl<string>;
  linkIcon: FormControl<string>;
  linkIconDark: FormControl<string>;
  fontSizeTitle: FormControl<number>;
  fontSizeText: FormControl<number>;
  fontSizeSmall: FormControl<number>;
  font: FormControl<IFont | null>;
  tabs: FormControl<IText[]>;
  buttonBorderRounding: FormControl<string>;
  faviconFilename: FormControl<string>;
  faviconFileExtension: FormControl<string>;
}

export const custom = 'Benutzerdefiniert';
export const byPrimaryColor = 'Nach Primärfarbe';
export const black = 'Schwarz';
export const blackHexCode = '#000000';
export const white = 'Weiß';
export const whiteHexCode = '#FFFFFF';
export const standard = 'Standard';
export const none = 'Keine';
export const byFontColor = 'Nach Schriftfarbe';
export const bySecondaryColor = 'Nach Sekundärfarbe';
export const defaultFontSize = 12;

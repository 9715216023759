import { z, ZodSchema } from 'zod';
import { IText, TextSchema } from './text-entity';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ILink, ILinkForm, LinkSchema } from './link-entity';

export const LinkAreaSchema: ZodSchema = z.object({
  id: z.string().uuid().nullable(),
  headlines: z.array(TextSchema),
  links: z.array(LinkSchema),
});

export interface ILinkArea {
  id?: string;
  headlines: IText[];
  links: ILink[];
}

export interface ILinkAreaForm {
  id: FormControl<string | null>;
  headlines: FormControl<IText[]>;
  links: FormArray<FormGroup<ILinkForm>>;
}

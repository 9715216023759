import { Observable } from 'rxjs';
import { IGetGateway, IListGateway } from '../../gateways';
import { RestCallBuilder } from '../../../builder/rest/rest.builder';
import {
  defaultSortGroups,
  ISortGroups,
  IWrapper,
  WrapperSchema,
} from '../../../entities/sort-groups-entity';
import { ConfigService } from '../../config/config.service';
import { inject } from '@angular/core';

export class SortGroupsGateway
  implements IListGateway<null, IWrapper<ISortGroups>>
{
  private configService: ConfigService = inject(ConfigService);
  private restCallBuilder: RestCallBuilder<IWrapper<ISortGroups>> =
    new RestCallBuilder(defaultSortGroups);
  private apiUrl: string = this.configService.externalApi;

  list(): Observable<IWrapper<ISortGroups>> {
    return this.restCallBuilder
      .getExternal(`${this.apiUrl}api/v1/admin/sortGroups`, {
        serviceAreaId: this.configService.serviceArea,
        includeRelationSortGroups: true,
      })
      .validate(WrapperSchema)
      .handleErrors()
      .build();
  }
}

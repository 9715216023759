import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlContainer,
  FormGroupDirective,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { ErrorMessageComponent } from '../../error-message/error-message.component';
import { AppendTestIdPipe } from '../../../pipes/append-test-id/append-test-id.pipe';
import { FormTranslationInputComponent } from '../form-translation-input.component';

@Component({
  selector: 'app-translation-accessibility-text-input',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    AppendTestIdPipe,
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  styleUrl: '../form-inputs.css',
  templateUrl: './translation-accessibility-text-input.component.html',
})
export class TranslationAccessibilityTextInputComponent extends FormTranslationInputComponent {
  constructor(public override ngControl: NgControl) {
    super(ngControl);
  }
}

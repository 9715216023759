import { Component, inject, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigService } from '../../services/config/config.service';
import { FooterService } from '../../services/api/footer/footer.service';
import { ClientService } from '../../services/client/client.service';
import { LanguagePipe } from '../../pipes/language/language.pipe';
import { CorporateDesignService } from '../../services/api/corporateDesign/corporate-design.service';
import { ICorporateDesign } from '../../entities/coporate-design-entity';
import IFooter from '../../entities/footer-entity';
import { toSignal } from '@angular/core/rxjs-interop';
import { FooterBlockComponent } from '../footer-block/footer-block.component';
import { FormsModule } from '@angular/forms';
import { QuillViewComponent } from 'ngx-quill';

@Component({
  selector: 'app-footer',
  imports: [
    CommonModule,
    LanguagePipe,
    FooterBlockComponent,
    FormsModule,
    QuillViewComponent,
  ],
  templateUrl: './footer.component.html',
  styles: ``,
})
export class FooterComponent {
  private corporateDesignService: CorporateDesignService = inject(
    CorporateDesignService
  );
  corporateDesign: Signal<ICorporateDesign> = toSignal(
    this.corporateDesignService.$entity
  ) as Signal<ICorporateDesign>;
  private footerService: FooterService = inject(FooterService);
  footer: Signal<IFooter> = toSignal(
    this.footerService.$entity
  ) as Signal<IFooter>;
  private configService = inject(ConfigService);
  private clientService = inject(ClientService);
  private apiUrl = this.configService.getCmsUrl;
  private client = this.clientService.client;

  constructor() {
    this.corporateDesignService.get$.next(null);
    this.footerService.get$.next(null);
  }

  requestPath(imageId: string | undefined, area: string) {
    return this.apiUrl + '/footer/' + this.client + '/' + area + '/' + imageId;
  }
}

<div
  class="border-primary-main text-initial mb-4 box-border flex w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed"
  (drop)="onFileDrop($event)"
  (dragover)="onDragOver($event)">
  @let hasError = ngControl.errors && ngControl.touched;
  <div class="relative bg-inherit">
    <input
      [attr.data-testid]="id() | appendTestId: 'input'"
      [id]="id()"
      type="file"
      hidden
      #uploader
      [multiple]="multiple()"
      [formControl]="ngControl.control! | toFormControl"
      (change)="selectFilesEvent($event)" />

    <div class="flex flex-col gap-2 p-5 text-center">
      <span class="material-symbols-rounded">attach_file</span>
      <span>
        Per Drag-and-Drop können Dateien angehangen werden oder über
      </span>
      <app-button
        (click)="uploader.click()"
        [type]="'button'"
        [fullWidth]="false"
        [text]="'Datei auswählen'">
      </app-button>

      @if (ngControl.control!.value && ngControl.control!.value.length > 0) {
        <p>
          <span>{{ ngControl.control!.value.length }} Dateien ausgewählt.</span>
        </p>
        <span
          class="material-symbols-rounded z-10 ml-4 cursor-pointer text-xl text-gray-400"
          [attr.data-testid]="id() | appendTestId: 'clear'"
          (click)="ngControl.control!.setValue(undefined)">
          delete
        </span>
      }
    </div>

    @if (hasError && !errorTemplate()) {
      <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
        <ng-content></ng-content>
      </app-error-message>
    }
    @if (hasError && errorTemplate()) {
      <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
        <ng-container *ngTemplateOutlet="errorTemplate()!"></ng-container>
      </app-error-message>
    }
  </div>
</div>

import { Injectable } from '@angular/core';
import { HeaderGateway } from './headerGateway';
import { IHeader } from '../../../entities/header-entity';
import { CRUDService } from '../../crud/crud.service';

@Injectable({
  providedIn: 'root',
})
export class HeaderService extends CRUDService<
  IHeader,
  never,
  null,
  never,
  never,
  never
> {
  constructor() {
    super(new HeaderGateway());
  }
}

<div
  [ngClass]="{
    'w-fit': !sideBarExpanded(),
  }"
  class="hover:bg-standard-hover flex h-10 flex-row items-center rounded-2xl pl-2 text-gray-700 transition-colors">
  <span class="text-standard-icon material-symbols-rounded mr-2 text-2xl">
    {{ menu!.headingIcon }}
  </span>
  @if (sideBarExpanded()) {
    <span class="text-s text-standard-text-primary pl-1">{{
      menu!.heading
    }}</span>
    <button
      type="button"
      (click)="toogleExpansion()"
      class="absolute right-0 w-10 p-2 text-2xl focus:outline-none">
      <span class="text-standard-icon material-symbols-rounded text-2xl">
        {{ !menu!.expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}
      </span>
    </button>
  }
</div>

@if (menu!.expanded && sideBarExpanded()) {
  <ul
    class="relative flex flex-col space-y-2 pl-4 before:absolute before:bottom-0 before:left-4 before:top-0 before:w-1 before:bg-gray-400 before:content-['']">
    @for (item of menu!.items; track $index) {
      <li class="relative flex w-full items-center pl-2">
        <app-sidebar-item
          class="w-full"
          [item]="item"
          [expanded]="menu!.expanded"></app-sidebar-item>
      </li>
    }
  </ul>
}

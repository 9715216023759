import {
  ChangeDetectorRef,
  Component,
  effect,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlContainer,
  FormBuilder,
  FormGroupDirective,
  NgControl,
} from '@angular/forms';
import { FormInputComponent } from './form-input.component';
import { LanguageService } from '../../services/language/language.service';
import { Subscription } from 'rxjs';
import { IText } from '../../entities/text-entity';

@Component({
  selector: 'app-form-input',
  imports: [CommonModule],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  template: '',
})
export class FormTranslationInputComponent
  extends FormInputComponent
  implements OnDestroy, OnInit
{
  private readonly formBuilder = inject(FormBuilder);
  private readonly cdRef = inject(ChangeDetectorRef);
  private languageService = inject(LanguageService);

  activeTextValue = this.formBuilder.nonNullable.control('');
  subscription: Subscription = new Subscription();

  constructor(public override ngControl: NgControl) {
    super(ngControl);

    effect(() => {
      this.languageService.activeLanguage();
      this.setActiveTextForLanguage();
    });

    this.subscription.add(
      this.activeTextValue.valueChanges.subscribe(value => {
        const text = this.languageService.getActiveLanguageText(
          this.ngControl.control?.value
        );
        let newText: IText[] = [...(this.ngControl.control?.value as IText[])];
        if (text) {
          newText = newText.map(text => {
            if (text.isoCode === this.languageService.activeLanguage().iso) {
              text.value = value;
            }
            return text;
          });
        } else {
          newText.push({
            value,
            isoCode: this.languageService.activeLanguage().iso,
          });
        }

        this.ngControl.control?.setValue(newText);
        this.ngControl.control?.updateValueAndValidity();
        this.ngControl.control?.markAsDirty();
        this.cdRef.detectChanges();
      })
    );
  }

  private setActiveTextForLanguage() {
    const text = this.languageService.getActiveLanguageText(
      this.ngControl.control?.value
    );
    this.activeTextValue.setValue(text?.value ?? '', { emitEvent: false });
  }

  ngOnInit() {
    this.subscription.add(
      this.ngControl.control!.statusChanges.subscribe(() => {
        if (this.ngControl.disabled && this.activeTextValue.enabled) {
          this.activeTextValue.disable({ emitEvent: false });
        } else if (this.ngControl.enabled && this.activeTextValue.disabled) {
          this.activeTextValue.enable({ emitEvent: false });
        }
      })
    );
    this.subscription.add(
      this.ngControl.control!.valueChanges.subscribe(() => {
        this.setActiveTextForLanguage();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

<div
  class="form-input__background"
  [ngClass]="{
    'form-input__disabled': ngControl.control!.disabled,
  }">
  @let hasError = ngControl.errors && ngControl.touched;
  <div class="form-input__container">
    <div
      class="form-input peer min-h-28"
      [ngClass]="{ 'form-input__error-ring': hasError }">
      <quill-editor
        class="test w-full"
        format="json"
        [disabled]="ngControl.control!.disabled"
        [ngClass]="{
          'form-input__error-ring': hasError,
        }"
        [id]="id()"
        [placeholder]="label() ?? ''"
        [attr.data-testid]="id() | appendTestId: 'input'"
        [formControl]="ngControl.control! | toFormControl"></quill-editor>
    </div>
    <label
      [attr.data-testid]="id() | appendTestId: 'label'"
      class="form-input__floating-label"
      [ngClass]="{ 'form-input__error-text': hasError }"
      [for]="id()">
      {{ label() }}
    </label>
    @if (hasError && !errorTemplate()) {
      <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
        <ng-content></ng-content>
      </app-error-message>
    }
    @if (hasError && errorTemplate()) {
      <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
        <ng-container *ngTemplateOutlet="errorTemplate()!"></ng-container>
      </app-error-message>
    }
  </div>
</div>

<button
  [disabled]="disabled()"
  [type]="type()"
  class="border-box border-1 flex w-full cursor-pointer items-center justify-center text-nowrap rounded-lg px-4 py-2 focus:outline-2 focus:outline-blue-600"
  [attr.data-testid]="
    'button' | appendTestId: (text() != undefined ? text()! : '')
  "
  [ngClass]="buttonColour()">
  <ng-content class="h-full"></ng-content>
  @if (text()) {
    <ng-container class="h-full">
      {{ text() }}
    </ng-container>
  }
</button>

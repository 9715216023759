import {
  Component,
  computed,
  ContentChildren,
  ElementRef,
  input,
  InputSignal,
  OnDestroy,
  output,
  OutputEmitterRef,
  QueryList,
  signal,
  Signal,
  TemplateRef,
  ViewChildren,
  viewChildren,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../button/button.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { toSignal } from '@angular/core/rxjs-interop';
import { fromEvent, map, Subscription, tap } from 'rxjs';
import { AppendTestIdPipe } from '../../pipes/append-test-id/append-test-id.pipe';
import { DropDownComponent } from '../form-inputs/drop-down/drop-down.component';
import { TableHeaderComponent } from './table-header/table-header.component';
import { LoadingAnimationComponent } from '../loading-animation/loading-animation.component';

export enum SortOrder {
  ASC = 'ascending',
  DESC = 'descending',
}

export type TableSortOrder = SortOrder | undefined;

export interface TableSortParams<T> {
  attribute: string;
  order?: TableSortOrder;
  compare: (a: T, b: T, order: TableSortOrder) => number;
}

export interface TableLabels {
  noEntriesFoundMessage: string;
  entriesText: string;
  entriesPerPageText: string;
  ofEntriesText: string;
}

@Component({
  selector: 'app-table',
  imports: [
    CommonModule,
    ButtonComponent,
    ReactiveFormsModule,
    AppendTestIdPipe,
    DropDownComponent,
    LoadingAnimationComponent,
  ],
  templateUrl: './table.component.html',
})
export class TableComponent<T> implements OnDestroy {
  public readonly isLoading: InputSignal<boolean> = input(false);
  public readonly id: InputSignal<string> = input.required();
  public readonly tableClasses: InputSignal<string> = input<string>(
    'rounded-lg border-1 border-gray-400'
  );
  public readonly rowClasses: InputSignal<string> =
    input<string>('flex flex-row');
  public readonly headerClasses: InputSignal<string> = input<string>(
    'flex flex-row bg-gray-200 rounded-t-lg'
  );
  public readonly labels: InputSignal<TableLabels> = input({
    noEntriesFoundMessage: 'Keine Daten gefunden',
    ofEntriesText: 'von',
    entriesText: 'Einträge',
    entriesPerPageText: 'Ergebnisse pro Seite:',
  });
  public readonly pageSizeOptions: InputSignal<number[]> = input<number[]>([
    5, 10, 15, 20, 50,
  ]);
  public readonly data: InputSignal<T[] | undefined> = input();
  public readonly header: InputSignal<TemplateRef<unknown>> =
    input.required<TemplateRef<unknown>>();
  public readonly columns: InputSignal<TemplateRef<unknown>> =
    input.required<TemplateRef<unknown>>();

  public readonly pageChange: OutputEmitterRef<number> = output<number>();

  public currentPage = 0;

  public pageSize: Signal<number>;
  public readonly pageSizeControl: FormControl<string> =
    new FormControl<string>('10', {
      nonNullable: true,
    });

  public readonly currentPageData: Signal<T[] | undefined> = computed(() => {
    this.autoCorrectCurrentPage(this.pageSize());
    return this.calculateCurrentPageData(this.pageSize(), this.sortParams());
  });

  public readonly maxPage: Signal<number> = computed(() => {
    const flooredPage = Math.round(
      this.data() ? this.data()!.length / this.pageSize() : 1
    );
    return flooredPage < 1 ? 1 : flooredPage;
  });

  public sortBy = (sortParams: TableSortParams<T> | undefined) => {
    const oldParams = this.sortParams();
    const order =
      sortParams?.attribute === oldParams?.attribute
        ? oldParams?.order === SortOrder.ASC
          ? SortOrder.DESC
          : oldParams?.order === SortOrder.DESC
            ? undefined
            : SortOrder.ASC
        : sortParams?.order;
    this.sortParams.set(sortParams ? { ...sortParams, order } : undefined);
  };

  private sortParams: WritableSignal<TableSortParams<T> | undefined> =
    signal(undefined);

  private readonly $handleKeyUpEvent: Subscription;
  private readonly appendTestId: AppendTestIdPipe = new AppendTestIdPipe();
  @ContentChildren(TableHeaderComponent) tableHeaderCells!: QueryList<
    TableHeaderComponent<T>
  >;

  constructor() {
    this.pageSize = toSignal(
      this.pageSizeControl.valueChanges.pipe(
        map(pageSize => +pageSize),
        map(pageSize => this.resetInvalidPageSize(pageSize)),
        tap(pageSize => this.autoCorrectCurrentPage(pageSize))
      )
    ) as Signal<number>;
    this.pageSizeControl.updateValueAndValidity();

    this.$handleKeyUpEvent = fromEvent<
      KeyboardEvent & { target?: { id: string } }
    >(document, 'keyup').subscribe(event => {
      const target = this.determineTarget(event.target?.id);
      if (event.key === 'Enter') {
        event.preventDefault();
        switch (target) {
          case 'previous':
            this.previousPage();
            break;
          case 'next':
            this.nextPage();
            break;
        }
      }
    });
  }

  private determineTarget(targetId: string): 'next' | 'previous' | 'invalid' {
    switch (targetId) {
      case this.appendTestId.transform(this.id(), 'nextPage'):
        return 'next';
      case this.appendTestId.transform(this.id(), 'previousPage'):
        return 'previous';
      default:
        return 'invalid';
    }
  }

  private resetInvalidPageSize(pageSize: number): number {
    return pageSize < 0 ? this.pageSize() : pageSize;
  }

  private autoCorrectCurrentPage(pageSize: number) {
    const currentPageData = this.calculateCurrentPageData(
      pageSize,
      this.sortParams()
    );
    if (
      (!currentPageData || currentPageData!.length === 0) &&
      this.currentPage > 0
    ) {
      this.setCurrentPage(0);
    }
  }

  public nextPage(): void {
    if (this.currentPage < this.maxPage() - 1) {
      this.setCurrentPage(this.currentPage + 1);
    }
  }

  public previousPage(): void {
    if (this.currentPage > 0) {
      this.setCurrentPage(this.currentPage - 1);
    }
  }

  public calculateCurrentPageData(
    pageSize: number,
    sortParams: TableSortParams<T> | undefined = undefined
  ): T[] | undefined {
    const originalData = this.data();
    const data = originalData ? [...originalData] : [];
    return data
      ? data
          .sort((a: T, b: T) => {
            if (sortParams) {
              this.tableHeaderCells.forEach(tableHeader => {
                if (
                  tableHeader.sortParams()?.attribute !== sortParams?.attribute
                ) {
                  tableHeader.resetParam();
                }
              });
            }
            return sortParams?.order
              ? sortParams?.compare(a, b, sortParams.order)
              : 0;
          })
          .filter((d: T, i: number) =>
            this.isOnPage(i, pageSize, this.currentPage)
          )
      : data;
  }

  private setCurrentPage(page: number): void {
    this.currentPage = page;
    this.pageChange.emit(this.currentPage);
  }

  private isOnPage(
    index: number,
    pageSize: number,
    currentPage: number
  ): boolean {
    const start = pageSize * currentPage;
    const end = start + pageSize;
    return index >= start && index < end;
  }

  ngOnDestroy() {
    this.$handleKeyUpEvent.unsubscribe();
  }
}

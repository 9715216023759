import { Component } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FileUploadComponent } from '../form-inputs/file-upload/file-upload.component';
import { AppendTestIdPipe } from '../../pipes/append-test-id/append-test-id.pipe';
import { ErrorMessageComponent } from '../error-message/error-message.component';
import { ToFormControlPipe } from '../../pipes/to-form-control/to-form-control.pipe';

@Component({
  selector: 'app-drag-drop',
  templateUrl: './drop-zone.component.html',
  styles: '',
  imports: [
    ButtonComponent,
    ReactiveFormsModule,
    AppendTestIdPipe,
    ErrorMessageComponent,
    ToFormControlPipe,
  ],
})
export class DropZoneComponent extends FileUploadComponent {
  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onFileDrop(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer?.files) {
      console.log(Array.from(event.dataTransfer?.files));
      this.selectFiles(Array.from(event.dataTransfer?.files));
    }
  }
}

import { Observable, ReplaySubject, switchMap } from 'rxjs';
import {
  IDeleteGateway,
  IGateway,
  IGetGateway,
  IListGateway,
  ISaveGateway,
  IUploadGateway,
} from '../gateways';

/*
 * CRUDService encapsulates common logic for executing gateway functions
 * extend CRUDService whenever new gateway functions become available
 * @TODO automatically handle loading state here via an injected loadingService
 * */

export class CRUDService<ENTITY, LIST_ENTITY, GET, LIST, DELETE, UPLOAD> {
  constructor(private readonly gateWay: IGateway) {}

  public readonly list$: ReplaySubject<LIST> = new ReplaySubject<LIST>(1);
  public readonly get$: ReplaySubject<GET> = new ReplaySubject<GET>(1);
  public readonly save$: ReplaySubject<ENTITY> = new ReplaySubject<ENTITY>(1);
  public readonly delete$: ReplaySubject<DELETE> = new ReplaySubject<DELETE>(1);
  public readonly upload$: ReplaySubject<UPLOAD> = new ReplaySubject<UPLOAD>(1);

  public readonly $entities: Observable<LIST_ENTITY> = this.list$.pipe(
    switchMap(query =>
      (this.gateWay as IListGateway<LIST, LIST_ENTITY>).list(query)
    )
  );

  public readonly $entity: Observable<ENTITY> = this.get$.pipe(
    switchMap(query => (this.gateWay as IGetGateway<GET, ENTITY>).get(query))
  );

  public readonly $savedEntity: Observable<ENTITY> = this.save$.pipe(
    switchMap(entity =>
      (this.gateWay as ISaveGateway<ENTITY, ENTITY>).save(entity)
    )
  );

  public readonly $deletedEntity: Observable<ENTITY> = this.delete$.pipe(
    switchMap(query =>
      (this.gateWay as IDeleteGateway<DELETE, ENTITY>).delete(query)
    )
  );

  public readonly $uploadedFile: Observable<ENTITY> = this.upload$.pipe(
    switchMap(query =>
      (this.gateWay as IUploadGateway<UPLOAD, ENTITY>).upload(query)
    )
  );
}

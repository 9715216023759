<div [attr.data-testid]="'footer-block'" class="p-5">
  <div
    [attr.data-testid]="'footer-block-header'"
    [style.font-size]="headlineSize + 'pt'"
    class="mb-2 font-medium uppercase"
    [attr.aria-label]="headline()">
    {{ headline() }}
  </div>
  <div [attr.data-testid]="'footer-block-content'">
    <ng-container *ngTemplateOutlet="children()"> </ng-container>
  </div>
</div>

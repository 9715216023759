import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { BannerPageComponent } from '../banner/banner-page.component';
import { PopupService } from '../../../services/popup/popup.service';

@Injectable({
  providedIn: 'root',
})
export class BannerFormChangedGuard
  implements CanDeactivate<BannerPageComponent>
{
  constructor(private popupService: PopupService) {}
  canDeactivate(
    component: BannerPageComponent
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (component.bannerForm.touched) {
      this.popupService.showPopupChanges();
      return false;
    }
    return true;
  }
}

import { Component, Input, input, TemplateRef } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'app-footer-block',
  imports: [NgTemplateOutlet],
  templateUrl: './footer-block.component.html',
})
export class FooterBlockComponent {
  headline = input.required<string>();
  @Input() headlineSize!: number;
  children = input.required<TemplateRef<unknown>>();
}

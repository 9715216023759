import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketsOverviewComponent } from '../../../components/tickets-overview/tickets-overview.component';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-tickets',
  imports: [CommonModule, TicketsOverviewComponent, ReactiveFormsModule],
  templateUrl: './tickets-page.component.html',
})
export class TicketsPageComponent {}

import { Component, Input, input, InputSignal } from '@angular/core';
import { ISidebarMenu } from '../../../../entities/sidebar/menu.entity';
import { SidebarItemComponent } from '../item/sidebar-item.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styles: ``,
  imports: [SidebarItemComponent, NgClass],
})
export class SidebarMenuComponent {
  @Input({ required: true }) menu: ISidebarMenu | undefined;
  sideBarExpanded: InputSignal<boolean> = input<boolean>(true);

  toogleExpansion(): void {
    this.menu!.expanded = !this.menu!.expanded;
  }
}

import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as Sentry from '@sentry/angular';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  imports: [CommonModule],
  templateUrl: './page-not-found.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent {
  private readonly route = inject(ActivatedRoute);
  constructor() {
    Sentry.captureMessage(
      `PageNotFoundComponent because ${JSON.stringify(this.route.snapshot.url)} ${JSON.stringify(this.route.snapshot.params)}`,
      { level: 'error' }
    );
  }
}

import { Injectable } from '@angular/core';
import { CRUDService } from '../../crud/crud.service';
import { TicketGateway } from './ticketGateway';
import { ITicket } from '../../../entities/ticket-entity';

@Injectable({
  providedIn: 'root',
})
export class TicketService extends CRUDService<
  ITicket,
  ITicket[],
  never,
  string,
  never,
  never
> {
  constructor() {
    super(new TicketGateway());
  }
}

@if (corporateDesign() && corporateDesignForm) {
  <div [formGroup]="corporateDesignForm">
    <app-section>
      <app-section-header>
        <app-section-headline> Corporate Design </app-section-headline>
        <app-section-header-actions>
          <app-button
            [type]="'button'"
            [style]="'tertiary'"
            [disabled]="!corporateDesignForm.dirty"
            (click)="showPopUpToFactorySettings()">
            Auf Werkseinstellungen zurücksetzen
          </app-button>
          <app-button
            [disabled]="!corporateDesignForm.valid || !faviconForm.valid"
            [type]="'submit'"
            [style]="'primary'"
            (click)="save()"
            >Speichern</app-button
          >
          <app-button
            [type]="'button'"
            [style]="'secondary'"
            (click)="showPopUpChanges()"
            >Abbrechen
          </app-button>
        </app-section-header-actions>
      </app-section-header>
      <!-- Common Colors -->
      <app-section>
        <app-section-header>
          <app-section-headline> Allgemeine Farben </app-section-headline>
        </app-section-header>

        <!-- Primary Color -->
        <app-color-picker-input
          formControlName="primaryColor"
          label="Primärfarbe"
          id="primaryColor" />
        <!-- Primary Font Color -->
        <div class="flex flex-row items-center gap-2">
          <app-color-picker-input
            class="flex-grow"
            formControlName="primaryFontColor"
            label="Primärfarbe - Schriftfarbe"
            id="primaryFontColor" />
          <app-button-group
            class="flex"
            [id]="'primaryFontColor'"
            [formControl]="primaryFontColorBtn"
            [options]="fontColors | enumToButtonOptionArray"
            (valueChange)="onFontColorChange($event, 'primaryFontColor')" />
        </div>
        <!-- Primary Border Color -->
        <div class="flex flex-row items-center gap-2">
          <app-color-picker-input
            class="flex-grow"
            formControlName="primaryBorderColor"
            [disabled]="true"
            label="Primärfarbe - Umrandung"
            id="primaryColorBorder" />
          <app-button-group
            class="flex"
            [id]="'primaryBorderColor'"
            [formControl]="primaryBorderColorBtn"
            [options]="borderColors | enumToButtonOptionArray"
            (valueChange)="onBorderColorChange($event, 'primaryBorderColor')" />
        </div>
        <!-- Primary Alternative Color -->
        <div class="flex flex-row items-center gap-2">
          <div class="flex flex-grow flex-col">
            <span
              class="text-standard-text-primary text-de pb-1 text-base font-bold">
              Primärfarbe - Alternative
            </span>
            <span class="text-standard-text-primary text-sm">
              Wenn es mehr als eine Aktion gibt, wird diese Farbe für die
              alternativen Aktionen verwendet.<br />
              Alternativ-Komponenten sehen zudem anders aus.
            </span>
          </div>
          <app-button-group
            class="flex"
            [formControl]="primaryAlternativeColorBtn"
            [id]="'primaryAlternativeColorButtonGroup'"
            [options]="alternateColors | enumToButtonOptionArray" />
        </div>
        <!-- Secondary Color -->
        <app-color-picker-input
          formControlName="secondaryColor"
          label="Sekundärfarbe"
          id="secondaryColor" />
        <!-- Secondary Font Color -->
        <div class="flex flex-row items-center gap-2">
          <app-color-picker-input
            class="flex-grow"
            formControlName="secondaryFontColor"
            label="Sekundärfarbe - Schriftfarbe"
            id="secondaryColorFont" />
          <app-button-group
            class="flex"
            [id]="'secondaryFontColorButtonGroup'"
            [formControl]="secondaryFontColorBtn"
            [options]="fontColors | enumToButtonOptionArray"
            (valueChange)="onFontColorChange($event, 'secondaryFontColor')" />
        </div>
        <!-- Secondary Border Color -->
        <div class="flex flex-row items-center gap-2">
          <app-color-picker-input
            class="flex-grow"
            formControlName="secondaryBorderColor"
            label="Sekundärfarbe - Umrandung"
            id="secondaryColorBorder" />
          <app-button-group
            class="flex"
            [formControl]="secondaryBorderColorBtn"
            [id]="'secondaryBorderColorButtonGroup'"
            [options]="borderColors | enumToButtonOptionArray"
            (valueChange)="
              onBorderColorChange($event, 'secondaryBorderColor')
            " />
        </div>
        <!-- Secondary Alternative Color -->
        <div class="flex flex-row items-center gap-2">
          <div class="flex flex-grow flex-col">
            <span
              class="text-standard-text-primary text-de pb-1 text-base font-bold">
              Sekundärfarbe - Alternative
            </span>
            <span class="text-standard-text-primary text-sm">
              Wenn es mehr als eine Aktion gibt, wird diese Farbe für die
              alternativen Aktionen verwendet.<br />
              Alternativ-Komponenten sehen zudem anders aus.
            </span>
          </div>
          <app-button-group
            class="flex"
            [formControl]="secondaryAlternativeColorBtn"
            [id]="'secondaryAlternativeColorButtonGroup'"
            [options]="alternateColors | enumToButtonOptionArray" />
        </div>
        <!-- Link Icon -->
        <div class="space-b flex flex-row justify-between pb-4">
          <div class="flex flex-col">
            <span
              class="text-standard-text-primary text-de pb-1 text-base font-bold">
              Link - Icon
              <span class="material-symbols-rounded pt-1 text-2xl"
                >arrow_outward</span
              >
            </span>
            <span class="text-standard-text-primary text-sm">
              In den Karten der Startseite können Links angegeben werden. Links
              werden mit einem Icon angedeutet.
            </span>
          </div>
        </div>
        <div class="flex flex-row items-center gap-2">
          <app-color-picker-input
            class="flex-grow"
            formControlName="linkIcon"
            label="Link Icon Farbe"
            id="linkIcon" />
          <app-button-group
            class="flex"
            [id]="'linkIconColorButtonGroup'"
            [formControl]="linkIconColorBtn"
            [options]="iconColors | enumToButtonOptionArray"
            (valueChange)="onLinkIconColorChange($event, 'linkIcon')" />
        </div>
      </app-section>
      <!-- Dark Mode -->
      <app-section [dark]="true">
        <app-section-header>
          <app-section-headline> Dunkler Modus </app-section-headline>
        </app-section-header>
        <div class="flex">
          <span class="text-sm text-[#F7FAFD99]">
            <b>Hinweise:</b> Wenn hier <b>Standard</b> ausgewählt wird, wird die
            Farbe von der allgemeinen Variante abgleitet. Im dunklen Modes
            werden <b>weniger Farben</b> verwendet.
          </span>
        </div>
        <!-- Primary Color Dark Mode -->
        <div class="flex flex-row items-center gap-2">
          <app-color-picker-input
            class="flex-grow"
            formControlName="primaryDarkColor"
            label="Primärfarbe - Dunkler Modus"
            [darkMode]="true"
            id="primaryDarkColor" />
          <app-button-group
            class="flex"
            [formControl]="primaryDarkColorBtn"
            [id]="'primaryDarkColorButtonGroup'"
            [options]="darkColors | enumToButtonOptionArray"
            (valueChange)="onDarkColorChange($event, 'primaryDarkColor')"
            [darkMode]="true" />
        </div>
        <!-- Primary Dark Font Color -->
        <div class="flex flex-row items-center gap-2">
          <app-color-picker-input
            class="flex-grow"
            formControlName="primaryDarkFontColor"
            label="Primärfarbe - Schriftfarbe - Dunkler Modus"
            [darkMode]="true"
            id="primaryDarkColorFont" />
          <app-button-group
            class="flex"
            [formControl]="primaryDarkFontColorBtn"
            [id]="'primaryDarkFontColorButtonGroup'"
            [options]="fontColors | enumToButtonOptionArray"
            (valueChange)="onFontColorChange($event, 'primaryDarkFontColor')"
            [darkMode]="true" />
        </div>
        <!-- Link Icon Dark -->
        <div class="flex flex-row items-center gap-2">
          <app-color-picker-input
            class="flex-grow"
            formControlName="linkIconDark"
            label="Link-Icon Farbe"
            [darkMode]="true"
            [id]="'linkIconDarkColor'" />
          <app-button-group
            class="flex"
            [formControl]="linkIconDarkColorBtn"
            [id]="'linkIconDarkButtonGroup'"
            [options]="darkIconColors | enumToButtonOptionArray"
            (valueChange)="onLinkIconColorChange($event, 'linkIconDark')"
            [darkMode]="true" />
        </div>
      </app-section>
      <!-- Font Sizes -->
      <app-section>
        <app-section-header>
          <app-section-headline> Schriftgrößen </app-section-headline>
        </app-section-header>
        <span class="text-standard-text-primary text-sm">
          in der Einheit pt
        </span>
        <!-- Font Size Titel -->
        <div class="flex flex-row items-center gap-2">
          <app-number-input
            class="flex-grow"
            formControlName="fontSizeTitle"
            label="Titel"
            id="fontSizeTitleInput" />
          <app-button-group
            class="flex"
            [formControl]="fontSizeTitleBtn"
            [id]="'fontSizeTitleButtonGroup'"
            [options]="fontSizes | enumToButtonOptionArray"
            (valueChange)="onFontSizeChange($event, 'fontSizeTitle')" />
        </div>
        <div class="flex flex-row">
          <span
            [style.fontSize.pt]="
              corporateDesignForm!.controls.fontSizeTitle.value
            ">
            Vorschau
          </span>
        </div>
        <!-- Font Size Text -->
        <div class="flex flex-row items-center gap-2">
          <app-number-input
            class="flex-grow"
            formControlName="fontSizeText"
            label="Textkörper"
            id="textFontSizeInput" />
          <app-button-group
            class="flex"
            [formControl]="textFontSizeColorBtn"
            [id]="'textFontSizeButtonGroup'"
            [options]="fontSizes | enumToButtonOptionArray"
            (valueChange)="onFontSizeChange($event, 'fontSizeText')" />
        </div>
        <div class="flex flex-row">
          <span
            [style.fontSize.pt]="
              corporateDesignForm!.controls.fontSizeText.value
            ">
            Vorschau
          </span>
        </div>
        <!-- Font Size Text Small -->
        <div class="flex flex-row items-center gap-2">
          <app-number-input
            class="flex-grow"
            formControlName="fontSizeSmall"
            label="Kleine Texte"
            id="smallTextSizeInput" />
          <app-button-group
            class="flex"
            [formControl]="smallTextBtn"
            [id]="'smallTextSizeButtonGroup'"
            [options]="fontSizes | enumToButtonOptionArray"
            (valueChange)="onFontSizeChange($event, 'fontSizeSmall')" />
        </div>
        <div class="flex flex-row">
          <span
            [style.fontSize.pt]="
              corporateDesignForm!.controls.fontSizeSmall.value
            ">
            Vorschau
          </span>
        </div>
      </app-section>
      <!-- Other -->
      <app-section>
        <app-section-header>
          <app-section-headline> Sonstiges </app-section-headline>
        </app-section-header>
        @if (fonts()) {
          <div class="flex flex-row items-center gap-2">
            <app-font-select
              [formControl]="corporateDesignForm.controls.font"
              label="Schriftart"
              id="textFont" />
            <app-button-group
              class="flex"
              [formControl]="fontStyleBtn"
              [id]="'fontStyleButtonGroup'"
              [options]="fontSizes | enumToButtonOptionArray"
              (valueChange)="onFontStyleChange($event, 'font')" />
          </div>
        }
        <!-- Button Roundings -->
        <div class="mb-2 mt-2 flex flex-row items-center gap-2">
          <div class="flex flex-col">
            <span
              class="text-standard-text-primary text-de pb-1 text-base font-bold">
              Abrundung der Button-Ecken
            </span>
            <span class="text-standard-text-primary text-sm">
              Bestimme, ob die Ecken von Oberflächen und Buttons eckig,
              abgerundet oder ganz rund sein sollen.
            </span>
          </div>
          <app-button-group
            class="flex flex-grow"
            [id]="'buttonCornerRoundingButtonGroup'"
            [formControl]="buttonCornerRoundingBtn"
            [options]="buttonCornerRoundingArray | enumToButtonOptionArray"
            (valueChange)="
              corporateDesignForm.controls.buttonBorderRounding.setValue($event)
            " />
          <div
            class="border-2 border-gray-400 px-10 py-5"
            [ngClass]="{
              'rounded-none':
                corporateDesignForm.controls.buttonBorderRounding.value ===
                buttonCornerRoundingArray.Eckig,
              'rounded-md':
                corporateDesignForm.controls.buttonBorderRounding.value ===
                buttonCornerRoundingArray.Abgerundet,
              'rounded-xl':
                corporateDesignForm.controls.buttonBorderRounding.value ===
                buttonCornerRoundingArray.Rund,
            }">
            Vorschau
          </div>
        </div>
        <!-- Browser Tab Name -->
        <div>
          <app-translation-text-input
            class="w-full"
            [id]="'tabName'"
            formControlName="tabs"
            label="Browser Tab Name" />
        </div>
        <!-- Favicon -->
        <app-section>
          <app-section-headline> Browser FavIcon</app-section-headline>
          <div class="flex w-full items-center gap-2" [formGroup]="faviconForm">
            <app-drag-drop
              [allowedFileTypes]="['ico']"
              [fileType]="'image'"
              [multiple]="false"
              class="w-full"
              [id]="'faviconSelection'"
              formControlName="favicon" />
          </div>
        </app-section>
      </app-section>
    </app-section>
  </div>
}

<div
  [ngClass]="{
    'form-input__disabled': ngControl.control!.disabled,
    'form-input__background-color-dark form-input__text-dark': darkMode(),
    'form-input__background-color form-input__text': !darkMode(),
  }">
  @let hasError = ngControl.errors && ngControl.touched;

  <div class="form-input__container flex flex-row items-center">
    <input
      [attr.data-testid]="id() | appendTestId: 'input'"
      type="text"
      (change)="updateColorPickerValue()"
      [formControl]="ngControl.control! | toFormControl"
      class="form-input peer !pl-12"
      [ngClass]="{
        'form-input__error-ring': hasError,
      }"
      [id]="id()" />
    <label
      [attr.data-testid]="id() | appendTestId: 'label'"
      class="form-input__floating-label"
      [ngClass]="{ 'form-input__error-text': hasError }"
      [for]="id()"
      >{{ label() }}</label
    >
    @if (!ngControl.control!.disabled) {
      <span
        class="material-symbols-rounded absolute right-2 cursor-pointer"
        (click)="openColorPicker()">
        colorize
      </span>
    }
    <input
      [ngClass]="{
        'cursor-not-allowed': ngControl.control!.disabled,
      }"
      class="border-1 absolute left-2 h-6 w-6 cursor-pointer border-gray-700 focus:ring-0"
      tabindex="-1"
      [cpDisabled]="ngControl.control!.disabled"
      [cpCancelButton]="true"
      [cpCancelButtonText]="'Abbrechen'"
      [cpOKButton]="true"
      [cpOKButtonText]="'Ok'"
      [defaultValue]="defaultColor"
      [cpOutputFormat]="'hex'"
      [cpAlphaChannel]="'disabled'"
      (cpToggleChange)="positionPicker($event)"
      (colorPickerSelect)="changeColor($event)"
      [(colorPicker)]="color"
      [style.background]="color" />
  </div>
  @if (hasError && !errorTemplate()) {
    <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
      <ng-content></ng-content>
    </app-error-message>
  }
  @if (hasError && errorTemplate()) {
    <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
      <ng-container *ngTemplateOutlet="errorTemplate()!"></ng-container>
    </app-error-message>
  }
</div>

<div
  class="form-input__background"
  [ngClass]="{
    'form-input__disabled': ngControl.control!.disabled,
  }">
  @let hasError = ngControl.errors && ngControl.touched;
  <div class="form-input__container">
    <input
      [owlDateTime]="datePicker"
      [owlDateTimeTrigger]="datePicker"
      [attr.data-testid]="id() | appendTestId: 'input'"
      [formControl]="ngControl.control! | toFormControl"
      class="form-input peer !pr-10"
      [ngClass]="{ 'form-input__error-ring': hasError }"
      [id]="id()"
      [placeholder]="label() ?? ''"
      type="text" />
    <span
      class="pointer-events-none absolute left-0 right-0 top-0 h-10 rounded-lg bg-inherit p-2">
      {{ ngControl.control!.value | date: 'dd.MM.yyyy' }}
    </span>
    <owl-date-time #datePicker pickerType="calendar"></owl-date-time>

    <label
      [attr.data-testid]="id() | appendTestId: 'label'"
      class="form-input__floating-label"
      [ngClass]="{ 'form-input__error-text': hasError }"
      [for]="id()">
      {{ label() }}
    </label>
    @if (!ngControl.control!.disabled) {
      <span
        [id]="id() | appendTestId: 'arrow-down'"
        [attr.data-testid]="id() | appendTestId: 'arrow-down'"
        class="material-symbols-rounded form-input__text pointer-events-none absolute bottom-1 right-2 top-1 h-8 text-2xl">
        calendar_month
      </span>
    }
    @if (hasError && !errorTemplate()) {
      <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
        <ng-content></ng-content>
      </app-error-message>
    }
    @if (hasError && errorTemplate()) {
      <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
        <ng-container *ngTemplateOutlet="errorTemplate()!"></ng-container>
      </app-error-message>
    }
  </div>
</div>

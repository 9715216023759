import { Component, input } from '@angular/core';

@Component({
  selector: 'app-section-header',
  standalone: true,
  templateUrl: './section-header.component.html',
})
export class SectionHeaderComponent {
  dark = input<boolean>(false);
}

<div
  class="form-input__background"
  [ngClass]="{
    'form-input__disabled': ngControl.control!.disabled,
  }">
  @let hasError = ngControl.errors && ngControl.touched;
  <div class="form-input peer !max-w-10 !rounded-r-none">
    <span class="material-symbols-rounded">
      {{ ngControl.value | toSelectOptionAttribute: name() }}
    </span>
  </div>
  <div class="form-input__container !rounded-l-none">
    <input
      [attr.data-testid]="id() | appendTestId: 'input'"
      autocomplete="off"
      [formControl]="ngControl.control! | toFormControl"
      class="form-input peer !rounded-l-none"
      [ngClass]="{
        'form-input__error-ring': hasError,
      }"
      [id]="id()"
      [placeholder]="label() ?? ''"
      (focus)="showList.set(true)"
      type="text" />
    <label
      [attr.data-testid]="id() | appendTestId: 'label'"
      class="form-input__floating-label"
      [ngClass]="{ 'form-input__error-text': hasError }"
      [for]="id()">
      {{ label() }}
    </label>
    @if (ngControl.value) {
      <span
        [attr.data-testid]="id() | appendTestId: 'value'"
        class="pointer-events-none absolute left-1 right-5 top-2 truncate px-1">
        {{
          name() && ngControl.value
            ? (ngControl.value
              | SelectionOptionValueToName: options() : name()! : value())
            : (ngControl.value ?? 'Nix')
        }}
      </span>
    }
    <div
      [attr.data-testid]="id() | appendTestId: 'options'"
      [ngClass]="{ hidden: !showList() }"
      class="form-input__dropdown-list flex flex-row flex-wrap">
      @for (option of filteredOptions(); track $index; let index = $index) {
        <div
          [attr.data-testid]="id() + index | appendTestId: 'option'"
          class="form-input__dropdown-list-item form-input__dropdown-list--hover flex cursor-pointer items-center"
          [ngClass]="{
            'form-input__dropdown-list--selected': selectedItem() === index,
            'rounded-t-lg': index === 0,
            'rounded-b-lg': index === filteredOptions()!.length - 1,
          }"
          (click)="selectOptionAndSetItem(option, index)">
          <span class="material-symbols-rounded">
            {{ option | toSelectOptionAttribute: name() }}
          </span>
        </div>
      }
      @if (filteredOptions()!.length === 0) {
        <div
          class="form-input__dropdown-list-item"
          [attr.data-testid]="id() | appendTestId: 'option'">
          Keine passenden Ergebnisse...
        </div>
      }
    </div>
    @if (hasError && !errorTemplate()) {
      <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
        <ng-content></ng-content>
      </app-error-message>
    }
    @if (hasError && errorTemplate()) {
      <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
        <ng-container *ngTemplateOutlet="errorTemplate()!"></ng-container>
      </app-error-message>
    }
  </div>
</div>

<div
  class="form-input__background"
  [ngClass]="{
    'form-input__disabled': ngControl.control!.disabled,
  }">
  @let hasError = ngControl.errors && ngControl.touched;
  <div class="form-input__container">
    <input
      [attr.data-testid]="id() | appendTestId: 'input'"
      [formControl]="ngControl.control! | toFormControl"
      [step]="step"
      class="form-input number-input peer [appearance:textfield]"
      [ngClass]="{
        'form-input__error-ring': hasError,
      }"
      [id]="id()"
      [placeholder]="label() ?? ''"
      type="number" />
    <label
      [attr.data-testid]="id() | appendTestId: 'label'"
      class="form-input__floating-label"
      [ngClass]="{ 'form-input__error-text': hasError }"
      [for]="id()">
      {{ label() }}
    </label>
    @if (!ngControl.control!.disabled) {
      <span
        class="form-input__text absolute bottom-0 right-2 top-0 flex h-10 cursor-pointer flex-col justify-center text-lg">
        <span
          class="material-symbols-rounded -mb-0.5 cursor-pointer"
          (click)="increaseValue()">
          keyboard_arrow_up
        </span>
        <span
          class="material-symbols-rounded -mt-0.5 cursor-pointer"
          (click)="decreaseValue()">
          keyboard_arrow_down
        </span>
      </span>
    }
    @if (hasError && !errorTemplate()) {
      <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
        <ng-content></ng-content>
      </app-error-message>
    }
    @if (hasError && errorTemplate()) {
      <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
        <ng-container *ngTemplateOutlet="errorTemplate()!"></ng-container>
      </app-error-message>
    }
  </div>
</div>

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  private showPopupChangesSubject = new Subject<boolean>();
  public showPopupChanges$ = this.showPopupChangesSubject.asObservable();
  private showPopupToFactorySubject = new Subject<boolean>();
  public showPopupToFactory$ = this.showPopupToFactorySubject.asObservable();
  private popupActionSubjectChanges = new Subject<boolean>();
  public popupActionChanges$ = this.popupActionSubjectChanges.asObservable();
  private popupActionSubjectToFactory = new Subject<boolean>();
  public popupActionToFactory$ =
    this.popupActionSubjectToFactory.asObservable();

  showPopupChanges(): void {
    this.showPopupChangesSubject.next(true);
  }

  closePopupChanges(): void {
    this.showPopupChangesSubject.next(false);
  }

  showPopupToFactory(): void {
    this.showPopupToFactorySubject.next(true);
  }

  closePopupToFactory(): void {
    this.showPopupToFactorySubject.next(false);
  }

  userActionChanges(action: boolean): void {
    this.popupActionSubjectChanges.next(action);
    this.closePopupChanges();
  }

  userActionToFactory(action: boolean): void {
    this.popupActionSubjectToFactory.next(action);
    this.closePopupToFactory();
  }
}

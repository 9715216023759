import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-error-message',
  imports: [CommonModule],
  styleUrl: '../form-inputs/form-inputs.css',
  templateUrl: './error-message.component.html',
})
export class ErrorMessageComponent {}

import { IText, TextSchema } from './text-entity';
import { z, ZodSchema } from 'zod';

export const TicketSchema: ZodSchema = z.object({
  id: z.string().uuid().nullable(),
  client: z.string(),
  ticketId: z.string(),
  overviewId: z.string(),
  active: z.boolean(),
  startDate: z.string(),
  endDate: z.string().nullable(),
  title: z.array(TextSchema),
  price: z.number(),
  description: z.array(TextSchema),
  detailsTitle: z.array(TextSchema),
  button: z.array(TextSchema),
  details: z
    .array(
      z.object({
        icon: z.string().nullable(),
        text: z.array(TextSchema),
      })
    )
    .nullable(),
});

export const TicketSchemas: ZodSchema = z.array(TicketSchema);

export interface ITicketDetail {
  icon: string | undefined;
  text: IText[];
}

// representation of web2.0 ticket
export interface ITicket {
  id?: string;
  client: string;
  ticketId: string;
  overviewId: string;
  active: boolean;
  startDate: string;
  endDate: string;
  title: IText[];
  price: number;
  description: IText[];
  detailsTitle: IText[];
  details: ITicketDetail[];
  button: IText[];
}

export const defaultTicket: ITicket = {
  client: '',
  ticketId: '',
  overviewId: '',
  active: false,
  startDate: '',
  endDate: '',
  title: [],
  price: 0,
  description: [],
  detailsTitle: [],
  details: [],
  button: [],
};

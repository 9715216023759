import { Injectable } from '@angular/core';
import { CorporateDesignGateway } from './corporateDesignGateway';
import { ICorporateDesign } from '../../../entities/coporate-design-entity';
import { CRUDService } from '../../crud/crud.service';

@Injectable({
  providedIn: 'root',
})
export class CorporateDesignService extends CRUDService<
  ICorporateDesign,
  never,
  null,
  never,
  never,
  FormData
> {
  constructor() {
    super(new CorporateDesignGateway());
  }
}

import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { ClientService } from '../../client/client.service';
import { IGetGateway, ISaveGateway } from '../../gateways';
import { RestCallBuilder } from '../../../builder/rest/rest.builder';
import {
  defaultHeader,
  HeaderSchema,
  IHeader,
} from '../../../entities/header-entity';

export class HeaderGateway
  implements IGetGateway<null, IHeader>, ISaveGateway<IHeader, IHeader>
{
  private clientService = inject(ClientService);
  private client = this.clientService.client;
  private restCallBuilder: RestCallBuilder<IHeader> =
    new RestCallBuilder<IHeader>(defaultHeader);

  get(): Observable<IHeader> {
    return this.restCallBuilder
      .get('/header/' + this.client)
      .validate(HeaderSchema)
      .handleErrors()
      .build();
  }

  save(query: IHeader): Observable<IHeader> {
    return this.restCallBuilder
      .post('/header/', query)
      .validate(HeaderSchema)
      .handleErrors()
      .build();
  }
}

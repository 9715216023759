import { Component, input, InputSignal } from '@angular/core';
import { IMenuTab } from '../../../../entities/sidebar/menu-tab.entity';
import { SidebarItemComponent } from '../item/sidebar-item.component';
import { SidebarMenuComponent } from '../menu/sidebar-menu.component';

@Component({
  selector: 'app-menutab',
  templateUrl: './menutab.component.html',
  styles: ``,
  imports: [SidebarItemComponent, SidebarMenuComponent],
})
export class MenuTabComponent {
  menutab: InputSignal<IMenuTab> = input.required<IMenuTab>();
  expanded: InputSignal<boolean> = input.required<boolean>();
}

import { Component, inject, OnDestroy, OnInit, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { toSignal } from '@angular/core/rxjs-interop';
import { Subscription, tap } from 'rxjs';
import { ClientService } from '../../../services/client/client.service';
import {
  CMSFontColors,
  CMSFontSize,
} from '../../../constants/buttonGroups.enums';
import { ButtonGroupSupportService } from '../../../services/support/button-group-support.service';
import { BannerService } from '../../../services/api/banner/banner.service';
import { BannerFormsFactoryService } from '../../../factory/banner/banner-forms-factory.service';
import {
  BannerHorizontalAlignment,
  BannerVerticalAlignment,
  defaultBanner,
  IBanner,
  IBannerForm,
} from '../../../entities/banner-entity';
import { SectionComponent } from '../../../components/section/section.component';
import { LanguageSwitcherComponent } from '../../../components/form-inputs/language-switcher/language-switcher.component';
import { SectionHeadlineComponent } from '../../../components/section/section-header/section-headline/section-headline.component';
import { SectionHeaderComponent } from '../../../components/section/section-header/section-header.component';
import { SectionHeaderActionsComponent } from '../../../components/section/section-header/section-header-actions/section-header-actions.component';
import { ButtonComponent } from '../../../components/button/button.component';
import { AccessibilityBannerComponent } from '../../../components/accessibility-banner/accessibility-banner-component';
import { ToggleComponent } from '../../../components/form-inputs/toggle/toggle.component';
import { ImageSelectComponent } from '../../../components/form-inputs/image-select/image-select.component';
import { NumberInputComponent } from '../../../components/form-inputs/number-input/number-input.component';
import { TranslationAccessibilityTextInputComponent } from '../../../components/form-inputs/translation-accessibility-text-input/translation-accessibility-text-input.component';
import { TranslationTextInputComponent } from '../../../components/form-inputs/translation-text-input/translation-text-input.component';
import { ButtonGroupComponent } from '../../../components/form-inputs/button-group/button-group.component';
import { EnumToButtonOptionArrayPipe } from '../../../pipes/enum-to-button-option-array/enum-to-button-option-array.pipe';
import { DropDownComponent } from '../../../components/form-inputs/drop-down/drop-down.component';
import { ColorPickerInputComponent } from '../../../components/form-inputs/color-picker-input/color-picker-input.component';
import { FontSelectComponent } from '../../../components/form-inputs/font-select/font-select.component';
import { PopupService } from '../../../services/popup/popup.service';

@Component({
  selector: 'app-banner-page',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SectionComponent,
    LanguageSwitcherComponent,
    SectionHeadlineComponent,
    SectionHeaderComponent,
    SectionHeaderActionsComponent,
    ButtonComponent,
    AccessibilityBannerComponent,
    ToggleComponent,
    ImageSelectComponent,
    NumberInputComponent,
    TranslationAccessibilityTextInputComponent,
    TranslationTextInputComponent,
    ButtonGroupComponent,
    EnumToButtonOptionArrayPipe,
    DropDownComponent,
    ColorPickerInputComponent,
    FontSelectComponent,
  ],
  templateUrl: './banner-page.component.html',
  styles: ``,
})
export class BannerPageComponent implements OnInit, OnDestroy {
  buttonGroupSupportService = inject(ButtonGroupSupportService);
  popupService = inject(PopupService);
  clientService = inject(ClientService);
  bannerService = inject(BannerService);
  bannerFactory = inject(BannerFormsFactoryService);

  public bannerForm!: FormGroup<IBannerForm>;
  public languageForm: FormGroup = new FormGroup({
    language: new FormControl('deu', [Validators.required]),
  });

  banner: Signal<IBanner> = toSignal(
    this.bannerService.$entity.pipe(
      tap(banner => {
        this.bannerForm = this.bannerFactory.buildForm(banner);
        this.setInitialButtonStates(banner);
        this.createActivitySubscription();
      })
    )
  ) as Signal<IBanner>;
  savedBanner: Signal<IBanner> = toSignal(
    this.bannerService.$savedEntity.pipe(
      tap(banner => {
        this.bannerForm = this.bannerFactory.buildForm(banner);
      })
    )
  ) as Signal<IBanner>;

  // DataSources for Components
  protected readonly fontSizes = CMSFontSize;
  protected readonly fontColors = CMSFontColors;

  public readonly verticalAlignments = BannerVerticalAlignment;
  public readonly horizontalAlignments = BannerHorizontalAlignment;

  public readonly headlineFontSizeBtn: FormControl<string> = new FormControl();
  public readonly subHeadlineFontSizeBtn: FormControl<string> =
    new FormControl();
  public readonly textColorBtn: FormControl<string> = new FormControl();
  public readonly textFontBtn: FormControl<string> = new FormControl();

  protected showPopupChanges = false;
  protected showPopupToFactorySettings = false;

  private readonly sub = new Subscription();

  constructor() {
    this.bannerService.get$.next(null);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  ngOnInit(): void {
    window.onbeforeunload = (event: BeforeUnloadEvent) => {
      if (this.bannerForm.dirty) {
        event.preventDefault();
      }
    };
    this.sub.add(
      this.popupService.popupActionChanges$.subscribe(action => {
        this.resetFormChanges(action);
      })
    );
    this.sub.add(
      this.popupService.popupActionToFactory$.subscribe(() => {
        this.resetToFactorySettings();
      })
    );
  }

  createActivitySubscription() {
    this.sub.add(
      this.bannerForm.controls.imageActive.valueChanges.subscribe(isActive => {
        if (isActive) {
          this.bannerForm.controls.imageHeight.enable();
          this.bannerForm.controls.accessibleImage.enable();
        } else {
          this.bannerForm.controls.imageHeight.disable();
          this.bannerForm.controls.accessibleImage.disable();
        }
      })
    );
    this.sub.add(
      this.bannerForm.controls.textActive.valueChanges.subscribe(isActive => {
        if (isActive) {
          this.headlineFontSizeBtn.enable();
          this.subHeadlineFontSizeBtn.enable();
          this.textColorBtn.enable();
          this.textFontBtn.enable();
          this.bannerForm.controls.textColor.enable();
          this.bannerForm.controls.textFont.enable();
          this.bannerForm.controls.headlines.enable();
          this.bannerForm.controls.headlineFontSize.enable();
          this.bannerForm.controls.subHeadlines.enable();
          this.bannerForm.controls.subHeadlineFontSize.enable();
          this.bannerForm.controls.textAlignmentVertical.enable();
          this.bannerForm.controls.textAlignmentHorizontal.enable();
        } else {
          this.headlineFontSizeBtn.disable();
          this.subHeadlineFontSizeBtn.disable();
          this.textColorBtn.disable();
          this.textFontBtn.disable();
          this.bannerForm.controls.textColor.disable();
          this.bannerForm.controls.textColor.disable();
          this.bannerForm.controls.textFont.disable();
          this.bannerForm.controls.headlines.disable();
          this.bannerForm.controls.headlineFontSize.disable();
          this.bannerForm.controls.subHeadlines.disable();
          this.bannerForm.controls.subHeadlineFontSize.disable();
          this.bannerForm.controls.textAlignmentVertical.disable();
          this.bannerForm.controls.textAlignmentHorizontal.disable();
        }
      })
    );
  }

  onHeadlinesFontSizeChange(value: string): void {
    switch (value) {
      case CMSFontSize.Benutzerdefiniert:
        this.bannerForm.controls.headlineFontSize.enable();
        break;
      case CMSFontSize.Standard:
        this.bannerForm.controls.headlineFontSize.setValue(
          defaultBanner.headlineFontSize
        );
        this.bannerForm.controls.headlineFontSize.disable();
        break;
    }
  }

  onSubHeadlinesFontSizeChange(value: string): void {
    switch (value) {
      case CMSFontSize.Benutzerdefiniert:
        this.bannerForm.controls.subHeadlineFontSize.enable();
        break;
      case CMSFontSize.Standard:
        this.bannerForm.controls.subHeadlineFontSize.setValue(
          defaultBanner.subHeadlineFontSize
        );
        this.bannerForm.controls.subHeadlineFontSize.disable();
        break;
    }
  }

  onFontColorChange(value: string): void {
    switch (value) {
      case CMSFontColors.Benutzerdefiniert:
        this.bannerForm.controls.textColor.enable();
        break;
      default:
        this.bannerForm.controls.textColor.setValue(value);
        this.bannerForm.controls.textColor.disable();
        break;
    }
  }

  onFontChange(value: string): void {
    switch (value) {
      case CMSFontSize.Benutzerdefiniert:
        this.bannerForm.controls.textFont.enable();
        break;
      default:
        this.bannerForm.controls.textFont.setValue(defaultBanner.textFont);
        this.bannerForm.controls.textFont.disable();
        break;
    }
  }

  save(): void {
    this.bannerService.save$.next(
      this.bannerFactory.buildEntity(this.bannerForm)
    );
  }

  private setInitialButtonStates(banner: IBanner) {
    if (banner.headlineFontSize.toString() !== CMSFontSize.Standard) {
      this.headlineFontSizeBtn.setValue(CMSFontSize.Benutzerdefiniert);
    } else {
      this.headlineFontSizeBtn.setValue(CMSFontSize.Standard);
    }
    this.onHeadlinesFontSizeChange(this.headlineFontSizeBtn.value);

    if (banner.subHeadlineFontSize.toString() !== CMSFontSize.Standard) {
      this.subHeadlineFontSizeBtn.setValue(CMSFontSize.Benutzerdefiniert);
    } else {
      this.subHeadlineFontSizeBtn.setValue(CMSFontSize.Standard);
    }
    this.onSubHeadlinesFontSizeChange(this.subHeadlineFontSizeBtn.value);

    this.textColorBtn.setValue(
      this.buttonGroupSupportService.findValueOnEnum(
        banner.textColor.toString(),
        this.fontColors,
        CMSFontColors.Benutzerdefiniert
      )
    );
    this.onFontColorChange(this.textColorBtn.value);

    this.textFontBtn.setValue(
      banner.textFont ? CMSFontSize.Benutzerdefiniert : CMSFontSize.Standard
    );
    this.onFontChange(this.textFontBtn.value);
  }

  showPopUpChanges() {
    this.popupService.showPopupChanges();
  }

  resetToFactorySettings() {
    this.bannerForm.reset({
      id: this.banner().id,
      ...defaultBanner,
    });
    this.showPopupToFactorySettings = false;
  }

  resetFormChanges(reset: boolean) {
    if (reset) {
      this.bannerForm.reset({
        client: this.banner().client,
        imageActive: this.banner().imageActive,
        accessibleImage: this.banner().accessibleImage,
        imageHeight: this.banner().imageHeight,
        textActive: this.banner().textActive,
        headlines: this.banner().headlines,
        headlineFontSize: this.banner().headlineFontSize,
        subHeadlines: this.banner().subHeadlines,
        subHeadlineFontSize: this.banner().subHeadlineFontSize,
        textFont: this.banner().textFont,
        textColor: this.banner().textColor,
        textAlignmentVertical: this.banner().textAlignmentVertical,
        textAlignmentHorizontal: this.banner().textAlignmentHorizontal,
      });
    }
    this.showPopupChanges = false;
  }
}

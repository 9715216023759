<div class="text-initial box-border flex w-full rounded-lg p-4">
  @let hasError = ngControl.errors && ngControl.touched;

  <div class="group relative box-border flex gap-8 bg-inherit">
    <div
      class="peer flex h-10 items-center rounded-lg bg-gray-200 px-2 pr-1 text-gray-600 placeholder-transparent">
      <span class="material-symbols-rounded"> translate </span>
    </div>
    @for (
      option of languageService.languages;
      track $index;
      let index = $index
    ) {
      <label
        [attr.data-testid]="id() + index | appendTestId: 'label'"
        (click)="selectOption(option.iso)"
        class="flex cursor-pointer items-center">
        <input
          [attr.data-testid]="id() + index | appendTestId: 'input'"
          [formControl]="ngControl.control! | toFormControl"
          [id]="id() + index"
          [value]="option.iso"
          type="radio"
          class="hidden h-5 w-5 border-2"
          [ngClass]="{
            'form-input__error-border': hasError,
          }"
          [name]="id() + index" />
        <div
          class="flex h-10 flex-col justify-center"
          [ngClass]="{
            'border-primary-main text-primary-main border-b-2':
              option.iso === languageService.activeLanguage().iso,
          }"
          [attr.data-testid]="id() + index | appendTestId: 'option'">
          {{ option.name }}
        </div>
      </label>
    }
  </div>
  @if (hasError && !errorTemplate()) {
    <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
      <ng-content></ng-content>
    </app-error-message>
  }
  @if (hasError && errorTemplate()) {
    <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
      <ng-container *ngTemplateOutlet="errorTemplate()!"></ng-container>
    </app-error-message>
  }
</div>

import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICorporateDesign } from '../../entities/coporate-design-entity';
import { toSignal } from '@angular/core/rxjs-interop';
import { ILoginLogout } from '../../entities/login-logout.entity';
import { CorporateDesignService } from '../../services/api/corporateDesign/corporate-design.service';
import { LoginLogoutService } from '../../services/api/login-logout/login-logout.service';
import { LanguagePipe } from '../../pipes/language/language.pipe';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ButtonComponent } from '../button/button.component';
import { PasswordInputComponent } from '../form-inputs/password-input/password-input.component';
import { ILogin, ILoginForm } from '../../entities/login.entity';
import { LoginService } from '../../services/api/login/login.service';
import { TextInputComponent } from '../form-inputs/text-input/text-input.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  imports: [
    CommonModule,
    LanguagePipe,
    ReactiveFormsModule,
    ButtonComponent,
    TextInputComponent,
    PasswordInputComponent,
  ],
  templateUrl: './login.component.html',
  styles: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  private corporateDesignService: CorporateDesignService = inject(
    CorporateDesignService
  );
  private loginLogoutService: LoginLogoutService = inject(LoginLogoutService);
  private loginService: LoginService = inject(LoginService);
  corporateDesign: Signal<ICorporateDesign> = toSignal(
    this.corporateDesignService.$entity
  ) as Signal<ICorporateDesign>;
  login: Signal<ILoginLogout> = toSignal(
    this.loginLogoutService.$entity
  ) as Signal<ILoginLogout>;

  protected loginForm!: FormGroup<ILoginForm>;
  public email: FormControl<string> = new FormControl();
  public password: FormControl<string> = new FormControl();

  constructor(
    private fb: FormBuilder,
    private router: Router
  ) {
    this.corporateDesignService.get$.next(null);
    this.loginLogoutService.get$.next(null);
    this.loginForm = this.fb.group({
      email: this.fb.nonNullable.control('', [
        Validators.required,
        Validators.email,
      ]),
      password: this.fb.nonNullable.control('', [Validators.required]),
    });
  }

  loginUser() {
    const login: ILogin = {
      id: this.login().id,
      client: this.login().client,
      email: this.loginForm.controls.email.value,
      password: this.loginForm.controls.password.value,
    };
    this.loginService.loginUser(login);
    this.router.navigate(['/']);
  }
}

import { z, ZodSchema } from 'zod';
import { FormControl } from '@angular/forms';

export const TextSchema: ZodSchema = z.object({
  id: z.string().uuid().nullable(),
  value: z.string(),
  isoCode: z.string().min(3).max(3),
});

export interface IText {
  id?: string;
  value: string;
  isoCode: string;
}

export interface ITextForm {
  id: FormControl<string | null>;
  value: FormControl<string>;
  isoCode: FormControl<string>;
}

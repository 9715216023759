import { inject, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import IFooter, {
  defaultFooter,
  IFooterForm,
} from '../../entities/footer-entity';
import { Factory } from '../factory';
import { ClientService } from '../../services/client/client.service';
import { ImageFormsFactoryService } from '../image/image-forms-factory.service';
import { LinkFormsFactoryService } from '../link/link-forms-factory.service';
import { ILogoAreaForm } from '../../entities/logo-area-entity';
import { IAddressAreaForm } from '../../entities/address-area-entity';
import { IPaymentAreaForm } from '../../entities/payment-area-entity';
import { ILinkAreaForm } from '../../entities/link-area-entity';

@Injectable({
  providedIn: 'root',
})
export class FooterFormsFactoryService extends Factory<IFooterForm, IFooter> {
  clientService = inject(ClientService);
  formBuilder = inject(FormBuilder);
  imageFormsFactory = inject(ImageFormsFactoryService);
  linkFormsFactory = inject(LinkFormsFactoryService);

  buildForm(entity: IFooter = defaultFooter): FormGroup<IFooterForm> {
    return this.formBuilder.group<IFooterForm>({
      id: this.formBuilder.control(entity.id || null),
      client: this.formBuilder.nonNullable.control(this.clientService.client, [
        Validators.required,
      ]),
      backgroundColor: this.formBuilder.nonNullable.control(
        entity.backgroundColor,
        [Validators.required]
      ),
      logoArea: this.formBuilder.group<ILogoAreaForm>({
        id: this.formBuilder.control(entity.logoArea.id || null),
        toggle: this.formBuilder.nonNullable.control(entity.logoArea.toggle, [
          Validators.required,
        ]),
        headlines: this.formBuilder.nonNullable.control(
          entity.logoArea.headlines
        ),
        accessibleImages: this.imageFormsFactory.buildFormArray(
          entity.logoArea.accessibleImages
        ),
      }),
      addressArea: this.formBuilder.group<IAddressAreaForm>({
        id: this.formBuilder.control(entity.addressArea.id || null),
        toggle: this.formBuilder.nonNullable.control(
          entity.addressArea.toggle,
          [Validators.required]
        ),
        headlines: this.formBuilder.nonNullable.control(
          entity.addressArea.headlines
        ),
        texts: this.formBuilder.nonNullable.control(entity.addressArea.texts),
      }),
      paymentArea: this.formBuilder.group<IPaymentAreaForm>({
        id: this.formBuilder.control(entity.paymentArea.id || null),
        toggle: this.formBuilder.nonNullable.control(
          entity.paymentArea.toggle,
          [Validators.required]
        ),
        headlines: this.formBuilder.nonNullable.control(
          entity.paymentArea.headlines
        ),
        accessibleImages: this.imageFormsFactory.buildFormArray(
          entity.paymentArea.accessibleImages
        ),
      }),
      linkArea: this.formBuilder.group<ILinkAreaForm>({
        id: this.formBuilder.control(entity.linkArea.id || null),
        headlines: this.formBuilder.nonNullable.control(
          entity.linkArea.headlines
        ),
        links: this.linkFormsFactory.buildFormArray(entity.linkArea.links),
      }),
    });
  }

  buildEntity(form: FormGroup<IFooterForm>): IFooter {
    /*
      getRawValue() also gives the values of disabled controls
      value only gives the value of enables controls
      since we disable controls with standard values from buttongroups
      we obviously want to keep and sent them
    */
    const footer = form.getRawValue() as unknown as IFooter;
    this.removeNullIds(footer);

    return footer as unknown as IFooter;
  }
}

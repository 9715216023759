import { Injectable } from '@angular/core';
import { IImage } from '../../../entities/image-entity';
import { CRUDService } from '../../crud/crud.service';
import { ImageGateway } from './imageGateway';

@Injectable({
  providedIn: 'root',
})
export class ImageService extends CRUDService<
  IImage[],
  IImage[],
  null,
  never,
  IImage,
  FormData
> {
  constructor() {
    super(new ImageGateway());
  }
}

import {
  Component,
  HostBinding,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlContainer,
  FormGroupDirective,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { ErrorMessageComponent } from '../../error-message/error-message.component';
import { ToFormControlPipe } from '../../../pipes/to-form-control/to-form-control.pipe';
import { AppendTestIdPipe } from '../../../pipes/append-test-id/append-test-id.pipe';
import { Subscription } from 'rxjs';
import { LanguageService } from '../../../services/language/language.service';
import { FormInputComponent } from '../form-input.component';

@Component({
  selector: 'app-language-switcher',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    ToFormControlPipe,
    AppendTestIdPipe,
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  styleUrl: '../form-inputs.css',
  templateUrl: './language-switcher.component.html',
})
export class LanguageSwitcherComponent
  extends FormInputComponent
  implements OnDestroy, OnInit
{
  subscription: Subscription = new Subscription();
  languageService = inject(LanguageService);

  @HostBinding('class') override get formInputClasses(): string {
    return '';
  }

  constructor(public override ngControl: NgControl) {
    super(ngControl);
  }

  public selectOption(option: string): void {
    this.languageService.setActiveLanguage(option);
  }

  ngOnInit(): void {
    this.subscription.add(
      this.ngControl.control?.valueChanges.subscribe(isoCode => {
        this.languageService.setActiveLanguage(isoCode);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

import { Injectable, signal, WritableSignal } from '@angular/core';
import { IText } from '../../entities/text-entity';

export interface ILanguage {
  id: number;
  name: string;
  iso: string;
}

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private _languages: ILanguage[] = [
    { id: 1, name: 'Deutsch', iso: 'deu' },
    { id: 2, name: 'English', iso: 'gbr' },
    { id: 3, name: 'Einfache Sprache', iso: 'ein' },
  ];
  activeLanguage: WritableSignal<ILanguage> = signal(this._languages[0]);

  get languages(): ILanguage[] {
    return this._languages;
  }

  getActiveLanguageText(texts: IText[]): IText | undefined {
    return texts.find(
      (text: IText) => text.isoCode === this.activeLanguage().iso
    );
  }

  setActiveLanguage(isoCode: string): void {
    const lang = this._languages.find(lang => lang.iso === isoCode);
    if (lang) {
      this.activeLanguage.set(lang);
    }
  }
}

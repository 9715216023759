import { z, ZodSchema } from 'zod';
import {
  AccessibleImageSchema,
  IAccessibleImage,
  IAccessibleImageForm,
} from './image-entity';
import { IText, TextSchema } from './text-entity';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

export const PaymentAreaSchema: ZodSchema = z.object({
  id: z.string().uuid().nullable(),
  toggle: z.boolean(),
  headlines: z.array(TextSchema),
  accessibleImages: z.array(AccessibleImageSchema),
});

export interface IPaymentArea {
  id?: string;
  toggle: boolean;
  headlines: IText[];
  accessibleImages: IAccessibleImage[];
}

export interface IPaymentAreaForm {
  id: FormControl<string | null>;
  toggle: FormControl<boolean>;
  headlines: FormControl<IText[]>;
  accessibleImages: FormArray<FormGroup<IAccessibleImageForm>>;
}

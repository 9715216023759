import { Component, input, InputSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormInputComponent } from './form-input.component';
import { NgControl } from '@angular/forms';
import { SelectionOptionValueToNamePipe } from '../../pipes/selection-option-value-to-name/selection-option-value-to-name.pipe';

export interface ObjectOption {
  [key: string]: unknown;
}

type SimpleOption = unknown;

export type SelectOption = ObjectOption | SimpleOption;

@Component({
  selector: 'app-form-input-select',
  imports: [CommonModule],
  template: '',
})
export class FormInputSelectComponent extends FormInputComponent {
  public options: InputSignal<SelectOption[]> = input.required<any[]>();
  public value: InputSignal<string | undefined> = input<string>();
  public name: InputSignal<string | undefined> = input<string>();
  protected readonly toSelectOptionName = new SelectionOptionValueToNamePipe();

  constructor(public override ngControl: NgControl) {
    super(ngControl);
  }

  public selectOption(option: SelectOption): void {
    if (!this.ngControl.control!.disabled) {
      const findValue = (option: SelectOption, value?: string) => {
        return option &&
          value &&
          Object.prototype.hasOwnProperty.call(option as ObjectOption, value)
          ? (option as ObjectOption)[value]
          : option;
      };

      this.ngControl.control!.setValue(findValue(option, this.value()));
      this.ngControl.control!.markAsTouched();
    }
  }

  protected findIndexFromValue(value: unknown): number | undefined {
    const matchingOption: SelectOption =
      this.toSelectOptionName.findOptionByValue(
        value,
        this.options(),
        this.name()!,
        this.value()
      );
    return this.options().findIndex(option => option == matchingOption);
  }
}

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlContainer,
  FormGroupDirective,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { ErrorMessageComponent } from '../../error-message/error-message.component';
import { FormInputComponent } from '../form-input.component';
import { ToFormControlPipe } from '../../../pipes/to-form-control/to-form-control.pipe';
import { AppendTestIdPipe } from '../../../pipes/append-test-id/append-test-id.pipe';
import {
  OwlDateTimeComponent,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-date-input',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    ToFormControlPipe,
    AppendTestIdPipe,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  styleUrl: '../form-inputs.css',
  templateUrl: './date-input.component.html',
})
export class DateInputComponent
  extends FormInputComponent
  implements OnInit, OnDestroy
{
  private $handleKeyUpEvent!: Subscription;
  @ViewChild('datePicker') dateInput!: OwlDateTimeComponent<Date>;

  constructor(public override ngControl: NgControl) {
    super(ngControl);
  }

  ngOnInit() {
    this.$handleKeyUpEvent = fromEvent<
      KeyboardEvent & { target?: { id: string } }
    >(document, 'keyup').subscribe(event => {
      const isCorrectTarget = event.target?.id === this.id();
      if (isCorrectTarget) {
        event.preventDefault();
        if (event.key === ' ' && !this.dateInput.opened) {
          this.dateInput.open();
        }
      } else if (event.key === ' ' && this.dateInput.opened) {
        this.dateInput.close();
      }
    });
  }

  ngOnDestroy() {
    this.$handleKeyUpEvent.unsubscribe();
  }
}

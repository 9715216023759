@if (ticket && ticketTileForm) {
  <div class="flex w-full flex-row">
    <div class="w-8/12 px-4 pb-4" [formGroup]="ticketTileForm">
      <!-- @TODO Breadcrumbs -->
      <div class="h-12 py-4 text-sm">BREADCRUMBS</div>
      <app-section>
        <app-section-header>
          <div>
            <app-section-headline>Ticket</app-section-headline>
            <div class="text-lg text-gray-600">
              {{ ticket!.type }}, {{ ticket!.name }}
            </div>
          </div>
          <app-section-header-actions>
            <app-button
              [disabled]="ticketTileForm.invalid"
              (click)="saveTicket()"
              [style]="'primary'"
              [type]="'submit'"
              >Speichern</app-button
            >
            <app-button
              [style]="'secondary'"
              [disabled]="!ticketTileForm.dirty"
              (click)="resetTicketForm()"
              [type]="'reset'"
              >Abbrechen</app-button
            >
          </app-section-header-actions>
        </app-section-header>
        <app-section>
          <div class="flex flex-row items-center justify-between">
            <app-toggle
              [labelLeft]="true"
              formControlName="active"
              label="Ticket aktiv setzen"
              id="ticket-active"></app-toggle>
            <div class="min-w-1/2 flex flex-row gap-4">
              <app-date-input
                formControlName="startDate"
                label="Ab"
                id="ticket-start"></app-date-input>
              <app-date-input
                formControlName="endDate"
                label="Bis (optional)"
                id="ticket-end"></app-date-input>
            </div>
          </div>
        </app-section>
        <app-ticket-frame [styling]="ticketStyle.frame">
          <div upper class="flex flex-col gap-4">
            <div class="text-lg font-semibold">Oberer Abschnitt</div>
            <app-translation-text-input
              formControlName="title"
              label="Titel"
              id="ticket-title">
            </app-translation-text-input>
            <app-number-input
              formControlName="price"
              label="Preis"
              id="ticket-price">
            </app-number-input>
            <app-translation-text-area
              formControlName="description"
              label="Beschreibungstext"
              id="ticket-description">
            </app-translation-text-area>
          </div>
          <div lower class="flex flex-col gap-4">
            <div class="text-lg font-semibold">Unterer Abschnitt</div>
            <app-translation-text-input
              formControlName="detailsTitle"
              label="Erweitern Schaltfläche Bezeichnung"
              id="ticket-details-headline">
            </app-translation-text-input>
            <div class="flex flex-row items-center justify-between">
              <div class="font-semibold">Details</div>
              <app-button
                [style]="'primary'"
                [type]="'button'"
                (click)="addDetail()">
                <span class="material-symbols-rounded pr-2"> add </span>
                Neues Detail
              </app-button>
            </div>
            @for (
              detail of ticketTileForm.controls.details.controls;
              track $index;
              let index = $index
            ) {
              <div class="flex flex-row gap-2">
                <app-icon-picker
                  class="w-3/12"
                  [formControl]="detail.controls.icon"
                  label="Icon"
                  [id]="'ticket-details-icon-' + index"></app-icon-picker>
                <app-translation-text-input
                  class="w-9/12"
                  [formControl]="detail.controls.text"
                  label="Text"
                  [id]="
                    'ticket-details-text-' + index
                  "></app-translation-text-input>
                <app-button
                  [style]="'error'"
                  [type]="'button'"
                  (click)="removeDetail(index)">
                  <span class="material-symbols-rounded"> mop </span>
                </app-button>
              </div>
            }
            <app-translation-text-input
              formControlName="button"
              label="Ticket Aktion Schaltfläche Bezeichnung"
              id="ticket-button">
              ></app-translation-text-input
            >
          </div>
        </app-ticket-frame>
      </app-section>
    </div>
    <div
      class="border-l-1 w-4/12 min-w-72 border-l-gray-300 bg-gray-100 px-5 py-12">
      <div class="pb-4 text-2xl font-semibold">Design Vorschau</div>
      <app-ticket-tile
        [id]="'ticket-preview'"
        [styling]="ticketStyle"
        [ticket]="
          ticketTileForm.value | toTicketTile: ticket!.name
        "></app-ticket-tile>
    </div>
  </div>
}

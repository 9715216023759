import { Pipe, PipeTransform } from '@angular/core';
import { ITicketTile } from '../../components/ticket-tile/ticket-tile.component';
import { FormGroup } from '@angular/forms';
import { ITicketTileForm } from '../../pages/cms/tickets/ticket-edit-page/ticket-edit-page.component';

// @TODO maybe actually provide default translations

export const defaultTicketTile: ITicketTile = {
  guid: 'default-ticket',
  detailsTitle: [],
  details: [],
  buttonText: [],
  price: 0,
  afterPriceText: '',
  via: ['VIA1', 'VIA2', 'VIA3'],
  description: [],
  viaBanner: 'list',
  title: [],
};

@Pipe({
  name: 'toTicketTile',
})
export class ToTicketTilePipe implements PipeTransform {
  transform(
    ticket: FormGroup<ITicketTileForm>['value'],
    guid: string
  ): ITicketTile {
    return {
      guid,
      detailsTitle: ticket.detailsTitle ?? defaultTicketTile.detailsTitle,
      details:
        ticket?.details?.map(detail => {
          return {
            icon: detail.icon,
            text: detail.text ?? [],
          };
        }) ?? [],
      buttonText: ticket.button ?? [],
      price: ticket.price ?? defaultTicketTile.price,
      afterPriceText: defaultTicketTile.afterPriceText,
      via: defaultTicketTile.via, //@TODO use proper via if applicable
      description: ticket.description ?? defaultTicketTile.description,
      viaBanner: 'list',
      title: ticket.title ?? defaultTicketTile.title,
    };
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  InputSignal,
  OnChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViaSupportService } from '../../services/support/via-support.service';

@Component({
  selector: 'app-via-chip',
  imports: [CommonModule],
  templateUrl: './via-chip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViaChipComponent implements OnChanges {
  private viaService: ViaSupportService = inject(ViaSupportService);
  public readonly via: InputSignal<string[]> = input.required();
  public viaAriaLabel = '';

  ngOnChanges(): void {
    this.viaAriaLabel = this.viaService.buildViaString(this.via());
  }
}

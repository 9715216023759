import { Observable } from 'rxjs';
import { IGetGateway } from '../../gateways';
import { RestCallBuilder } from '../../../builder/rest/rest.builder';
import {
  defaultTicketsOverview,
  ITicketsOverview,
} from '../../../entities/tickets-overview-entity';
import { WrapperSchema } from '../../../entities/sort-groups-entity';

export class TicketsOverviewGateway
  implements IGetGateway<string, ITicketsOverview>
{
  private restCallBuilder: RestCallBuilder<ITicketsOverview> =
    new RestCallBuilder<ITicketsOverview>(defaultTicketsOverview);

  get(query: string): Observable<ITicketsOverview> {
    return this.restCallBuilder
      .get(`/tickets-overview/${query}`)
      .validate(WrapperSchema)
      .handleErrors()
      .build();
  }
}

import { Component, inject, input, InputSignal, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ICombinedTicket,
  CombinedTicketsService,
} from '../../../../services/tickets/combined-tickets.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { SectionComponent } from '../../../../components/section/section.component';
import { SectionHeaderComponent } from '../../../../components/section/section-header/section-header.component';
import { SectionHeadlineComponent } from '../../../../components/section/section-header/section-headline/section-headline.component';
import { SectionHeaderActionsComponent } from '../../../../components/section/section-header/section-header-actions/section-header-actions.component';
import { TicketFrameComponent } from '../../../../components/ticket-frame/ticket-frame.component';
import {
  ITicketTileStyling,
  TicketTileComponent,
} from '../../../../components/ticket-tile/ticket-tile.component';
import { ToTicketTilePipe } from '../../../../pipes/to-ticket-tile/to-ticket-tile.pipe';
import { ToggleComponent } from '../../../../components/form-inputs/toggle/toggle.component';
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { DateInputComponent } from '../../../../components/form-inputs/date-input/date-input.component';
import { ButtonComponent } from '../../../../components/button/button.component';
import { NumberInputComponent } from '../../../../components/form-inputs/number-input/number-input.component';
import { TranslationTextInputComponent } from '../../../../components/form-inputs/translation-text-input/translation-text-input.component';
import { TranslationTextAreaComponent } from '../../../../components/form-inputs/translation-text-area/translation-text-area.component';
import { IText } from '../../../../entities/text-entity';
import { IconPickerComponent } from '../../../../components/form-inputs/icon-picker/icon-picker.component';
import { TicketTileFactoryService } from '../../../../factory/ticketTile/ticket-tile-factory.service';
import { tap } from 'rxjs';
import { TicketService } from '../../../../services/api/tickets/ticket.service';
import { ITicket } from '../../../../entities/ticket-entity';
import { Router } from '@angular/router';

export interface ITicketTileDetailForm {
  icon: FormControl<string>;
  text: FormControl<IText[]>;
}

export interface ITicketTileForm {
  id: FormControl<string | undefined>;
  client: FormControl<string>;
  ticketId: FormControl<string>;
  overviewId: FormControl<string>;
  active: FormControl<boolean>;
  startDate: FormControl<string>;
  endDate: FormControl<string>;
  title: FormControl<IText[]>;
  price: FormControl<number>;
  description: FormControl<IText[]>;
  detailsTitle: FormControl<IText[]>;
  details: FormArray<FormGroup<ITicketTileDetailForm>>;
  button: FormControl<IText[]>;
}

@Component({
  selector: 'app-ticket-edit-page',
  imports: [
    CommonModule,
    SectionComponent,
    SectionHeaderComponent,
    SectionHeadlineComponent,
    SectionHeaderActionsComponent,
    TicketFrameComponent,
    TicketTileComponent,
    ToTicketTilePipe,
    ToggleComponent,
    ReactiveFormsModule,
    DateInputComponent,
    TranslationTextInputComponent,
    TranslationTextAreaComponent,
    ButtonComponent,
    NumberInputComponent,
    IconPickerComponent,
  ],
  templateUrl: './ticket-edit-page.component.html',
})
export class TicketEditPageComponent {
  private readonly ticketTileFactory = inject(TicketTileFactoryService);
  private readonly ticketService = inject(TicketService);
  protected ticket: ICombinedTicket | undefined;
  public ticketTileForm!: FormGroup<ITicketTileForm>;
  private readonly router: Router = inject(Router);

  // TODO this one will become obsolete once productGroup, sortGroup and subscription merge moves to cmsService
  public combinedTicketsService: CombinedTicketsService = inject(
    CombinedTicketsService
  );

  public readonly ticketId: InputSignal<string> = input.required();
  public readonly tickets: Signal<ICombinedTicket[] | undefined> = toSignal(
    this.combinedTicketsService.$tickets.pipe(
      tap(tickets => {
        const ticket = this.findTicket(tickets, this.ticketId());
        this.ticket = JSON.parse(JSON.stringify(ticket));
        this.ticketTileForm = this.ticketTileFactory.buildForm(ticket?.data);
      })
    )
  );
  public readonly savedTicket: Signal<ITicket | undefined> = toSignal(
    this.ticketService.$savedEntity.pipe(
      tap(() => {
        this.ticketTileForm.markAsUntouched();
        this.router.navigate(['/cms/tickets']);
      })
    )
  );

  protected readonly ticketStyle: ITicketTileStyling = {
    frame: {
      borderWidth: '1px',
      borderColor: 'rgb(197 202 207)',
      backgroundColor: 'white',
    },
    list: {
      iconColor: 'blue',
    },
  };

  private findTicket(
    tickets: ICombinedTicket[] = [],
    id: string
  ): ICombinedTicket | undefined {
    return tickets?.find(ticket => ticket.id === id);
  }

  public saveTicket(): void {
    this.ticketService.save$.next(
      this.ticketTileFactory.buildEntity(this.ticketTileForm)
    );
  }

  public resetTicketForm(): void {
    this.ticketTileForm.reset(JSON.parse(JSON.stringify(this.ticket!.data)));
  }

  public addDetail(): void {
    this.ticketTileForm.controls.details.push(
      this.ticketTileFactory.createTicketTileDetailForm()
    );
  }

  public removeDetail(index: number): void {
    this.ticketTileForm.controls.details.removeAt(index);
  }
}

import {
  Component,
  HostBinding,
  input,
  InputSignal,
  TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlContainer,
  FormGroupDirective,
  NgControl,
} from '@angular/forms';
import { VALUE_ACCESSOR } from './valueAccessor';

@Component({
  selector: 'app-form-input',
  imports: [CommonModule],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  template: '',
})
export class FormInputComponent {
  public formControlName: InputSignal<string | undefined> = input<string>();
  public label: InputSignal<string | undefined> = input<string>();
  public placeholder: InputSignal<string | undefined> = input<string>();
  public id: InputSignal<string> = input.required<string>();
  public errorTemplate: InputSignal<TemplateRef<unknown> | undefined> =
    input<TemplateRef<unknown>>();

  @HostBinding('class') get formInputClasses(): string {
    return 'w-full';
  }

  constructor(public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = VALUE_ACCESSOR;
    }
  }
}

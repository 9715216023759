import { ChangeDetectorRef, Component, inject, OnDestroy, OnInit, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionComponent } from '../../../components/section/section.component';
import { SectionHeaderComponent } from '../../../components/section/section-header/section-header.component';
import { SectionHeadlineComponent } from '../../../components/section/section-header/section-headline/section-headline.component';
import { ClientService } from '../../../services/client/client.service';
import {
  defaultLoginLogout,
  ILoginLogout,
  ILoginLogoutForm,
} from '../../../entities/login-logout.entity';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { toSignal } from '@angular/core/rxjs-interop';
import { Subscription, tap } from 'rxjs';
import { TranslationTextInputComponent } from '../../../components/form-inputs/translation-text-input/translation-text-input.component';
import { LoginLogoutService } from '../../../services/api/login-logout/login-logout.service';
import { LoginLogoutFormsFactoryService } from '../../../factory/login-logout/login-logout.factory';
import { ButtonComponent } from '../../../components/button/button.component';
import { SectionHeaderActionsComponent } from '../../../components/section/section-header/section-header-actions/section-header-actions.component';
import { PopupService } from '../../../services/popup/popup.service';
import { LanguageSwitcherComponent } from '../../../components/form-inputs/language-switcher/language-switcher.component';

@Component({
  selector: 'app-login-logout',
  imports: [
    CommonModule,
    SectionComponent,
    SectionHeaderComponent,
    SectionHeadlineComponent,
    ReactiveFormsModule,
    TranslationTextInputComponent,
    ButtonComponent,
    SectionHeaderActionsComponent,
    LanguageSwitcherComponent,
  ],
  templateUrl: './login-logout.component.html',
  styles: '',
})
export class LoginLogoutComponent implements OnInit, OnDestroy {
  clientService = inject(ClientService);
  loginService = inject(LoginLogoutService);
  loginFormsFactory = inject(LoginLogoutFormsFactoryService);
  private popupService = inject(PopupService);

  public loginForm!: FormGroup<ILoginLogoutForm>;

  public languageForm: FormGroup = new FormGroup({
    languageSelect: new FormControl('deu', [Validators.required]),
  });

  login: Signal<ILoginLogout> = toSignal(
    this.loginService.$entity.pipe(
      tap(login => {
        this.loginForm = this.loginFormsFactory.buildForm(login);
      })
    )
  ) as Signal<ILoginLogout>;
  savedEntity: Signal<ILoginLogout | undefined> = toSignal(
    this.loginService.$savedEntity.pipe(
      tap(login => {
        this.loginForm = this.loginFormsFactory.buildForm(login);
      })
    )
  ) as Signal<ILoginLogout>;

  private readonly sub = new Subscription();

  protected showPopupChanges = false;
  protected showPopupToFactorySettings = false;

  constructor(private cdRef: ChangeDetectorRef) {
    this.loginService.get$.next(null);
  }

  ngOnInit(): void {
    window.onbeforeunload = (event: BeforeUnloadEvent) => {
      if (this.loginForm.dirty) {
        event.preventDefault();
      }
    };
    this.sub.add(
      this.popupService.popupActionChanges$.subscribe(action => {
        this.resetFormChanges(action);
      })
    );
    this.sub.add(
      this.popupService.popupActionToFactory$.subscribe(() => {
        this.resetToFactorySettings();
      })
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  save(): void {
    this.loginService.save$.next(
      this.loginFormsFactory.buildEntity(this.loginForm)
    );
  }

  showPopUpToFactorySettings() {
    this.popupService.showPopupToFactory();
  }

  showPopUpChanges() {
    this.popupService.showPopupChanges();
  }

  resetFormChanges(reset: boolean) {
    if (reset) {
      this.loginForm.reset({
        loginButton: this.login().loginButton,
        loginHeader: this.login().loginHeader,
        email: this.login().email,
        password: this.login().password,
        separation: this.login().separation,
        register: this.login().register,
        passwordReset: this.login().passwordReset,
        passwordResetLink: this.login().passwordResetLink,
        logoutAltText: this.login().logoutAltText,
      });
    }
    this.showPopupChanges = false;
  }

  resetToFactorySettings() {
    this.loginForm.reset({
      id: this.login().id,
      ...defaultLoginLogout,
    });
    this.showPopupToFactorySettings = false;
  }
}

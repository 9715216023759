import { z, ZodSchema } from 'zod';
import { FontSchema, IFont } from './font-entity';
import {
  AccessibleImageSchema,
  defaultAccessibleImage,
  IAccessibleImage,
  IAccessibleImageForm,
} from './image-entity';
import { IText, TextSchema } from './text-entity';
import { FormControl, FormGroup } from '@angular/forms';

export enum BannerVerticalAlignment {
  Links = 'left',
  Mitte = 'center',
  Rechts = 'right',
}

export enum BannerHorizontalAlignment {
  Oben = 'top',
  Mitte = 'center',
  Unten = 'bottom',
}

export const BannerSchema: ZodSchema = z.object({
  id: z.string().uuid().nullable(),
  client: z.string(),
  imageActive: z.boolean(),
  accessibleImage: AccessibleImageSchema.nullable(),
  imageHeight: z.number(),
  textActive: z.boolean(),
  headlines: z.array(TextSchema),
  headlineFontSize: z.number(),
  subHeadlines: z.array(TextSchema),
  subHeadlineFontSize: z.number(),
  textFont: FontSchema.nullable(),
  textColor: z.string(),
  textAlignmentVertical: z.enum(
    Object.values(BannerVerticalAlignment) as [string, ...string[]]
  ),
  textAlignmentHorizontal: z.enum(
    Object.values(BannerHorizontalAlignment) as [string, ...string[]]
  ),
});

export interface IBanner {
  id?: string;
  client: string;
  imageActive: boolean;
  accessibleImage: IAccessibleImage;
  imageHeight: number;
  textActive: boolean;
  headlines: IText[];
  headlineFontSize: number;
  subHeadlines: IText[];
  subHeadlineFontSize: number;
  textFont: IFont | null;
  textColor: string;
  textAlignmentVertical: BannerVerticalAlignment;
  textAlignmentHorizontal: BannerHorizontalAlignment;
}

export interface IBannerForm {
  id: FormControl<string | null>;
  client: FormControl<string>;
  imageActive: FormControl<boolean>;
  textActive: FormControl<boolean>;
  accessibleImage: FormGroup<IAccessibleImageForm>;
  imageHeight: FormControl<number>;
  headlines: FormControl<IText[] | null>;
  headlineFontSize: FormControl<number>;
  subHeadlines: FormControl<IText[] | null>;
  subHeadlineFontSize: FormControl<number>;
  textAlignmentVertical: FormControl<BannerVerticalAlignment>;
  textAlignmentHorizontal: FormControl<BannerHorizontalAlignment>;
  textFont: FormControl<IFont | null>;
  textColor: FormControl<string>;
}

export const defaultBanner: IBanner = {
  client: '',
  imageActive: true,
  accessibleImage: defaultAccessibleImage,
  imageHeight: 400,
  textActive: true,
  headlines: [{ value: 'Dein Ticket, dein Weg', isoCode: 'deu' }],
  headlineFontSize: 32,
  subHeadlines: [
    { value: 'Einfach online buchen und stressfrei reisen!', isoCode: 'deu' },
  ],
  subHeadlineFontSize: 14,
  textFont: null,
  textColor: '#FFFFFF',
  textAlignmentVertical: BannerVerticalAlignment.Mitte,
  textAlignmentHorizontal: BannerHorizontalAlignment.Mitte,
};

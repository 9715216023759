<div class="overflow-hidden">
  <div
    class="rounded-t-2xl p-6"
    [style.background-color]="styling().backgroundColor"
    [style.border-left-width]="styling().borderWidth"
    [style.border-right-width]="styling().borderWidth"
    [style.border-top-width]="styling().borderWidth"
    [style.border-color]="styling().borderColor"
    [style.margin-bottom]="'-' + styling().borderWidth + ''">
    <ng-content select="[upper]"></ng-content>
  </div>
  <div class="relative flex flex-row justify-between">
    <div
      class="z-1 box-border rounded-r-2xl py-3 pl-3"
      [style.border-top-width]="styling().borderWidth"
      [style.border-right-width]="styling().borderWidth"
      [style.border-bottom-width]="styling().borderWidth"
      [style.border-color]="styling().borderColor"></div>
    <div
      class="absolute -left-3 box-border rounded-r-2xl p-3"
      [style.top]="'-0px'"
      [style.bottom]="'-0px'"
      [style.box-shadow]="'10px 0 0 0 ' + styling().backgroundColor"></div>
    <div
      class="z-1 w-[calc(100%+2rem)] justify-between"
      [style.background-color]="styling().backgroundColor">
      <div
        class="mt-3 border-dashed"
        [style.border-top-width]="styling().borderWidth"
        [style.border-color]="styling().borderColor"></div>
    </div>
    <div
      class="z-1 rounded-l-2xl py-3 pr-3"
      [style.border-bottom-width]="styling().borderWidth"
      [style.border-left-width]="styling().borderWidth"
      [style.border-top-width]="styling().borderWidth"
      [style.border-color]="styling().borderColor"></div>
    <div
      class="absolute -right-3 box-border rounded-l-2xl p-3"
      [style.top]="'-0px'"
      [style.bottom]="'-0px'"
      [style.box-shadow]="'-10px 0 0 0 ' + styling().backgroundColor"></div>
  </div>
  <div
    class="rounded-b-2xl p-6"
    [style.background-color]="styling().backgroundColor"
    [style.margin-top]="'-' + styling().borderWidth + ''"
    [style.border-bottom-width]="styling().borderWidth"
    [style.border-left-width]="styling().borderWidth"
    [style.border-right-width]="styling().borderWidth"
    [style.border-color]="styling().borderColor">
    <ng-content select="[lower]"></ng-content>
  </div>
</div>

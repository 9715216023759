import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { CorporatePageComponent } from '../corporate/corporate-page.component';
import { PopupService } from '../../../services/popup/popup.service';

@Injectable({
  providedIn: 'root',
})
export class CorporateFormChangedGuard
  implements CanDeactivate<CorporatePageComponent>
{
  constructor(private popupService: PopupService) {}
  canDeactivate(
    component: CorporatePageComponent
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (component.corporateDesignForm.touched) {
      this.popupService.showPopupChanges();
      return false;
    }
    return true;
  }
}

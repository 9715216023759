import { Component, input, InputSignal, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorMessageComponent } from '../../error-message/error-message.component';
import {
  ControlContainer,
  FormControl,
  FormGroupDirective,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { FormInputComponent } from '../form-input.component';
import { ToFormControlPipe } from '../../../pipes/to-form-control/to-form-control.pipe';
import { AppendTestIdPipe } from '../../../pipes/append-test-id/append-test-id.pipe';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-toggle',
  imports: [
    CommonModule,
    ErrorMessageComponent,
    ReactiveFormsModule,
    ToFormControlPipe,
    AppendTestIdPipe,
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  styleUrl: '../form-inputs.css',
  templateUrl: './toggle.component.html',
})
export class ToggleComponent extends FormInputComponent {
  public labelLeft: InputSignal<boolean> = input(false);

  constructor(public override ngControl: NgControl) {
    super(ngControl);
  }

  /*
   * Necessary so clicking the label also change the controls value
   * touched needs to be set too, since type=checkbox does not propagate it by itself
   * */
  public toggle(): void {
    if (this.ngControl.control && !this.ngControl.control.disabled) {
      this.ngControl.control!.setValue(!this.ngControl.control.value);
      this.ngControl.control!.markAsTouched();
      this.ngControl.control!.markAsDirty();
    }
  }
}

import { z, ZodSchema } from 'zod';
import {
  AccessibleImageSchema,
  defaultAccessibleImage,
  IAccessibleImage,
  IAccessibleImageForm,
} from './image-entity';
import { IText, TextSchema } from './text-entity';
import { FormControl, FormGroup } from '@angular/forms';

export const HeaderSchema: ZodSchema = z.object({
  id: z.string().uuid().nullable(),
  client: z.string(),
  toggle: z.boolean(),
  logo: AccessibleImageSchema.nullable(),
  backgroundColor: z.string(),
  fontColor: z.string(),
  headlines: z.array(TextSchema),
  headlineFontSize: z.number(),
  leftImage: AccessibleImageSchema.nullable(),
  rightImage: AccessibleImageSchema.nullable(),
});

export interface IHeader {
  id?: string;
  client: string;
  toggle: boolean;
  logo: IAccessibleImage;
  backgroundColor: string;
  fontColor: string;
  headlines: IText[];
  headlineFontSize: number;
  leftImage: IAccessibleImage;
  rightImage: IAccessibleImage;
}
export interface IHeaderForm {
  id: FormControl<string | null>;
  client: FormControl<string>;
  toggle: FormControl<boolean>;
  backgroundColor: FormControl<string>;
  fontColor: FormControl<string>;
  logo: FormGroup<IAccessibleImageForm>;
  headlines: FormControl<IText[]>;
  headlineFontSize: FormControl<number>;
  leftImage: FormGroup<IAccessibleImageForm>;
  rightImage: FormGroup<IAccessibleImageForm>;
}

export const defaultHeader: IHeader = {
  client: '',
  toggle: false,
  logo: defaultAccessibleImage,
  backgroundColor: '#FFFFFF',
  fontColor: '#444444',
  headlines: [{ value: 'Software, die bewegt', isoCode: 'deu' }],
  headlineFontSize: 32,
  leftImage: defaultAccessibleImage,
  rightImage: defaultAccessibleImage,
};

import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlContainer,
  FormGroupDirective,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { ErrorMessageComponent } from '../../error-message/error-message.component';
import { FormInputComponent } from '../form-input.component';
import { ToFormControlPipe } from '../../../pipes/to-form-control/to-form-control.pipe';
import { AppendTestIdPipe } from '../../../pipes/append-test-id/append-test-id.pipe';

@Component({
  selector: 'app-date-input',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    ToFormControlPipe,
    AppendTestIdPipe,
  ],
  styleUrl: '../form-inputs.css',
  templateUrl: './date-input.component.html',
})
export class DateInputComponent extends FormInputComponent {
  constructor(public override ngControl: NgControl) {
    super(ngControl);
  }
}

<div class="-p-1.5 flex flex-col">
  @for (item of items(); track $index) {
    <div class="flex flex-row py-1.5">
      <div
        class="relative h-2.5 w-2.5 rounded-3xl bg-blue-800 p-2.5"
        [attr.data-testid]="id() | appendTestId: 'icon'">
        <div
          class="absolute left-0 top-0 flex h-full w-full flex-row items-center justify-center">
          <div class="flex flex-row items-center justify-center text-slate-50">
            <span class="material-symbols-rounded text-sm">
              {{ item.icon || 'check' }}
            </span>
          </div>
        </div>
      </div>
      <div
        class="pl-3 text-base"
        [attr.data-testid]="id() | appendTestId: 'text'"
        [attr.aria-label]="item.text">
        {{ item.text | language }}
      </div>
    </div>
  }
</div>

import {
  Component,
  computed,
  HostBinding,
  input,
  InputSignal,
  Signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AppendTestIdPipe } from '../../pipes/append-test-id/append-test-id.pipe';

export type ButtonStyle =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'error'
  | 'custom';

const BUTTON_PRIMARY =
  'focus:outline-none bg-primary-main border-primary-main text-white hover:bg-standard-hover hover:border-standard-hover disabled:cursor-not-allowed disabled:bg-gray-300 disabled:border-gray-300 disabled:text-gray-500  w-52';
const BUTTON_SECONDARY =
  'focus:outline-none bg-transparent text-primary-main border-primary-main hover:border-standard-hover hover:bg-standard-hover disabled:border-gray-300 disabled:text-gray-300 disabled:cursor-not-allowed disabled:bg-gray-200/50  w-52';
const BUTTON_TERTIARY =
  'focus:outline-none text-primary-main border-none bg-transparent disabled:border-gray-300 disabled:text-gray-300 disabled:cursor-not-allowed disabled:bg-gray-200/50 w-max';
const BUTTON_ERROR =
  'border-error-main hover:border-error-hover bg-error-main hover:bg-error-hover text-white focus:outline-none disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-200/50 disabled:text-gray-300';
const BUTTON_CUSTOM =
  'disabled:border-gray-300 disabled:text-gray-300 disabled:cursor-not-allowed disabled:bg-gray-200/50';

@Component({
  selector: 'app-button',
  imports: [CommonModule, FormsModule, AppendTestIdPipe],
  templateUrl: './button.component.html',
})
export class ButtonComponent {
  public text: InputSignal<string | undefined> = input<string>();
  public type: InputSignal<'submit' | 'button' | 'reset'> = input.required();
  public disabled: InputSignal<boolean | undefined> = input<boolean>();
  public fullWidth: InputSignal<boolean> = input<boolean>(true);
  public style: InputSignal<ButtonStyle> = input<ButtonStyle>('primary');
  public buttonColour: Signal<string> = computed((): string => {
    return this.determineButtonColour(this.style());
  });

  /*
   * @HostBinding applies styles to the root element of the component,
   * in this case the surrounding app-button tag
   * */
  @HostBinding('class') hostClass = '';

  private determineButtonColour(style: ButtonStyle): string {
    switch (style) {
      case 'secondary':
        return BUTTON_SECONDARY;
      case 'tertiary':
        return BUTTON_TERTIARY;
      case 'error':
        return BUTTON_ERROR;
      case 'custom':
        return BUTTON_CUSTOM;
      default:
        return BUTTON_PRIMARY;
    }
  }
}

import {
  ChangeDetectorRef,
  Component,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { ClientService } from '../../../../services/client/client.service';
import { SectionComponent } from '../../../../components/section/section.component';
import { LanguageSwitcherComponent } from '../../../../components/form-inputs/language-switcher/language-switcher.component';
import { SectionHeadlineComponent } from '../../../../components/section/section-header/section-headline/section-headline.component';
import { SectionHeaderComponent } from '../../../../components/section/section-header/section-header.component';
import { SectionHeaderActionsComponent } from '../../../../components/section/section-header/section-header-actions/section-header-actions.component';
import { ButtonComponent } from '../../../../components/button/button.component';
import { SubNavigationWrapperComponent } from '../../../../components/sub-navigation/sub-navigation-wrapper.component';
import { SubNavigationComponent } from '../../../../components/sub-navigation/sub-navigation/sub-navigation.component';

@Component({
  selector: 'app-private-area-page',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    SectionComponent,
    LanguageSwitcherComponent,
    SectionHeadlineComponent,
    SectionHeaderComponent,
    SectionHeaderActionsComponent,
    ButtonComponent,
    SubNavigationWrapperComponent,
    SubNavigationComponent,
  ],
  templateUrl: './private-area-page.component.html',
  styles: `
    a.active {
      color: blue;
      font-weight: bold;
    }
  `,
})
export class PrivateAreaPageComponent implements OnDestroy, OnInit {
  clientService = inject(ClientService);

  public languageForm: FormGroup = new FormGroup({
    language: new FormControl('deu', [Validators.required]),
  });

  private readonly sub = new Subscription();
  activeSection = '';

  constructor(private cdr: ChangeDetectorRef) {}

  @HostListener('window:scroll', [])
  onScroll() {
    console.log('scroll detected');
    this.updateActiveSection();
    this.cdr.detectChanges(); // Ensures Angular detects changes
  }

  ngOnInit() {
    this.updateActiveSection(); // Initialize the active section
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  save() {}

  updateActiveSection() {
    console.log('scroll');
    const sections = document.querySelectorAll('.section');
    let currentSection = '';

    sections.forEach((section: Element) => {
      const rect = section.getBoundingClientRect();
      if (
        rect.top <= window.innerHeight / 2 &&
        rect.bottom >= window.innerHeight / 2
      ) {
        currentSection = section.id;
      }
    });

    this.activeSection = currentSection;
  }

  showPopUpChanges() {}
}

import {
  Component,
  inject,
  OnDestroy,
  signal,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlContainer,
  FormGroupDirective,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';

import { ErrorMessageComponent } from '../../error-message/error-message.component';
import { FormInputComponent } from '../form-input.component';
import { ToFormControlPipe } from '../../../pipes/to-form-control/to-form-control.pipe';
import { AppendTestIdPipe } from '../../../pipes/append-test-id/append-test-id.pipe';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-password-input',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    ToFormControlPipe,
    AppendTestIdPipe,
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  styleUrl: '../form-inputs.css',
  templateUrl: './password-input.component.html',
})
export class PasswordInputComponent
  extends FormInputComponent
  implements OnDestroy
{
  public readonly isPasswordVisible: WritableSignal<boolean> =
    signal<boolean>(false);
  private readonly testIdPipe = new AppendTestIdPipe();
  private readonly $handleKeyUpEvent: Subscription;

  constructor(public override ngControl: NgControl) {
    super(ngControl);
    this.$handleKeyUpEvent = fromEvent<
      KeyboardEvent & { target?: { id: string } }
    >(document, 'keyup').subscribe(event => {
      const isCorrectTarget =
        event.target?.id ===
        this.testIdPipe.transform(this.id(), 'visibility-switch');
      if (event.key === 'Enter' && isCorrectTarget) {
        event.preventDefault();
        this.isPasswordVisible.set(!this.isPasswordVisible());
      }
    });
  }

  ngOnDestroy() {
    this.$handleKeyUpEvent.unsubscribe();
  }
}

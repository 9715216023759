<div class="form-input__background flex-col">
  @let hasError = ngControl.errors && ngControl.touched;

  <div
    class="form-input__container"
    [ngClass]="{
      'form-input__disabled': ngControl.control!.disabled,
    }">
    @for (option of options(); track $index; let index = $index) {
      <label
        [attr.data-testid]="id() + index | appendTestId: 'label'"
        class="flex cursor-pointer items-center bg-inherit px-3 py-2"
        [ngClass]="{
          'pointer-events-none !cursor-not-allowed':
            ngControl.control!.disabled,
        }">
        <input
          (click)="selectOption(option)"
          [attr.data-testid]="id() + index | appendTestId: 'input'"
          [id]="id() | appendTestId: index + ''"
          [checked]="
            ngControl.control!.value === option
              | toSelectOptionAttribute: value()
          "
          [value]="option | toSelectOptionAttribute: value()"
          type="radio"
          class="form-input__focus-text pointer-events-none h-5 w-5 border-2"
          [ngClass]="{
            'form-input__disabled-border form-input__disabled-text form-input__disabled-background-color':
              ngControl.control!.disabled,
            'form-input__error-border form-input__error-text': hasError,
            'cursor-not-allowed': ngControl.control!.disabled,
          }"
          [name]="id() + index" />
        <span
          class="pl-2"
          [attr.data-testid]="id() + index | appendTestId: 'option'"
          >{{ option | toSelectOptionAttribute: name() }}</span
        >
      </label>
    }
  </div>
  @if (hasError && !errorTemplate()) {
    <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
      <ng-content></ng-content>
    </app-error-message>
  }
  @if (hasError && errorTemplate()) {
    <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
      <ng-container *ngTemplateOutlet="errorTemplate()!"></ng-container>
    </app-error-message>
  }
</div>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-accessibility-banner',
  imports: [CommonModule],
  templateUrl: './accessibility-banner-component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessibilityBannerComponent {
  @Input() mainColor = '#1e3a8a';
  @Input() textColorTop = 'white';
  @Input() textColorBottom = 'black';

  public showText = false;

  public toggleText(): void {
    this.showText = !this.showText;
  }
}

import { Component, inject, OnDestroy, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CorporateDesignService } from '../../../services/api/corporateDesign/corporate-design.service';
import { ICorporateDesign } from '../../../entities/coporate-design-entity';
import { SortGroupsService } from '../../../services/api/sortGroups/sort-groups.service';
import { ISortGroups, IWrapper } from '../../../entities/sort-groups-entity';
import { SubscriptionsService } from '../../../services/api/subscriptions/subscriptions.service';
import { Subscription, throwError } from 'rxjs';
import { ISubscription } from '../../../entities/subscription-entity';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  ITicketTile,
  ITicketTileStyling,
  TicketTileComponent,
} from '../../../components/ticket-tile/ticket-tile.component';
import { ColorPickerInputComponent } from '../../../components/form-inputs/color-picker-input/color-picker-input.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NumberInputComponent } from '../../../components/form-inputs/number-input/number-input.component';
import { BannerComponent } from '../../../components/banner/banner.component';

const benefits = [
  {
    icon: 'matCheck',
    text: [
      {
        isoCode: 'deu',
        value: 'Bundesweit in allen Verkehrsmitteln des ÖPNV reisen',
      },
    ],
  },
  {
    icon: 'matCheck',
    text: [
      {
        isoCode: 'deu',
        value: 'Abonnement mit monatlicher Verlängerung und monatlich kündbar',
      },
    ],
  },
  {
    icon: 'matCheck',
    text: [
      {
        isoCode: 'deu',
        value: 'Ganzen Monat bundesweit im Nahverkehr fahren',
      },
    ],
  },
];

@Component({
  selector: 'app-landing-page',
  imports: [
    CommonModule,
    BannerComponent,
    TicketTileComponent,
    ColorPickerInputComponent,
    ReactiveFormsModule,
    NumberInputComponent,
  ],
  templateUrl: './landing-page.component.html',
  styles: ``,
})
export class LandingPageComponent implements OnDestroy {
  public backgroundColor: FormControl = new FormControl<string>('#ffffff');
  public borderWidth: FormControl = new FormControl<number>(1);
  public fakeTickets: ITicketTile[] = [
    {
      guid: '1',
      detailsTitle: [],
      details: benefits,
      buttonText: [
        {
          isoCode: 'deu',
          value: 'Bestellen',
        },
      ],
      price: 1200,
      afterPriceText: ' / Monatlich',
      via: ['OL', 'Bremen', 'Hannover'],
      description: [
        {
          isoCode: 'deu',
          value:
            'Das ist ein Beispieltext. Hier steht ein Descr.-Text mit max. 80 Zeichen.',
        },
      ],
      viaBanner: 'banner',
      title: [
        {
          isoCode: 'deu',
          value: 'Titel',
        },
      ],
    },
    {
      guid: '2',
      detailsTitle: [],
      details: benefits,
      buttonText: [
        {
          isoCode: 'deu',
          value: 'Bestellen',
        },
      ],
      price: 1200,
      afterPriceText: ' / Monatlich',
      via: ['OL', 'Bremen', 'Hannover'],
      description: [
        {
          isoCode: 'deu',
          value:
            'Das ist ein Beispieltext. Hier steht ein Descr.-Text mit max. 80 Zeichen.',
        },
      ],
      viaBanner: 'banner',
      title: [
        {
          isoCode: 'deu',
          value: 'Titel',
        },
      ],
    },
    {
      guid: '3',
      detailsTitle: [],
      details: benefits,
      buttonText: [
        {
          isoCode: 'deu',
          value: 'Bestellen',
        },
      ],
      price: 1200,
      afterPriceText: ' / Monatlich',
      via: ['OL', 'Bremen', 'Hannover'],
      description: [
        {
          isoCode: 'deu',
          value:
            'Das ist ein Beispieltext. Hier steht ein Descr.-Text mit max. 80 Zeichen.',
        },
      ],
      viaBanner: 'list',
      title: [
        {
          isoCode: 'deu',
          value: 'Titel',
        },
      ],
    },
    {
      guid: '4',
      detailsTitle: [],
      details: benefits,
      buttonText: [
        {
          isoCode: 'deu',
          value: 'Bestellen',
        },
      ],
      afterPriceText: ' / Monatlich',
      via: ['OL', 'Bremen', 'Hannover'],
      description: [
        {
          isoCode: 'deu',
          value:
            'Das ist ein Beispieltext. Hier steht ein Descr.-Text mit max. 80 Zeichen.',
        },
      ],
      viaBanner: 'chip',
      title: [
        {
          isoCode: 'deu',
          value: 'Titel',
        },
      ],
    },
  ];
  public ticketStyle: ITicketTileStyling = {
    frame: {
      borderWidth: '1px',
      borderColor: '#C4C4CF',
      backgroundColor: '#fff',
    },
    list: {
      iconColor: '',
    },
  };
  private sortGroupsService = inject(SortGroupsService);
  sortGroups: Signal<IWrapper<ISortGroups> | undefined> = toSignal(
    this.sortGroupsService.$entities
  );
  private subscriptionService = inject(SubscriptionsService);
  subscriptions: Signal<IWrapper<ISubscription> | undefined> = toSignal(
    this.subscriptionService.$entities
  );
  private corporateDesignService: CorporateDesignService = inject(
    CorporateDesignService
  );
  corporateDesign: Signal<ICorporateDesign> = toSignal(
    this.corporateDesignService.$entity
  ) as Signal<ICorporateDesign>;
  private componentSubscriptions: Subscription = new Subscription();

  constructor() {
    // collect all active subscriptions
    this.corporateDesignService.get$.next(null);
    this.componentSubscriptions.add(
      // subscribe where the value is being requested
      this.subscriptionService.$entities.subscribe()
    );
    this.componentSubscriptions.add(
      this.backgroundColor.valueChanges.subscribe(value => {
        this.ticketStyle = {
          ...this.ticketStyle,
          frame: {
            ...this.ticketStyle.frame,
            backgroundColor: value,
          },
        };
      })
    );

    this.componentSubscriptions.add(
      this.borderWidth.valueChanges.subscribe(value => {
        this.ticketStyle = {
          ...this.ticketStyle,
          frame: {
            ...this.ticketStyle.frame,
            borderWidth: value + 'px',
          },
        };
      })
    );
  }

  testSortGroups() {
    console.log('Found sortgroups', this.sortGroups());
  }

  testSubscriptions() {
    console.log('Found subscriptions', this.subscriptions());
  }

  triggerError() {
    console.log('Error');
    throwError(() => new Error('test'));
    throw new Error('test');
  }

  ngOnDestroy() {
    /* destroy subscriptions at the end of component lifecycle
     * to prevent memory leaks
     */
    this.componentSubscriptions.unsubscribe();
  }
}

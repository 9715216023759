import {
  Component,
  inject,
  OnDestroy,
  OnInit,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlContainer,
  FormGroupDirective,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { IImage } from '../../../entities/image-entity';
import { ImageService } from '../../../services/api/image/image.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormInputComponent } from '../form-input.component';
import { ToFormControlPipe } from '../../../pipes/to-form-control/to-form-control.pipe';
import { ConfigService } from '../../../services/config/config.service';
import { AppendTestIdPipe } from '../../../pipes/append-test-id/append-test-id.pipe';
import { ErrorMessageComponent } from '../../error-message/error-message.component';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-image-select',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ToFormControlPipe,
    AppendTestIdPipe,
    ErrorMessageComponent,
  ],
  styleUrl: '../form-inputs.css',
  templateUrl: './image-select.component.html',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  styles: ``,
})
export class ImageSelectComponent
  extends FormInputComponent
  implements OnInit, OnDestroy
{
  imagesService = inject(ImageService);
  images = toSignal(this.imagesService.$entity) as Signal<IImage[]>;
  showList: WritableSignal<boolean> = signal(false);
  public $handleClickEvent!: Subscription;
  private configService: ConfigService = inject(ConfigService);
  private apiUrl = this.configService.getCmsUrl;

  constructor(public override ngControl: NgControl) {
    super(ngControl);
    this.imagesService.get$.next(null);
  }

  ngOnInit() {
    this.$handleClickEvent = fromEvent<
      MouseEvent & { target?: { id: string } }
    >(document, 'click').subscribe(event => {
      const isCorrectTarget = event?.target?.id === this.id();
      if (!isCorrectTarget) {
        this.showList.set(false);
      } else if (isCorrectTarget && !this.showList()) {
        this.showList.set(true);
      }
    });
  }

  ngOnDestroy() {
    this.$handleClickEvent.unsubscribe();
  }

  public selectImage(option: IImage): void {
    this.ngControl.control?.setValue(option);
    this.ngControl.control?.updateValueAndValidity();
    this.showList.set(false);
  }

  getThumbnailPath(id: string | undefined) {
    return this.apiUrl + '/images/' + id + '/thumbnail';
  }
}

@if (fonts()) {
  <div class="flex flex-col items-start gap-2">
    @for (font of fonts(); track font.name) {
      <div
        class="text-gray-600 outline-none focus:outline-none dark:text-gray-300">
        <div class="flex rounded-lg bg-white p-4">
          <div
            class="p- peer h-10 min-w-72 content-center rounded-l-lg bg-transparent px-2 placeholder-transparent ring-2 ring-gray-500 focus:border-rose-600 focus:outline-none focus:ring-sky-600">
            {{ font.name }}
          </div>
          <div class="relative bg-inherit">
            <div
              class="peer h-10 content-center rounded-r-lg bg-transparent px-2 placeholder-transparent ring-2 ring-gray-500 focus:border-rose-600 focus:outline-none focus:ring-sky-600">
              <button type="submit" (click)="deleteFont(font)">Delete</button>
            </div>
          </div>
        </div>
      </div>
    } @empty {
      <li>There are no items.</li>
    }
    <div class="w-full border-b-2"></div>
    <label
      class="flex cursor-pointer appearance-none justify-center rounded-md border border-dashed border-gray-300 bg-white px-3 py-6 text-sm transition hover:border-gray-400 focus:border-solid focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
      tabindex="0">
      <span class="flex items-center space-x-2">
        <span class="material-symbols-rounded"> cloud_upload </span>

        <span class="text-gray-600">
          @if (selectedFile()) {
            <span class="text-blue-600 underline">{{
              selectedFile()?.name
            }}</span>
          } @else {
            <span class="text-blue-600 underline">Select</span>
          }
        </span>
      </span>
      <input
        id="font-upload"
        type="file"
        class="sr-only"
        [formControl]="filename"
        (change)="selectFile($event)" />
    </label>

    <app-button (click)="save()" [type]="'submit'" [text]="'Upload'" />
  </div>
}

<div>
  <div
    class="flex cursor-pointer flex-row items-center"
    (click)="extended = !extended">
    <div
      tabindex="0"
      class="pr-2 text-base font-medium"
      [id]="id()"
      [attr.data-testid]="id() | appendTestId: 'title'"
      [ngClass]="{ underline: !extended }">
      {{ title() }}
    </div>
    @if (!extended) {
      <span
        class="material-symbols-rounded -mt-0.5 cursor-pointer"
        [attr.data-testid]="id() | appendTestId: 'icon-down'">
        keyboard_arrow_down
      </span>
    } @else if (extended) {
      <span
        class="material-symbols-rounded -mb-0.5 cursor-pointer"
        [attr.data-testid]="id() | appendTestId: 'icon-up'">
        keyboard_arrow_up
      </span>
    }
  </div>
  @if (extended) {
    <div class="py-3" [attr.data-testid]="id() | appendTestId: 'content'">
      <ng-content></ng-content>
    </div>
  }
</div>

import { Observable } from 'rxjs';
import { IGetGateway } from '../../gateways';
import { RestCallBuilder } from '../../../builder/rest/rest.builder';
import {
  IPaymentProvider,
  PaymentProviderSchema,
} from '../../../entities/payment-providers-entity';

export class PaymentProviderGateway
  implements IGetGateway<null, IPaymentProvider[]>
{
  private restCallBuilder: RestCallBuilder<IPaymentProvider[]> =
    new RestCallBuilder<IPaymentProvider[]>([]);

  get(): Observable<IPaymentProvider[]> {
    return this.restCallBuilder
      .get('/paymentProviders/')
      .validate(PaymentProviderSchema)
      .handleErrors()
      .build();
  }
}

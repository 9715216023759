import {
  ChangeDetectionStrategy,
  Component,
  input,
  InputSignal,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormInputComponent } from '../form-input.component';
import {
  ControlContainer,
  FormGroupDirective,
  NgControl,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { ErrorMessageComponent } from '../../error-message/error-message.component';
import { ToFormControlPipe } from '../../../pipes/to-form-control/to-form-control.pipe';
import { AppendTestIdPipe } from '../../../pipes/append-test-id/append-test-id.pipe';

@Component({
  selector: 'app-file-upload',
  imports: [
    CommonModule,
    ErrorMessageComponent,
    ReactiveFormsModule,
    ToFormControlPipe,
    AppendTestIdPipe,
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  styleUrl: '../form-inputs.css',
  templateUrl: './file-upload.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent
  extends FormInputComponent
  implements OnInit, OnDestroy
{
  public multiple: InputSignal<boolean> = input.required();
  public allowedFileTypes: InputSignal<string[]> = input.required();
  public fileType: InputSignal<string> = input.required();

  constructor(public override ngControl: NgControl) {
    super(ngControl);
  }

  ngOnInit() {
    this.ngControl.control?.addValidators(
      this.conformsToFileType(this.ngControl.control?.value)
    );
  }

  ngOnDestroy() {
    this.ngControl.control?.removeValidators(
      this.conformsToFileType(this.ngControl.control?.value)
    );
  }

  public selectFilesEvent($event: Event) {
    const target = $event.target as HTMLInputElement;
    const files = target.files as FileList;
    if (this.multiple()) {
      this.selectFiles(Array.from(files));
    } else {
      this.selectFiles(files.length > 0 ? [files.item(0) as File] : []);
    }
  }

  public selectFiles(files: File[]) {
    if (files && files.length > 0) {
      if (this.multiple()) {
        this.ngControl.control?.setValue(files);
      } else {
        this.ngControl.control?.setValue([files[0]]);
      }
      this.ngControl.control?.updateValueAndValidity();
    }
  }

  private conformsToFileType(files: File[]): ValidatorFn {
    return (): ValidationErrors | null => {
      let checkFileType = false;

      files?.forEach(file => {
        if (file) {
          const nameExtension = file.name.split('.').pop();

          const allowedTypes = this.allowedFileTypes();
          const allowedTypesLong = allowedTypes.map(
            allowedType => `${this.fileType()}/${allowedType}`
          );

          if (nameExtension && !allowedTypes.includes(nameExtension)) {
            checkFileType = true;
          }
          if (file.type !== '' && !allowedTypesLong.includes(file.type)) {
            checkFileType = true;
          }
        }
      });

      return checkFileType ? { wrongFileType: true } : null;
    };
  }
}

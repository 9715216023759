<div class="p-4">
  <div class="cursor-pointer bg-gray-50 p-4" (click)="toggle()">
    @if (form.disabled) {
      ENABLE
    } @else {
      DISABLE
    }
  </div>
  <div class="flex flex-col gap-5">
    <div class="py-2">Pflichtfeld</div>
    <app-text-input [formControl]="text" label="Text Input" id="textInput1">
      <div>Here be Errors for Text Input</div>
    </app-text-input>

    <div class="flex w-full flex-col gap-5" [formGroup]="form">
      <app-toggle formControlName="toggle" label="Toggle" id="toggle"
        ><div>Here be Errors for Toggle</div></app-toggle
      >
      <app-icon-picker
        label="Icon Picker Simple"
        id="icon-picker"
        formControlName="iconPicker"
        ><div>Here be Errors for the Icon Picker</div></app-icon-picker
      >
      <app-color-picker-input
        formControlName="color"
        label="Color Input"
        id="color"></app-color-picker-input>
      <app-button-group
        class="flex"
        formControlName="primaryFontColor"
        [id]="'primaryFontColor'"
        [options]="[custom, white, black] | enumToButtonOptionArray" />
      <app-text-input formControlName="text" label="Text Input" id="textInput">
        <div>Here be Errors for Text Input</div>
      </app-text-input>
      <app-accessibility-text-input
        formControlName="accessibilityText"
        label="Text Input"
        id="accessibilityTextInput">
        <div>Here be Errors for Text Input</div>
      </app-accessibility-text-input>
      <div class="py-2">Pflichtfeld</div>
      <app-password-input
        formControlName="password"
        label="Password Input"
        id="passwordInput">
        <div>Here be Errors for Password</div>
      </app-password-input>
      <div class="py-2">Pflichtfeld, Mindestlänge 10 Zeichen</div>
      <app-text-area
        formControlName="textArea"
        label="Text Area"
        id="textAreaInput">
        <div>Here be Errors for TextArea</div>
      </app-text-area>
      <div class="py-2">Pflichtfeld</div>

      <div class="py-2">Pflichtfeld, mindestens 1, höchstens 10</div>
      <app-number-input
        formControlName="number"
        label="Number input"
        id="numberInput"
        ><div>Here be Errors for Number</div></app-number-input
      >
      <div class="py-2">Pflichtfeld</div>
      <app-checkbox formControlName="checkBox" label="Checkbox" id="checkBox">
        <div>Here be Errors for Checkbox</div>
      </app-checkbox>
      <div class="py-2">Pflichtfeld</div>
      <app-date-input formControlName="date" label="DateInput" id="dateInput">
        <div>Here be Errors for Date Input</div>
      </app-date-input>
      <app-date-time-input
        formControlName="dateTime"
        label="DateTimeInput"
        id="dateTimeInput">
        <div>Here be Errors for Date Time Input</div>
      </app-date-time-input>
      <div class="py-2">Pflichtfeld, mindestens 5</div>
      <app-drop-down
        formControlName="dropdown"
        label="Drop Down"
        id="dropdown"
        [options]="[1, 2, 3, 4, 5, 6, 7]">
        <div>Here be Errors for Drop Down</div>
      </app-drop-down>
      <app-drop-down
        formControlName="dropdownName"
        label="Drop Down Name"
        name="name"
        id="dropdown2"
        [options]="[
          { value: 1, name: 'option 1' },
          { value: 2, name: 'option 2' },
          { value: 3, name: 'option 3' },
          { value: 4, name: 'special option 4' },
          { value: 5, name: 'option 5' },
          { value: 6, name: 'special option 6' },
          { value: 7, name: 'option 7' },
          { value: 8, name: 'The last one' },
        ]">
        <div>Here be Errors for Drop Down</div>
      </app-drop-down>
      <app-drop-down
        formControlName="dropdownNameAndValue"
        label="Drop Down Name and Value"
        name="name"
        value="value"
        id="dropdown3"
        [options]="[
          { value: 1, name: 'option 1' },
          { value: 2, name: 'option 2' },
          { value: 3, name: 'option 3' },
          { value: 4, name: 'special option 4' },
          { value: 5, name: 'option 5' },
          { value: 6, name: 'special option 6' },
          { value: 7, name: 'option 7' },
          { value: 8, name: 'The last one' },
        ]">
        <div>Here be Errors for Drop Down</div>
      </app-drop-down>
      <app-image-select
        id="imageSelect"
        formControlName="imageSelect"
        label="Banner" />
      <app-payment-select
        id="paymentSelect"
        formControlName="paymentSelect"
        label="Payment" />
      <div class="py-2">Pflichtfeld, mindestens 5</div>
      <app-single-select
        formControlName="singleSelect"
        label="Single Select"
        id="single-select"
        [options]="[1, 2, 3, 4, 5, 6, 7]">
        <div>Here be Errors for the Single Select</div>
      </app-single-select>
      <div class="py-2">Pflichtfeld, wähle mindestens zwei Optionen</div>
      <app-multi-select
        formControlName="multiSelect"
        label="Multi Select"
        id="multi-select"
        [options]="[1, 2, 3, 4, 5, 6, 7]">
        <div>Here be Errors for the Multi Select</div></app-multi-select
      >
      <div class="py-2">Pflichtfeld, mindestens 5</div>
      <app-auto-complete
        label="Auto Complete Simple"
        id="auto-complete-1"
        formControlName="autoComplete"
        [options]="[1, 2, 3, 4, 5, 6, 7, 8]"
        ><div>Here be Errors for the Auto Complete</div></app-auto-complete
      >
      <div class="py-2">Pflichtfeld, mindestens 5</div>
      <app-auto-complete
        label="Auto Complete Name & Value"
        id="auto-complete-2"
        formControlName="autoComplete2"
        name="name"
        value="value"
        [options]="[
          { value: 1, name: 'option 1' },
          { value: 2, name: 'option 2' },
          { value: 3, name: 'option 3' },
          { value: 4, name: 'special option 4' },
          { value: 5, name: 'option 5' },
          { value: 6, name: 'special option 6' },
          { value: 7, name: 'option 7' },
          { value: 8, name: 'The last one' },
        ]"
        ><div>Here be Errors for the Auto Complete</div></app-auto-complete
      >
      <div class="py-2">Pflichtfeld</div>
      <app-auto-complete
        label="Auto Complete Name only"
        id="auto-complete-3"
        formControlName="autoComplete3"
        name="name"
        [options]="[
          { value: 1, name: 'option 1' },
          { value: 2, name: 'option 2' },
          { value: 3, name: 'option 3' },
          { value: 4, name: 'special option 4' },
          { value: 5, name: 'option 5' },
          { value: 6, name: 'special option 6' },
          { value: 7, name: 'option 7' },
          { value: 8, name: 'The last one' },
        ]"
        ><div>Here be Errors for the Auto Complete</div></app-auto-complete
      >
      <app-file-upload
        [allowedFileTypes]="['jpg', 'jpeg', 'png', 'tiff']"
        [fileType]="'image'"
        [multiple]="true"
        formControlName="fileUpload"
        label="File Upload"
        id="fileUpload">
        <div>Here be Errors for the File Upload</div>
      </app-file-upload>
      <quill-view
        [content]="form.controls['quill'].value"
        format="json"
        theme="snow"></quill-view>
      <app-text-editor
        formControlName="quill"
        label="Text Editor"
        id="textEditorInput">
        <div>Here be Errors for TextEditor</div>
      </app-text-editor>
    </div>
    {{ form.value | json }}
  </div>
</div>

import { Component, inject } from '@angular/core';
import { NgClass, NgForOf } from '@angular/common';
import { ISidebar } from '../../../entities/sidebar/sidebar.entity';
import { MenuTabComponent } from './menutab/menutab.component';
import { ConfigService } from '../../../services/config/config.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  imports: [NgForOf, NgClass, MenuTabComponent],
  styles: ``,
})
export class SidebarComponent {
  private configService: ConfigService = inject(ConfigService);
  protected stage = this.configService.getStage;
  protected isExpanded = true;

  sidebarItems: ISidebar = {
    tabs: [
      {
        heading: 'Medien',
        menus: [],
        items: [
          {
            icon: 'text_fields',
            label: 'Alle Texte',
            routerLink: '',
          },
          {
            icon: 'image',
            label: 'Alle Bilder',
            routerLink: '/cms/images',
          },
          {
            icon: 'font_download',
            label: 'Alle Schriftarten',
            routerLink: '/cms/fonts',
          },
          {
            icon: 'palette',
            label: 'Corporate Design',
            routerLink: '/cms/corporate',
          },
        ],
      },
      {
        heading: 'Globale Komponenten',
        menus: [],
        items: [
          {
            icon: 'web_asset',
            label: 'Header',
            routerLink: '/cms/header',
          },
          {
            icon: 'image',
            label: 'Banner',
            routerLink: '/cms/banner',
          },
          {
            icon: 'video_label',
            label: 'Footer',
            routerLink: '/cms/footer',
          },
        ],
      },
      {
        heading: 'Inhalte',
        items: [
          {
            icon: 'confirmation_number',
            label: 'Tickets',
            routerLink: '/cms/tickets',
          },
          {
            icon: 'login',
            label: 'Login/Logout',
            routerLink: '/cms/login',
          },
        ],
        menus: [
          {
            heading: 'Allgemein',
            headingIcon: 'web',
            expanded: true,
            items: [
              {
                icon: '',
                label: 'Startseite',
                routerLink: '',
              },
              {
                icon: '',
                label: 'Warenkorb',
                routerLink: '',
              },
              {
                icon: '',
                label: 'Kaufbestätigung',
                routerLink: '',
              },
            ],
          },
          {
            heading: 'Kundenkonto',
            headingIcon: 'manage_accounts',
            expanded: true,
            items: [
              {
                icon: '',
                label: 'Persönlicher Bereich',
                routerLink: '/cms/account/private-area',
              },
            ],
          },
        ],
      },
      {
        heading: 'Sonstige',
        menus: [],
        items: [
          {
            icon: 'swap_vert',
            label: 'Relationsauswahl',
            routerLink: '',
          },
          {
            icon: 'accessibility_new',
            label: 'Barrierefreiheit',
            routerLink: '',
          },
          {
            icon: 'text_fields',
            label: 'Sprachen',
            routerLink: '',
          },
        ],
      },
      {
        heading: 'Administration',
        menus: [],
        items: [
          {
            icon: 'shopping_cart',
            label: 'Shop',
            routerLink: '',
          },
          {
            icon: 'settings',
            label: 'Einstellungen',
            routerLink: '',
          },
        ],
      },
    ],
  };

  toggleSidebar() {
    this.isExpanded = !this.isExpanded;
  }

  isLastItem(index: number): boolean {
    return this.sidebarItems.tabs.length === index + 1;
  }

  showCollapseIcon(): string {
    return !this.isExpanded
      ? 'keyboard_double_arrow_right'
      : 'keyboard_double_arrow_left';
  }
}

import { Component, OnDestroy } from '@angular/core';
import { CommonModule, JsonPipe } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TextInputComponent } from '../form-inputs/text-input/text-input.component';
import { TextAreaComponent } from '../form-inputs/text-area/text-area.component';
import { PasswordInputComponent } from '../form-inputs/password-input/password-input.component';
import { ToggleComponent } from '../form-inputs/toggle/toggle.component';
import { CheckboxComponent } from '../form-inputs/checkbox/checkbox.component';
import { DateInputComponent } from '../form-inputs/date-input/date-input.component';
import { SingleSelectComponent } from '../form-inputs/single-select/single-select.component';
import { DropDownComponent } from '../form-inputs/drop-down/drop-down.component';
import { MultiSelectComponent } from '../form-inputs/multi-select/multi-select.component';
import { AutoCompleteComponent } from '../form-inputs/auto-complete/auto-complete.component';
import { NumberInputComponent } from '../form-inputs/number-input/number-input.component';
import { FileUploadComponent } from '../form-inputs/file-upload/file-upload.component';
import { ColorPickerInputComponent } from '../form-inputs/color-picker-input/color-picker-input.component';
import { IconPickerComponent } from '../form-inputs/icon-picker/icon-picker.component';
import { QuillViewComponent } from 'ngx-quill';
import { TextEditorComponent } from '../form-inputs/text-editor/text-editor.component';
import { ImageSelectComponent } from '../form-inputs/image-select/image-select.component';
import { PaymentSelectComponent } from '../form-inputs/payment-select/payment-select.component';
import { AccessibilityTextInputComponent } from '../form-inputs/accessibility-text-input/accessibility-text-input.component';
import { ButtonGroupComponent } from '../form-inputs/button-group/button-group.component';
import {
  black,
  byPrimaryColor,
  custom,
  white,
} from '../../entities/coporate-design-entity';
import { Subscription } from 'rxjs';
import { EnumToButtonOptionArrayPipe } from '../../pipes/enum-to-button-option-array/enum-to-button-option-array.pipe';

@Component({
  selector: 'app-form-inputs-display',
  imports: [
    CommonModule,
    TextInputComponent,
    ReactiveFormsModule,
    TextAreaComponent,
    PasswordInputComponent,
    ToggleComponent,
    CheckboxComponent,
    DateInputComponent,
    SingleSelectComponent,
    DropDownComponent,
    MultiSelectComponent,
    AutoCompleteComponent,
    NumberInputComponent,
    FileUploadComponent,
    ColorPickerInputComponent,
    IconPickerComponent,
    FormsModule,
    JsonPipe,
    QuillViewComponent,
    TextEditorComponent,
    ButtonGroupComponent,
    ImageSelectComponent,
    PaymentSelectComponent,
    AccessibilityTextInputComponent,
    EnumToButtonOptionArrayPipe,
  ],
  templateUrl: './form-inputs-display.component.html',
})
export class FormInputsDisplayComponent implements OnDestroy {
  public text = new FormControl<string>('!!!!', [Validators.required]);
  public form: FormGroup = new FormGroup({
    iconPicker: new FormControl(undefined, [Validators.required]),
    text: new FormControl<string>('', [Validators.required]),
    accessibilityText: new FormControl<string>('', [Validators.required]),
    password: new FormControl<string>('', [Validators.required]),
    textArea: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(10),
    ]),
    number: new FormControl<number>(3, [
      Validators.required,
      Validators.min(1),
      Validators.max(10),
    ]),
    toggle: new FormControl<boolean>(false, [Validators.requiredTrue]),
    checkBox: new FormControl<boolean>(false, [Validators.requiredTrue]),
    date: new FormControl(undefined, [Validators.required]),
    dropdown: new FormControl(undefined, [
      Validators.required,
      Validators.min(5),
    ]),
    imageSelect: new FormControl(undefined, [Validators.required]),
    paymentSelect: new FormControl(undefined, [Validators.required]),
    dropdownName: new FormControl(undefined, [Validators.required]),
    dropdownNameAndValue: new FormControl(undefined, [
      Validators.required,
      Validators.min(5),
    ]),
    singleSelect: new FormControl(undefined, [
      Validators.required,
      Validators.min(5),
    ]),
    multiSelect: new FormControl(undefined, [
      Validators.required,
      Validators.minLength(2),
    ]),
    autoComplete: new FormControl(undefined, [
      Validators.required,
      Validators.min(5),
    ]),
    autoComplete2: new FormControl(undefined, [
      Validators.required,
      Validators.min(5),
    ]),
    autoComplete3: new FormControl(undefined, [Validators.required]),
    primaryFontColor: new FormControl(white, [Validators.required]),
    color: new FormControl(undefined, [Validators.required]),
    fileUpload: new FormControl(undefined, [Validators.required]),
    quill: new FormControl<string>('', [Validators.required]),
    buttonGroup: new FormControl(undefined, [Validators.required]),
  });
  private fontColor = '#FFFFFF';

  protected readonly custom = custom;
  protected readonly white = white;
  protected readonly black = black;

  public buttonState = 'custom';
  public sub = new Subscription();

  constructor() {
    this.sub.add(
      this.form.controls['primaryFontColor'].valueChanges.subscribe(value =>
        this.detectCustomOrPresetColor(value)
      )
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  detectCustomOrPresetColor(value: string): void {
    const colorMappings: { [key: string]: string } = {
      Weiß: '#FFFFFF',
      Schwarz: '#000000',
    };

    if (value === custom) {
      this.form.get('color')?.enable();
      this.buttonState = 'custom';
    } else if (colorMappings[value]) {
      this.form
        .get('color')
        ?.setValue(colorMappings[value], { emitEvent: true });
      this.form.get('color')?.disable();
      this.buttonState = colorMappings[value];
    } else if (value === byPrimaryColor) {
      this.form
        .get('color')
        ?.setValue(colorMappings[value], { emitEvent: true });
      this.form.get('color')?.disable();
      this.buttonState = colorMappings[value];
    }
  }
  toggle(): void {
    if (this.form.disabled) {
      this.form.enable();
    } else {
      this.form.disable();
    }
    this.form.updateValueAndValidity();
  }
}

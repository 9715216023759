<div
  class="text-initial box-border flex w-full rounded-lg bg-white"
  [ngClass]="{
    'form-input__disabled': ngControl.control!.disabled,
  }">
  @let hasError = ngControl.errors && ngControl.touched;
  <div
    class="peer flex h-10 items-center rounded-l-lg bg-gray-200 px-1 text-gray-600 placeholder-transparent ring-1 ring-gray-500">
    <span class="material-symbols-rounded"> translate </span>
  </div>
  <div class="relative w-full bg-inherit">
    <input
      [attr.data-testid]="id() | appendTestId: 'input'"
      [formControl]="activeTextValue"
      [id]="id()"
      [ngClass]="{
        'form-input__error-ring': hasError,
      }"
      [placeholder]="label() ?? ''"
      class="peer h-10 w-full rounded-r-lg border-0 bg-transparent px-2 placeholder-transparent ring-1 ring-gray-500 focus:outline-none focus:ring-1 focus:ring-blue-600"
      type="text" />
    <label
      [attr.data-testid]="id() | appendTestId: 'label'"
      [for]="id()"
      [ngClass]="{
        'form-input__error-text': hasError,
      }"
      class="form-input__floating-label">
      {{ label() }}
    </label>
    @if (hasError && !errorTemplate()) {
      <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
        <ng-content></ng-content>
      </app-error-message>
    }
    @if (hasError && errorTemplate()) {
      <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
        <ng-container *ngTemplateOutlet="errorTemplate()!"></ng-container>
      </app-error-message>
    }
  </div>
</div>

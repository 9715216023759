import { inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

export interface IDialogResponse<T> {
  submit: boolean;
  data: T;
}

export class Dialog<T> {
  dialogRef = inject<DialogRef<IDialogResponse<T>>>(
    DialogRef<IDialogResponse<T>>
  );
  data = inject(DIALOG_DATA);
}

@if (isPopupVisible) {
  <div
    class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div class="w-96 rounded-lg bg-white p-6 text-center shadow-xl">
      <p>Möchten Sie wirklich auf Werkseinstellungen zurücksetzen?</p>
      <div class="mt-4 flex space-x-2">
        <button
          type="button"
          class="flex-1 rounded bg-gray-500 px-4 py-2 text-white"
          (click)="cancel()">
          Abbrechen
        </button>
        <button
          type="button"
          class="flex-1 rounded bg-red-500 px-4 py-2 text-white"
          (click)="confirm()">
          Ja
        </button>
      </div>
    </div>
  </div>
}

import {
  Component,
  HostBinding,
  input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorMessageComponent } from '../../error-message/error-message.component';
import {
  AbstractControl,
  ControlContainer,
  FormGroupDirective,
  NgControl,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { FormInputComponent } from '../form-input.component';
import { ToFormControlPipe } from '../../../pipes/to-form-control/to-form-control.pipe';
import { AppendTestIdPipe } from '../../../pipes/append-test-id/append-test-id.pipe';
import { ColorPickerDirective, ColorPickerModule } from 'ngx-color-picker';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-color-picker-input',
  imports: [
    CommonModule,
    ErrorMessageComponent,
    ReactiveFormsModule,
    ToFormControlPipe,
    AppendTestIdPipe,
    ColorPickerModule,
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  styleUrl: '../form-inputs.css',
  templateUrl: './color-picker-input.component.html',
})
export class ColorPickerInputComponent
  extends FormInputComponent
  implements OnInit, OnDestroy
{
  @HostBinding('class') hostClass = '';
  public color = '#000000';
  public defaultColor = '#000000';
  darkMode = input<boolean>(false);

  @ViewChild(ColorPickerDirective)
  colorPicker!: ColorPickerDirective;

  private readonly sub: Subscription = new Subscription();

  constructor(public override ngControl: NgControl) {
    super(ngControl);
  }

  ngOnInit() {
    this.ngControl.control!.addValidators(this.isHexColor());
    this.sub.add(
      this.ngControl.control!.valueChanges.subscribe(value => {
        this.color = value;
      })
    );
    if (!this.ngControl.control!.value) {
      this.ngControl.control!.setValue(this.defaultColor, { emitEvent: false });
    } else {
      this.color = this.ngControl.control!.value;
    }
  }

  ngOnDestroy() {
    this.ngControl.control!.removeValidators(this.isHexColor());
    this.sub.unsubscribe();
  }

  openColorPicker(): void {
    this.colorPicker.openDialog();
  }

  changeColor(color: string): void {
    this.ngControl.control!.setValue(color);
    this.ngControl.control!.markAsTouched();
    this.ngControl.control!.markAsDirty();
  }

  positionPicker(isOpen: boolean): void {
    if (isOpen) {
      document.body.style.setProperty('overflow', 'hidden');
      const colorPicker: HTMLElement = document.getElementsByClassName(
        'color-picker'
      )[0] as HTMLElement;
      const arrow: Element = colorPicker.getElementsByClassName(
        'arrow arrow-bottom-left'
      )[0];
      colorPicker.style.setProperty('position', 'absolute');
      colorPicker.style.setProperty('left', '40px');
      colorPicker.style.setProperty('top', '7px');
      arrow?.setAttribute('class', 'arrow arrow-bottom-right');
    } else {
      document.body.style.setProperty('overflow', 'initial');
    }
  }

  updateColorPickerValue(): void {
    if (this.ngControl.control!.valid) {
      this.color = this.ngControl.control!.value;
    } else {
      this.color = this.defaultColor;
      this.ngControl.control!.setValue(this.defaultColor, { emitEvent: false });
    }
  }

  private isHexColor(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === undefined || control.value === null) {
        return null;
      }
      const sixCharacterCode = new RegExp('^#[0-9A-Fa-f]{6}$');
      const hexError = control.value.toString().match(sixCharacterCode);
      return !hexError ? { hexColor: true } : null;
    };
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  InputSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ITicketFrameStyling,
  TicketFrameComponent,
} from '../ticket-frame/ticket-frame.component';
import {
  ISimpleListItem,
  ISimpleListStyling,
  SimpleListComponent,
} from '../simple-list/simple-list.component';
import { ExtendableTextComponent } from '../extendable-text/extendable-text.component';
import { ViaListComponent } from '../via-list/via-list.component';
import { ViaChipComponent } from '../via-chip/via-chip.component';
import { ViaBannerComponent } from '../via-banner/via-banner.component';
import { CentsToEurosPipe } from '../../pipes/cents-to-euros/cents-to-euros.pipe';
import { AppendTestIdPipe } from '../../pipes/append-test-id/append-test-id.pipe';
import { ChipComponent } from '../chip/chip.component';
import { IText } from '../../entities/text-entity';
import { LanguagePipe } from '../../pipes/language/language.pipe';

export interface ITicketTileStyling {
  frame: ITicketFrameStyling;
  list: ISimpleListStyling;
}

export type TicketViaBannerType = 'chip' | 'banner' | 'list';

export interface ITicketTile {
  guid: string;
  detailsTitle: IText[];
  details: ISimpleListItem[];
  buttonText: IText[];
  price?: number;
  afterPriceText: string;
  via: string[];
  description: IText[];
  viaBanner: TicketViaBannerType;
  title: IText[];
}

@Component({
  selector: 'app-ticket-tile',
  imports: [
    CommonModule,
    TicketFrameComponent,
    SimpleListComponent,
    ExtendableTextComponent,
    ViaListComponent,
    ViaChipComponent,
    ViaBannerComponent,
    CentsToEurosPipe,
    AppendTestIdPipe,
    ChipComponent,
    LanguagePipe,
  ],
  templateUrl: './ticket-tile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketTileComponent {
  public id: InputSignal<string> = input.required<string>();
  public ticket: InputSignal<ITicketTile> = input.required<ITicketTile>();
  public styling: InputSignal<ITicketTileStyling> = input<ITicketTileStyling>({
    frame: {
      borderWidth: '1px',
      borderColor: '#C4C4CF',
      backgroundColor: '#fff',
    },
    list: {
      iconColor: '',
    },
  });
}

import { Component, computed, inject, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CorporateDesignService } from '../../services/api/corporateDesign/corporate-design.service';
import { HeaderService } from '../../services/api/header/header.service';
import { ConfigService } from '../../services/config/config.service';
import { ClientService } from '../../services/client/client.service';
import { LanguagePipe } from '../../pipes/language/language.pipe';
import { TemplatePipe } from '../../pipes/template/template.pipe';
import { ICorporateDesign } from '../../entities/coporate-design-entity';
import { IHeader } from '../../entities/header-entity';
import { toSignal } from '@angular/core/rxjs-interop';
import { ILoginLogout } from '../../entities/login-logout.entity';
import { LoginLogoutService } from '../../services/api/login-logout/login-logout.service';
import { ButtonComponent } from '../button/button.component';
import { RouterLink } from '@angular/router';
import { LoginService } from '../../services/api/login/login.service';

@Component({
  selector: 'app-header',
  imports: [
    CommonModule,
    LanguagePipe,
    TemplatePipe,
    ButtonComponent,
    RouterLink,
  ],
  templateUrl: './header.component.html',
  styles: ``,
})
export class HeaderComponent {
  private corporateDesignService: CorporateDesignService = inject(
    CorporateDesignService
  );
  private loginLogoutService: LoginLogoutService = inject(LoginLogoutService);
  private headerService: HeaderService = inject(HeaderService);
  private configService: ConfigService = inject(ConfigService);
  private clientService: ClientService = inject(ClientService);
  protected loginService: LoginService = inject(LoginService);

  private apiUrl = this.configService.getCmsUrl;
  private client: string = this.clientService.client;

  corporateDesign: Signal<ICorporateDesign> = toSignal(
    this.corporateDesignService.$entity
  ) as Signal<ICorporateDesign>;
  login: Signal<ILoginLogout> = toSignal(
    this.loginLogoutService.$entity
  ) as Signal<ILoginLogout>;
  header: Signal<IHeader> = toSignal(
    this.headerService.$entity
  ) as Signal<IHeader>;

  logoPath: Signal<string> = computed((): string => {
    return this.apiUrl + '/header/' + this.client + '/logo';
  });
  rightPath: Signal<string> = computed((): string => {
    return this.apiUrl + '/header/' + this.client + '/right';
  });
  leftPath: Signal<string> = computed((): string => {
    return this.apiUrl + '/header/' + this.client + '/left';
  });

  constructor() {
    this.headerService.get$.next(null);
    this.corporateDesignService.get$.next(null);
    this.loginLogoutService.get$.next(null);
  }
}

import { inject, Injectable } from '@angular/core';
import { TicketsOverviewGateway } from './ticketsOverviewGateway';
import { ITicketsOverview } from '../../../entities/tickets-overview-entity';
import { ConfigService } from '../../config/config.service';
import { CRUDService } from '../../crud/crud.service';

@Injectable({
  providedIn: 'root',
})
export class TicketsOverviewService extends CRUDService<
  ITicketsOverview,
  never,
  string,
  never,
  never,
  never
> {
  private readonly configService: ConfigService = inject(ConfigService);

  constructor() {
    super(new TicketsOverviewGateway());
  }

  public getTicketsOverview(): void {
    this.get$.next(this.configService.config()?.client?.name ?? '');
  }
}

import { inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../../services/language/language.service';
import { IText } from '../../entities/text-entity';

@Pipe({
  name: 'language',
  standalone: true,
})
@Injectable({
  providedIn: 'root',
})
export class LanguagePipe implements PipeTransform {
  languageService = inject(LanguageService);

  transform(texts: IText[]): string {
    return (
      texts.find(l => l.isoCode === this.languageService.activeLanguage().iso)
        ?.value ?? ''
    );
  }
}

import { Component, input, InputSignal } from '@angular/core';

export interface ITicketFrameStyling {
  borderWidth: string;
  borderColor: string;
  backgroundColor: string;
}

@Component({
  selector: 'app-ticket-frame',
  imports: [],
  templateUrl: './ticket-frame.component.html',
})
export class TicketFrameComponent {
  public readonly styling: InputSignal<ITicketFrameStyling> = input.required();
}

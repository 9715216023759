@if (images()) {
  <app-section>
    <app-section-header>
      <app-section-headline> Alle Bilder </app-section-headline>
      <app-section-header-actions>
        <app-button
          [type]="'submit'"
          (click)="save()"
          [text]="'Speichern'"
          [style]="'primary'"></app-button>
        <app-button
          [type]="'button'"
          (click)="reload()"
          [text]="'Abbrechen'"
          [style]="'secondary'"></app-button>
      </app-section-header-actions>
    </app-section-header>
    <div class="flex items-center">
      <div class="flex flex-col items-start gap-2">
        @for (image of images(); track image.id) {
          <div
            class="flex items-center gap-2 text-gray-600 outline-none focus:outline-none dark:text-gray-300">
            <img [src]="getThumbnailPath(image.id)" alt="Image Preview" />
            <app-text-input
              [text]="image.filename"
              label="Filename"
              (textChange)="onImageNameChange($event, image)" />
            <app-button
              [type]="'submit'"
              (click)="deleteImage(image)"
              [style]="'error'"
              ><span class="material-symbols-rounded">
                delete
              </span></app-button
            >
          </div>
        } @empty {
          <li>Noch keine Bilder hochgeladen.</li>
        }
      </div>
    </div>

    <app-section [formGroup]="imageForm">
      <app-section-header>
        <app-section-headline> Neue Bilder </app-section-headline>
      </app-section-header>
      <div class="flex items-center">
        <div class="flex flex-col items-start gap-2">
          @for (file of imageForm.controls.images.value; track file.name) {
            <div
              class="flex items-center gap-2 text-gray-600 outline-none focus:outline-none dark:text-gray-300">
              <div class="flex rounded-lg bg-white p-4">
                <div
                  class="p- peer h-10 min-w-72 content-center rounded-lg bg-transparent px-2 placeholder-transparent ring-2 ring-gray-500 focus:border-rose-600 focus:outline-none focus:ring-sky-600">
                  {{ file.name }}
                </div>
              </div>
            </div>
          } @empty {
            <div>Keine neuen Bilder hochgeladen.</div>
          }
          <app-drag-drop
            [allowedFileTypes]="['jpg', 'jpeg', 'png', 'tiff']"
            [fileType]="'image'"
            [multiple]="true"
            class="w-full"
            [id]="'faviconSelection'"
            formControlName="images" />
        </div>
      </div>
    </app-section>
  </app-section>
}

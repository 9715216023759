import { Factory } from '../factory';
import {
  defaultCorporateDesign,
  ICorporateDesign,
  ICorporateDesignForm,
} from '../../entities/coporate-design-entity';
import { inject, Injectable } from '@angular/core';
import { ClientService } from '../../services/client/client.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class CorporateDesignFormsFactory extends Factory<
  ICorporateDesignForm,
  ICorporateDesign
> {
  clientService = inject(ClientService);
  formBuilder = inject(FormBuilder);

  buildForm(
    entity: ICorporateDesign = defaultCorporateDesign
  ): FormGroup<ICorporateDesignForm> {
    return this.formBuilder.group<ICorporateDesignForm>({
      id: this.formBuilder.control(entity.id || null),
      client: this.formBuilder.nonNullable.control(this.clientService.client, [
        Validators.required,
      ]),
      primaryColor: this.formBuilder.nonNullable.control(entity.primaryColor, [
        Validators.required,
      ]),
      primaryBorderColor: this.formBuilder.nonNullable.control(
        entity.primaryBorderColor,
        [Validators.required]
      ),
      primaryFontColor: this.formBuilder.nonNullable.control(
        entity.primaryFontColor,
        [Validators.required]
      ),
      primaryAlternativeColor: this.formBuilder.nonNullable.control(
        entity.primaryAlternativeColor,
        [Validators.required]
      ),
      secondaryColor: this.formBuilder.nonNullable.control(
        entity.secondaryColor,
        [Validators.required]
      ),
      secondaryBorderColor: this.formBuilder.nonNullable.control(
        entity.secondaryBorderColor,
        [Validators.required]
      ),
      secondaryFontColor: this.formBuilder.nonNullable.control(
        entity.secondaryFontColor,
        [Validators.required]
      ),
      secondaryAlternativeColor: this.formBuilder.nonNullable.control(
        entity.secondaryAlternativeColor,
        [Validators.required]
      ),
      linkIcon: this.formBuilder.nonNullable.control(entity.linkIcon, [
        Validators.required,
      ]),
      primaryDarkColor: this.formBuilder.nonNullable.control(
        entity.primaryDarkColor,
        [Validators.required]
      ),
      primaryDarkFontColor: this.formBuilder.nonNullable.control(
        entity.primaryDarkFontColor,
        [Validators.required]
      ),
      linkIconDark: this.formBuilder.nonNullable.control(entity.linkIconDark, [
        Validators.required,
      ]),
      fontSizeTitle: this.formBuilder.nonNullable.control(
        entity.fontSizeTitle,
        [Validators.required]
      ),
      fontSizeText: this.formBuilder.nonNullable.control(entity.fontSizeText, [
        Validators.required,
      ]),
      fontSizeSmall: this.formBuilder.nonNullable.control(
        entity.fontSizeSmall,
        [Validators.required]
      ),
      font: this.formBuilder.nonNullable.control(entity.font, [
        Validators.required,
      ]),
      buttonBorderRounding: this.formBuilder.nonNullable.control(
        entity.buttonBorderRounding,
        [Validators.required]
      ),
      tabs: this.formBuilder.nonNullable.control(entity.tabs, [
        Validators.required,
      ]),
      faviconFilename: this.formBuilder.nonNullable.control(
        entity.faviconFilename,
        [Validators.required]
      ),
      faviconFileExtension: this.formBuilder.nonNullable.control(
        entity.faviconFileExtension,
        [Validators.required]
      ),
    });
  }

  buildEntity(form: FormGroup<ICorporateDesignForm>): ICorporateDesign {
    const corporateDesign = form.getRawValue() as unknown as ICorporateDesign;
    this.removeNullIds(corporateDesign);
    return corporateDesign;
  }
}

@if (paymentProviders()) {
  <div
    class="form-input__background"
    [ngClass]="{
      'form-input__disabled': ngControl.control!.disabled,
    }">
    @let hasError = ngControl.errors && ngControl.touched;
    <div class="form-input__container">
      <input
        [attr.data-testid]="id() | appendTestId: 'input'"
        autocomplete="off"
        [formControl]="ngControl.control! | toFormControl"
        class="form-input peer"
        [ngClass]="{
          'form-input__error-ring': hasError,
        }"
        [id]="id()"
        [placeholder]="label() ?? ''"
        (focus)="showList.set(true)"
        type="text" />
      <label
        [attr.data-testid]="id() | appendTestId: 'label'"
        class="form-input__floating-label"
        [ngClass]="{ 'form-input__error-text': hasError }"
        [for]="id()">
        {{ label() }}
      </label>
      @if (ngControl.value) {
        <div
          [attr.data-testid]="id() | appendTestId: 'value'"
          class="pointer-events-none absolute left-1 right-5 top-2 flex items-center gap-2 truncate px-1">
          <img
            [src]="getThumbnailPath(ngControl.value.id)"
            alt="PaymentProvider Preview"
            class="h-6" />
          {{ ngControl.value.filename }}
        </div>
      }
      <div
        [attr.data-testid]="id() | appendTestId: 'options'"
        [ngClass]="{ hidden: !showList() }"
        class="form-input__dropdown-list flex flex-col gap-2">
        @for (
          paymentProvider of paymentProviders();
          track paymentProvider.id;
          let index = $index
        ) {
          <div
            class="form-input__dropdown-list--hover flex items-center gap-2"
            (click)="selectPaymentProvider(paymentProvider)">
            <img
              [src]="getThumbnailPath(paymentProvider.id)"
              alt="PaymentProvider Preview"
              class="h-10" />
            {{ paymentProvider.filename }}
          </div>
        }
        @if (paymentProviders()!.length === 0) {
          <div
            class="px-4 py-2"
            [attr.data-testid]="id() | appendTestId: 'option'">
            Keine passenden Ergebnisse...
          </div>
        }
      </div>
      @if (hasError && !errorTemplate()) {
        <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
          <ng-content></ng-content>
        </app-error-message>
      }
      @if (hasError && errorTemplate()) {
        <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
          <ng-container *ngTemplateOutlet="errorTemplate()!"></ng-container>
        </app-error-message>
      }
    </div>
  </div>
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  ControlContainer,
  FormGroupDirective,
  NgControl,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { ErrorMessageComponent } from '../../error-message/error-message.component';
import { FormInputComponent } from '../form-input.component';
import { ToFormControlPipe } from '../../../pipes/to-form-control/to-form-control.pipe';
import { AppendTestIdPipe } from '../../../pipes/append-test-id/append-test-id.pipe';

@Component({
  selector: 'app-number-input',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    ToFormControlPipe,
    AppendTestIdPipe,
  ],
  styleUrl: '../form-inputs.css',
  templateUrl: './number-input.component.html',
})
export class NumberInputComponent
  extends FormInputComponent
  implements OnInit, OnDestroy
{
  public step = 1;

  constructor(public override ngControl: NgControl) {
    super(ngControl);
  }

  ngOnInit() {
    this.ngControl.control!.addValidators(this.conformsToStepSize());
  }

  ngOnDestroy() {
    this.ngControl.control!.removeValidators(this.conformsToStepSize());
  }

  public increaseValue(): void {
    if (!this.ngControl.control!.value) {
      this.setValue(this.step);
    } else {
      this.setValue(this.ngControl.control!.value + this.step);
    }
  }

  public decreaseValue(): void {
    if (!this.ngControl.control!.value) {
      this.setValue(-this.step);
    } else {
      this.setValue(this.ngControl.control!.value - this.step);
    }
  }

  private setValue(value: number): void {
    this.ngControl.control!.setValue(value);
    this.ngControl.control!.updateValueAndValidity();
  }

  private findPrecision(value = 0): number {
    return value.toString().split('.')?.[1]?.length ?? 0;
  }

  private conformsToStepSize(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const stepPrecision = this.findPrecision(this.step);
      const valuePrecision = this.findPrecision(control.value);

      const remnant = control.value ? control.value % this.step : 0;
      return remnant > 0 || valuePrecision > stepPrecision
        ? { stepSize: true }
        : null;
    };
  }
}

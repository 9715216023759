import { Component, input, InputSignal } from '@angular/core';

@Component({
  selector: 'app-loading-animation',
  imports: [],
  templateUrl: './loading-animation.component.html',
})
export class LoadingAnimationComponent {
  public color: InputSignal<string | undefined> = input();
}

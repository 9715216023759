import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import { AppendTestIdPipe } from '../../../pipes/append-test-id/append-test-id.pipe';
import { ErrorMessageComponent } from '../../error-message/error-message.component';
import { FormTranslationInputComponent } from '../form-translation-input.component';

@Component({
  selector: 'app-translation-text-input',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AppendTestIdPipe,
    ErrorMessageComponent,
  ],
  styleUrl: '../form-inputs.css',
  templateUrl: './translation-text-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslationTextInputComponent
  extends FormTranslationInputComponent
  implements OnInit, OnDestroy
{
  constructor(public override ngControl: NgControl) {
    super(ngControl);
  }
}

@if (corporateDesign() && footer()) {
  <div
    [attr.data-testid]="'footer'"
    [style.color]="corporateDesign().primaryFontColor"
    [style.background-color]="footer().backgroundColor"
    class="bottom-0 flex min-h-10 w-full justify-between gap-2 p-2">
    <!-- Link Block -->
    <app-footer-block
      [children]="linkBlockChildren"
      headline="{{ footer().linkArea.headlines | language }}"
      [headlineSize]="corporateDesign().fontSizeTitle">
      <ng-template #linkBlockChildren>
        @for (link of footer().linkArea.links; track link.id) {
          <a
            [attr.data-testid]="'link-' + link.id"
            [style.font-size]="corporateDesign().fontSizeText + 'pt'"
            class="block hover:text-teal-600 hover:underline"
            href="{{ link.urls | language }}"
            id="{{ link.id }}">
            <span class="p-1" [attr.aria-label]="link.texts | language">
              {{ link.texts | language }}
            </span>
          </a>
        }
      </ng-template>
    </app-footer-block>

    @if (footer().paymentArea.toggle) {
      <!-- Payment Block -->
      <app-footer-block
        [children]="paymentBlockChildren"
        headline="{{ footer().paymentArea.headlines | language }}"
        [headlineSize]="corporateDesign().fontSizeTitle">
        <ng-template #paymentBlockChildren>
          @for (
            paymentImage of footer().paymentArea.accessibleImages;
            track paymentImage.id
          ) {
            <a
              [attr.data-testid]="'img-link-' + paymentImage.id"
              [style.font-size]="corporateDesign().fontSizeText + 'pt'"
              class="block hover:text-teal-600 hover:underline"
              href="{{ paymentImage.url }}"
              id="{{ paymentImage.id }}">
              <img
                [attr.data-testid]="'image-' + paymentImage.id"
                [style.font-size]="corporateDesign().fontSizeText + 'pt'"
                [alt]="paymentImage.imageAlts | language"
                [id]="paymentImage.id"
                [src]="requestPath(paymentImage.id, 'paymentArea')"
                class="my-3 block h-auto max-h-8" />
            </a>
          }
        </ng-template>
      </app-footer-block>
    }

    @if (footer().addressArea.toggle) {
      <!-- Address Block -->
      <app-footer-block
        [children]="addressBlockChildren"
        headline="{{ footer().addressArea.headlines | language }}"
        [headlineSize]="corporateDesign().fontSizeTitle">
        <ng-template #addressBlockChildren>
          <quill-view
            [content]="footer().addressArea.texts | language"
            [attr.aria-label]="footer().addressArea.texts | language"
            format="json"
            theme="snow"></quill-view>
        </ng-template>
      </app-footer-block>
    }

    @if (footer().logoArea.toggle) {
      <!-- Logo Block -->
      <app-footer-block
        [children]="logoBlockChildren"
        headline="{{ footer().logoArea.headlines | language }}"
        [headlineSize]="corporateDesign().fontSizeTitle">
        <ng-template #logoBlockChildren>
          @for (img of footer().logoArea.accessibleImages; track img.id) {
            <a
              [attr.data-testid]="'img-link-' + img.id"
              [style.font-size]="corporateDesign().fontSizeText + 'pt'"
              class="block hover:text-teal-600 hover:underline"
              href="{{ img.url }}"
              id="{{ img.id }}">
              <img
                [attr.data-testid]="'image-' + img.id"
                [style.font-size]="corporateDesign().fontSizeText + 'pt'"
                [alt]="img.imageAlts | language"
                [src]="requestPath(img.id, 'logoArea')"
                class="my-3 block h-auto max-h-8" />
            </a>
          }
        </ng-template>
      </app-footer-block>
    }
  </div>
}

@if (corporateDesign() && login()) {
  <div
    class="flex items-center justify-center"
    [style.font-size]="corporateDesign().fontSizeText + 'pt'"
    [formGroup]="loginForm">
    <div class="w-full max-w-md p-6 shadow-md">
      <div class="flex flex-col gap-4">
        <span
          [style.font-size]="corporateDesign().fontSizeTitle + 'pt'"
          [attr.aria-label]="login().loginHeader | language"
          [attr.data-testid]="login().loginHeader | language">
          {{ login().loginHeader | language }}
        </span>
        <app-text-input
          formControlName="email"
          label="{{ login().email | language }}"
          id="loginEmail">
          <div
            [attr.aria-label]="
              'Es muss eine gültige E-Mail Adresse eingegeben werden'
            ">
            Es muss eine gültige E-Mail Adresse eingegeben werden
          </div>
        </app-text-input>
        <app-password-input
          formControlName="password"
          label="{{ login().password | language }}"
          id="loginPassword">
          <div [attr.aria-label]="'Here be Errors for Password'">
            Here be Errors for Password
          </div>
        </app-password-input>
        <div>
          <span
            [attr.aria-label]="login().passwordReset | language"
            [attr.data-testid]="login().passwordReset | language">
            {{ login().passwordReset | language }}
          </span>
          <a
            href="#"
            [style.color]="'#0000EE'"
            [attr.aria-label]="login().passwordResetLink | language"
            [attr.data-testid]="login().passwordResetLink | language">
            {{ login().passwordResetLink | language }}
          </a>
        </div>
        <app-button
          [type]="'submit'"
          [text]="login().loginButton | language"
          [disabled]="!loginForm.valid"
          [style]="'custom'"
          [style.background]="corporateDesign().primaryColor"
          [style.color]="corporateDesign().primaryFontColor"
          (click)="loginUser()">
        </app-button>
        <div class="my-6 flex items-center justify-center">
          <div class="flex-grow border-t border-gray-300"></div>
          <span
            class="px-4 text-gray-500"
            [attr.aria-label]="login().separation | language"
            [attr.data-testid]="login().separation | language"
            >{{ login().separation | language }}</span
          >
          <div class="flex-grow border-t border-gray-300"></div>
        </div>
        <app-button
          [type]="'reset'"
          [text]="login().register | language"
          [style]="'secondary'"
          [style.color]="corporateDesign().secondaryFontColor"
          [style.background]="corporateDesign().secondaryColor"></app-button>
      </div>
    </div>
  </div>
}

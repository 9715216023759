<div class="text-initial box-border w-full">
  @let hasError = ngControl.errors && ngControl.touched;
  <div
    class="w-content relative flex items-center rounded"
    [ngClass]="{
      'form-input__disabled': ngControl.control!.disabled,
    }">
    <input
      [attr.data-testid]="id() | appendTestId: 'input'"
      type="checkbox"
      [id]="id()"
      [formControl]="ngControl.control! | toFormControl"
      class="form-input__ring form-input__focus-text box-border h-5 w-5 !rounded border-0 shadow-sm outline-none"
      [ngClass]="{
        'form-input__check-box--disabled form-input__disabled-background-color':
          ngControl.control!.disabled,
        'form-input__error-ring': hasError,
        'form-input__focus-ring':
          !!ngControl.value && !ngControl.control!.disabled,
      }" />
    <label
      [attr.data-testid]="id() | appendTestId: 'label'"
      [for]="id()"
      (click)="toggle()"
      [ngClass]="{
        '!cursor-not-allowed': ngControl.control!.disabled,
      }"
      class="absolute h-full w-full cursor-pointer pl-7 text-base">
      {{ label() }}
    </label>
  </div>
  @if (hasError && !errorTemplate()) {
    <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
      <ng-content></ng-content>
    </app-error-message>
  }
  @if (hasError && errorTemplate()) {
    <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
      <ng-container *ngTemplateOutlet="errorTemplate()!"></ng-container>
    </app-error-message>
  }
</div>

import { inject, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IText } from '../../entities/text-entity';
import {
  ITicketTileDetailForm,
  ITicketTileForm,
} from '../../pages/cms/tickets/ticket-edit-page/ticket-edit-page.component';
import { Factory } from '../factory';
import { ITicket, ITicketDetail } from '../../entities/ticket-entity';

@Injectable({
  providedIn: 'root',
})
export class TicketTileFactoryService extends Factory<
  ITicketTileForm,
  ITicket
> {
  formBuilder = inject(FormBuilder);

  buildEntity(form: FormGroup<ITicketTileForm>): ITicket {
    const ticket: ITicket = form.getRawValue();
    this.removeNullIds(ticket);
    return ticket;
  }

  buildForm(entity: ITicket | undefined): FormGroup<ITicketTileForm> {
    return this.formBuilder.group({
      id: this.formBuilder.nonNullable.control(entity?.id),
      client: this.formBuilder.nonNullable.control(entity?.client ?? '', [
        Validators.required,
      ]),
      ticketId: this.formBuilder.nonNullable.control(entity?.ticketId ?? '', [
        Validators.required,
      ]),
      overviewId: this.formBuilder.nonNullable.control(
        entity?.overviewId ?? '',
        [Validators.required]
      ),
      active: this.formBuilder.nonNullable.control(entity?.active ?? false, [
        Validators.required,
      ]),
      startDate: this.formBuilder.nonNullable.control(entity?.startDate ?? '', [
        Validators.required,
      ]),
      endDate: this.formBuilder.nonNullable.control(entity?.endDate ?? '', []),
      title: this.formBuilder.nonNullable.control<IText[]>(
        entity?.title ?? [],
        [Validators.required]
      ),
      price: this.formBuilder.nonNullable.control(entity?.price ?? 0, [
        Validators.required,
      ]),
      description: this.formBuilder.nonNullable.control<IText[]>(
        entity?.description ?? [],
        [Validators.required]
      ),
      detailsTitle: this.formBuilder.nonNullable.control<IText[]>(
        entity?.detailsTitle ?? [],
        [Validators.required]
      ),
      details: this.formBuilder.nonNullable.array(
        entity?.details?.map(detail =>
          this.createTicketTileDetailForm(detail)
        ) ?? []
      ),
      button: this.formBuilder.nonNullable.control<IText[]>(
        entity?.button ?? [],
        [Validators.required]
      ),
    });
  }

  public createTicketTileDetailForm(
    detail: ITicketDetail | undefined = undefined
  ): FormGroup<ITicketTileDetailForm> {
    return this.formBuilder.group({
      icon: this.formBuilder.nonNullable.control(detail?.icon ?? '', [
        Validators.required,
      ]),
      text: this.formBuilder.nonNullable.control<IText[]>(detail?.text ?? [], [
        Validators.required,
      ]),
    });
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import {
  ObjectOption,
  SelectOption,
} from '../../components/form-inputs/form-input-select.component';

@Pipe({
  name: 'toSelectOptionAttribute',
  standalone: true,
})
export class ToSelectOptionAttributePipe implements PipeTransform {
  transform(option: SelectOption, name?: string): unknown {
    return name && option && Object.prototype.hasOwnProperty.call(option, name)
      ? (option as ObjectOption)[name]
      : option;
  }
}

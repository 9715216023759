import { Observable } from 'rxjs';
import {
  IDeleteGateway,
  IGetGateway,
  ISaveGateway,
  IUploadGateway,
} from '../../gateways';
import { ImageSchema, IImage } from '../../../entities/image-entity';
import { RestCallBuilder } from '../../../builder/rest/rest.builder';
import { HeaderSchema, IHeader } from '../../../entities/header-entity';

export class ImageGateway
  implements
    IGetGateway<null, IImage[]>,
    ISaveGateway<IImage[], IImage[]>,
    IUploadGateway<FormData, IImage[]>,
    IDeleteGateway<IImage, IImage[]>
{
  private restCallBuilder: RestCallBuilder<IImage[]> = new RestCallBuilder<
    IImage[]
  >([]);

  get(): Observable<IImage[]> {
    return this.restCallBuilder
      .get('/images/')
      .validate(ImageSchema)
      .handleErrors()
      .build();
  }

  save(query: IImage[]): Observable<IImage[]> {
    return this.restCallBuilder
      .post('/images/', query)
      .validate(ImageSchema)
      .handleErrors()
      .build();
  }

  upload(query: FormData): Observable<IImage[]> {
    return this.restCallBuilder
      .post('/images/upload/', query)
      .handleErrors()
      .build();
  }

  delete(query: IImage): Observable<IImage[]> {
    return this.restCallBuilder
      .delete('/images/' + query.id)
      .handleErrors()
      .build();
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ButtonGroupSupportService {
  findValueOnEnum = (value: string, enm: any, fallback: any): any => {
    return Object.values(enm).find(k => k === value) ?? fallback;
  };
}

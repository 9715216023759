import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { ClientService } from '../../client/client.service';
import { IGetGateway, ISaveGateway } from '../../gateways';
import {
  BannerSchema,
  defaultBanner,
  IBanner,
} from '../../../entities/banner-entity';
import { RestCallBuilder } from '../../../builder/rest/rest.builder';

export class BannerGateway
  implements
    IGetGateway<null, IBanner | null>,
    ISaveGateway<IBanner, IBanner | null>
{
  private clientService = inject(ClientService);
  private client = this.clientService.client;
  private restCallBuilder: RestCallBuilder<IBanner> =
    new RestCallBuilder<IBanner>(defaultBanner);

  get(): Observable<IBanner> {
    return this.restCallBuilder
      .get('/banner/' + this.client)
      .validate(BannerSchema)
      .handleErrors()
      .build();
  }

  save(query: IBanner): Observable<IBanner> {
    return this.restCallBuilder
      .post('/banner/', query)
      .validate(BannerSchema)
      .handleErrors()
      .build();
  }
}

@if (banner()) {
  <div class="relative">
    @if (banner().textActive) {
      <div
        class="absolute inset-0 flex flex-col p-4"
        [ngClass]="{
          'items-start': banner().textAlignmentHorizontal === 'top',
          'items-center': banner().textAlignmentHorizontal === 'center',
          'items-end': banner().textAlignmentHorizontal === 'bottom',
          'justify-start': banner().textAlignmentVertical === 'left',
          'justify-center': banner().textAlignmentVertical === 'center',
          'justify-end': banner().textAlignmentVertical === 'right',
        }"
        [ngStyle]="{
          'font-family': banner().textFont?.name ?? 'Arial',
          color: banner().textColor,
        }">
        @if (banner().headlineFontSize) {
          <span
            [attr.data-testid]="'bannerHeadline'"
            [attr.aria-label]="banner().headlines | language"
            [ngStyle]="{
              'font-size': banner().headlineFontSize + 'pt',
            }">
            {{ banner().headlines | language }}
          </span>
        }
        @if (banner().subHeadlineFontSize) {
          <span
            [attr.data-testid]="'bannerSubHeadline'"
            [attr.aria-label]="banner().subHeadlines | language"
            [ngStyle]="{
              'font-size': banner().subHeadlineFontSize + 'pt',
            }">
            {{ banner().subHeadlines | language }}
          </span>
        }
      </div>
    }
    @if (banner().imageActive && banner().accessibleImage.imageAlts) {
      <img
        class="mx-auto"
        [style.max-height.px]="banner().imageHeight"
        [src]="bannerPath()"
        [attr.data-testid]="'bannerImage'"
        [alt]="banner().accessibleImage.imageAlts | language" />
    }
  </div>
}

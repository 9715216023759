import { Route } from '@angular/router';
import { LandingPageComponent } from './pages/shop/landing/landing-page.component';
import { HeaderPageComponent } from './pages/cms/header/header-page.component';
import { FooterPageComponent } from './pages/cms/footer/footer-page.component';
import { CorporatePageComponent } from './pages/cms/corporate/corporate-page.component';
import { CmsPageComponent } from './pages/cms/cms-page.component';
import { ShopPageComponent } from './pages/shop/shop-page.component';
import { ErrorPageComponent } from './pages/shop/error/error-page.component';
import { TicketsPageComponent } from './pages/cms/tickets/tickets-page.component';
import { TicketEditPageComponent } from './pages/cms/tickets/ticket-edit-page/ticket-edit-page.component';
import { ImagePageComponent } from './pages/cms/image/image-page.component';
import { FormInputsDisplayComponent } from './components/form-inputs-display/form-inputs-display.component';
import { FontPageComponent } from './pages/cms/font/font-page.component';
import { CorporateFormChangedGuard } from './pages/cms/guards/corporate-form-changed-guard.service';
import { PageNotFoundComponent } from './pages/shop/page-not-found/page-not-found.component';
import { FooterFormChangedGuard } from './pages/cms/guards/footer-form-changed-guard.service';
import { BannerPageComponent } from './pages/cms/banner/banner-page.component';
import { BannerFormChangedGuard } from './pages/cms/guards/banner-form-changed-guard.service';
import { HeaderFormChangedGuard } from './pages/cms/guards/header-form-changed-guard.service';
import { LoginLogoutComponent } from './pages/cms/login-logout/login-logout.component';
import { LoginComponent } from './components/login/login.component';
import { PrivateAreaPageComponent } from './pages/cms/account/privateArea/private-area-page.component';
import { TicketTileFormChangedGuard } from './pages/cms/guards/ticket-tile-form-changed.guard.service';

export const appRoutes: Route[] = [
  {
    path: 'cms',
    component: CmsPageComponent,
    children: [
      {
        path: 'header',
        component: HeaderPageComponent,
        canDeactivate: [HeaderFormChangedGuard],
      },
      {
        path: 'banner',
        component: BannerPageComponent,
        canDeactivate: [BannerFormChangedGuard],
      },
      {
        path: 'footer',
        component: FooterPageComponent,
        canDeactivate: [FooterFormChangedGuard],
      },
      {
        path: 'corporate',
        component: CorporatePageComponent,
        canDeactivate: [CorporateFormChangedGuard],
      },
      { path: 'images', component: ImagePageComponent },
      { path: 'fonts', component: FontPageComponent },
      {
        path: 'tickets',
        component: TicketsPageComponent,
      },
      {
        path: 'tickets/:ticketId',
        component: TicketEditPageComponent,
        canDeactivate: [TicketTileFormChangedGuard],
      },
      { path: 'login', component: LoginLogoutComponent },
      {
        path: 'inputs',
        component: FormInputsDisplayComponent,
      },
      {
        path: 'account',
        children: [
          {
            path: 'private-area',
            component: PrivateAreaPageComponent,
          },
        ],
      },
    ],
  },
  {
    path: '',
    component: ShopPageComponent,
    children: [
      { path: '', component: LandingPageComponent },
      {
        path: 'login',
        component: LoginComponent,
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
  { path: 'error', component: ErrorPageComponent },
];

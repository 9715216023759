import {
  Component,
  OnDestroy,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  ControlContainer,
  FormGroupDirective,
  NgControl,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { fromEvent, map, startWith, Subscription, tap } from 'rxjs';
import { ErrorMessageComponent } from '../../error-message/error-message.component';
import {
  FormInputSelectComponent,
  ObjectOption,
  SelectOption,
} from '../form-input-select.component';
import { SelectionOptionValueToNamePipe } from '../../../pipes/selection-option-value-to-name/selection-option-value-to-name.pipe';
import { ToFormControlPipe } from '../../../pipes/to-form-control/to-form-control.pipe';
import { ToSelectOptionAttributePipe } from '../../../pipes/to-select-option-attribute/to-select-option-attribute.pipe';
import { AppendTestIdPipe } from '../../../pipes/append-test-id/append-test-id.pipe';

@Component({
  selector: 'app-auto-complete',
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ErrorMessageComponent,
    SelectionOptionValueToNamePipe,
    ToFormControlPipe,
    ToSelectOptionAttributePipe,
    AppendTestIdPipe,
  ],
  styleUrl: '../form-inputs.css',
  templateUrl: './auto-complete.component.html',
})
export class AutoCompleteComponent
  extends FormInputSelectComponent
  implements OnInit, OnDestroy
{
  public $filterOptions!: Subscription;
  public $handleKeyUpEvent!: Subscription;
  public $handleClickEvent!: Subscription;
  private $valueChanges!: Subscription;
  public filteredOptions: WritableSignal<SelectOption[]> = signal([]);
  public selectedItem: WritableSignal<number | undefined> = signal(undefined);
  public showList: WritableSignal<boolean> = signal(false);

  constructor(public override ngControl: NgControl) {
    super(ngControl);
  }

  ngOnInit(): void {
    this.ngControl.control!.addValidators(this.isValidOption());

    this.setIndexFromValue(this.ngControl.control!.value);
    this.$valueChanges = this.ngControl.control!.valueChanges.subscribe(v => {
      this.setIndexFromValue(v);
    });
    this.$filterOptions = this.ngControl!.valueChanges!.pipe(
      tap(() => this.showList.set(true)),
      startWith(''),
      map(value => this.filter(value?.toString() || '')),
      tap(filteredOptions => this.filteredOptions.set(filteredOptions))
    ).subscribe();

    this.$handleKeyUpEvent = fromEvent<
      KeyboardEvent & { target?: { id: string } }
    >(document, 'keyup').subscribe(event => {
      const isCorrectTarget = event.target?.id === this.id();
      if (isCorrectTarget) {
        event.preventDefault();
        const filteredOptions = this.filteredOptions();
        if (event.key === 'ArrowDown' && isCorrectTarget) {
          this.selectedItem.set(
            this.selectedItem() !== undefined
              ? this.clampSelectedItem(
                  this.selectedItem()! + 1,
                  filteredOptions.length
                )
              : 0
          );
        } else if (event.key === 'ArrowUp' && isCorrectTarget) {
          this.selectedItem.set(
            this.selectedItem() !== 0 && this.selectedItem() !== undefined
              ? this.clampSelectedItem(
                  this.selectedItem()! - 1,
                  filteredOptions.length
                )
              : undefined
          );
        } else if (
          event.key === 'Enter' &&
          isCorrectTarget &&
          this.selectedItem() !== undefined &&
          this.showList()
        ) {
          this.selectOptionAndSetItem(
            filteredOptions?.[this.selectedItem()!],
            this.selectedItem()!
          );
        } else if (
          event.key === 'Backspace' &&
          isCorrectTarget &&
          '[object Object]'.includes(
            this.ngControl.control!.value.toString()
          ) &&
          !this.value()
        ) {
          this.ngControl.control!.setValue('');
        } else if (!isCorrectTarget && this.showList()) {
          this.showList.set(false);
        }
      }
    });

    this.$handleClickEvent = fromEvent<
      MouseEvent & { target?: { id: string } }
    >(document, 'click').subscribe(event => {
      const isCorrectTarget = event?.target?.id === this.id();
      if (!isCorrectTarget) {
        this.showList.set(false);
      } else if (isCorrectTarget && !this.showList()) {
        this.showList.set(true);
      }
    });
  }

  ngOnDestroy() {
    this.ngControl.control!.removeValidators(this.isValidOption());
    this.$filterOptions.unsubscribe();
    this.$handleKeyUpEvent.unsubscribe();
    this.$handleClickEvent.unsubscribe();
  }

  public selectOptionAndSetItem(option: SelectOption, index: number): void {
    super.selectOption(option);
    this.selectedItem.set(index);
    this.showList.set(false);
  }

  private clampSelectedItem(newValue: number, maxLength: number): number {
    if (newValue >= maxLength) {
      return maxLength - 1;
    } else if (newValue < 0) {
      return 0;
    }
    return newValue;
  }

  private filter(value: string): SelectOption[] {
    const filterValue = value?.toLowerCase();
    if (this.name()) {
      return this.options().filter(option => {
        return (option as ObjectOption)[this.name()!]
          ? ((option as ObjectOption)[this.name()!] as string)
              .toString()
              .toLowerCase()
              .includes(filterValue)
          : (option as string).toString().toLowerCase().includes(filterValue);
      });
    }

    return this.options().filter(option =>
      option?.toString()?.toLowerCase().includes(filterValue)
    );
  }

  private isValidOption(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === undefined || control.value === null) {
        return null;
      }
      const isInOptions = this.options().filter(option =>
        this.value()
          ? control.value == (option as ObjectOption)[this.value()!]
          : control.value == option
      );
      return isInOptions.length === 0 ? { invalidOption: true } : null;
    };
  }

  private setIndexFromValue(value: unknown): void {
    this.selectedItem.set(this.findIndexFromValue(value));
  }
}

<a
  href="#"
  [routerLink]="item().routerLink"
  routerLinkActive="bg-standard-hover"
  [ngClass]="{
    'w-fit': !expanded(),
  }"
  class="text-s hover:bg-standard-hover flex items-center rounded-2xl px-2 py-2 text-gray-700 transition-colors">
  @if (item().icon.length > 0) {
    <span class="text-standard-icon material-symbols-rounded text-2xl">
      {{ item().icon }}
    </span>
  }

  @if (expanded()) {
    <span class="text-standard-text-primary ml-3">{{ item().label }}</span>
  }
</a>

<div class="flex">
  <!-- sidebar -->
  <app-sidebar class="'min-w-64' overflow-hidden" />

  <div class="ml-auto flex w-fit gap-2">
    <app-reset-changes-dialog />
    <app-reset-to-factory-settings-dialog />
  </div>
  <!-- Main content -->
  <div class="flex flex-1 flex-col">
    <div class="ml-auto flex w-fit gap-2">
      <app-reset-changes-dialog />
      <app-reset-to-factory-settings-dialog />
    </div>
    <div
      class="flex h-16 items-center justify-between overflow-hidden border-b border-gray-200 bg-white"></div>
    <div class="h-full overflow-auto">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

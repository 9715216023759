@if (expanded()) {
  <span class="text-l text-standard-text-primary">{{ menutab().heading }}</span>
} @else {
  <hr
    class="border-standard-divider my-1 w-3/4 bg-zinc-300 bg-gradient-to-r from-transparent via-neutral-500 to-transparent" />
}
<ul>
  @for (item of menutab().items; track $index) {
    <li>
      <app-sidebar-item
        [item]="item"
        [expanded]="expanded()"></app-sidebar-item>
    </li>
  }
  @for (menu of menutab().menus; track $index) {
    <li>
      <app-sidebar-menu
        [menu]="menu"
        [sideBarExpanded]="expanded()"></app-sidebar-menu>
    </li>
  }
</ul>

import {
  Component,
  effect,
  inject,
  input,
  InputSignal,
  OnDestroy,
  Signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { fromEvent, Subscription } from 'rxjs';
import {
  SortOrder,
  TableComponent,
  TableSortOrder,
  TableSortParams,
} from '../table/table.component';
import { TicketDeleteDialogComponent } from '../dialogs/ticket-delete-modal/ticket-delete-dialog.component';

import { RouterLink } from '@angular/router';
import { IDialogResponse } from '../dialogs/dialog';
import {
  ICombinedTicket,
  TicketDataOption,
  CombinedTicketsService,
  TicketType,
} from '../../services/tickets/combined-tickets.service';
import { Dialog } from '@angular/cdk/dialog';
import { toSignal } from '@angular/core/rxjs-interop';
import { DropDownComponent } from '../form-inputs/drop-down/drop-down.component';
import { TextInputComponent } from '../form-inputs/text-input/text-input.component';
import { AppendTestIdPipe } from '../../pipes/append-test-id/append-test-id.pipe';
import { TableHeaderComponent } from '../table/table-header/table-header.component';

type TicketsOverviewFilter = FormGroup<{
  ticketType: FormControl<TicketType | null>;
  hasData: FormControl<TicketDataOption | null>;
  isActive: FormControl<TicketDataOption | null>;
  name: FormControl<string | null>;
}>;

type TicketsOverviewFilterValue = TicketsOverviewFilter['value'];

@Component({
  selector: 'app-tickets-overview',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TableComponent,
    RouterLink,
    DropDownComponent,
    TextInputComponent,
    AppendTestIdPipe,
    TableHeaderComponent,
  ],
  templateUrl: './tickets-overview.component.html',
})
export class TicketsOverviewComponent implements OnDestroy {
  private readonly ticketsService: CombinedTicketsService = inject(
    CombinedTicketsService
  );
  private readonly dialog: Dialog = inject(Dialog);
  private readonly appendTestId: AppendTestIdPipe = new AppendTestIdPipe();

  protected readonly TicketType = TicketType;

  public readonly headerColumnClasses = 'p-2 font-semibold';
  public readonly columnClass =
    'p-2 border-t-1 border-gray-300 flex flex-row items-center';
  public readonly actionIcon =
    'text-2xl m-1 text-gray-800 cursor-pointer hover:blue-600';

  public readonly ticketTypes: { name: string; value: TicketType }[] = [
    { name: 'Alle', value: TicketType.All },
    { name: 'Abonnement', value: TicketType.Subscription },
    { name: 'Direkt-Ticket', value: TicketType.Direct },
  ];

  public readonly ticketDataOptions: {
    name: string;
    value: TicketDataOption;
  }[] = [
    { name: 'Alle', value: TicketDataOption.All },
    { name: 'Ja', value: TicketDataOption.Yes },
    { name: 'Nein', value: TicketDataOption.No },
  ];

  public readonly filterForm: TicketsOverviewFilter = new FormGroup({
    ticketType: new FormControl<TicketType | null>(TicketType.All),
    hasData: new FormControl<TicketDataOption | null>(TicketDataOption.All),
    isActive: new FormControl<TicketDataOption | null>(TicketDataOption.All),
    name: new FormControl<string | null>(null),
  });

  public readonly componentSubscriptions: Subscription = new Subscription();

  public readonly id: InputSignal<string> = input.required();
  public readonly tickets: Signal<ICombinedTicket[]> = toSignal(
    this.ticketsService.$tickets,
    { initialValue: [] }
  );
  public filteredTickets: ICombinedTicket[] = this.tickets();

  public ticketStatusFilter: TableSortParams<ICombinedTicket>['compare'] = (
    a: ICombinedTicket,
    b: ICombinedTicket,
    order: TableSortOrder
  ): number => {
    const sortDirection = order === SortOrder.ASC ? 1 : -1;
    return !a.isActive && b.isActive ? 1 * sortDirection : -1 * sortDirection;
  };

  public ticketDataFilter: TableSortParams<ICombinedTicket>['compare'] = (
    a: ICombinedTicket,
    b: ICombinedTicket,
    order: TableSortOrder
  ): number => {
    const sortDirection = order === SortOrder.ASC ? 1 : -1;
    return !a.data?.title && !!b.data?.title
      ? 1 * sortDirection
      : -1 * sortDirection;
  };

  public ticketTypeFilter: TableSortParams<ICombinedTicket>['compare'] = (
    a: ICombinedTicket,
    b: ICombinedTicket,
    order: TableSortOrder
  ): number => {
    const sortDirection = order === SortOrder.ASC ? 1 : -1;
    return a.type === TicketType.Direct && b.type !== TicketType.Direct
      ? 1 * sortDirection
      : -1 * sortDirection;
  };

  public nameFilter: TableSortParams<ICombinedTicket>['compare'] = (
    a: ICombinedTicket,
    b: ICombinedTicket,
    order: TableSortOrder
  ): number => {
    const sortDirection = order === SortOrder.ASC ? 1 : -1;
    return a.name.toLowerCase() > b.name.toLowerCase()
      ? 1 * sortDirection
      : -1 * sortDirection;
  };

  constructor() {
    effect(() => {
      this.filteredTickets = this.filterTickets(
        this.tickets(),
        this.toTicketFilterValue(
          this.filterForm.value as TicketsOverviewFilterValue
        )
      );
    });
    this.componentSubscriptions.add(
      this.filterForm.valueChanges.subscribe(value => {
        this.filteredTickets = this.filterTickets(
          this.tickets(),
          this.toTicketFilterValue(value as TicketsOverviewFilterValue)
        );
      })
    );
    this.componentSubscriptions.add(
      fromEvent<KeyboardEvent & { target?: { id: string } }>(
        document,
        'keyup'
      ).subscribe(event => {
        const targetIsResetButton =
          event.target?.id ===
          this.appendTestId.transform(this.id(), 'reset-filter');
        if (event.key === 'Enter' && targetIsResetButton) {
          event.preventDefault();
          this.resetFilter();
        }
      })
    );
    this.filterForm.updateValueAndValidity();
  }

  public openDeleteDialog(ticket: ICombinedTicket): void {
    const dialogRef = this.dialog.open<IDialogResponse<ICombinedTicket>>(
      TicketDeleteDialogComponent,
      {
        width: '250px',
        data: { title: 'Ticket Löschen', ticket },
      }
    );

    dialogRef.closed.subscribe(result => {
      console.log('The delete dialog closed', result);
      this.deleteTicket(result);
    });
  }

  public resetFilter(): void {
    this.filterForm.reset({
      ticketType: TicketType.All,
      hasData: TicketDataOption.All,
      isActive: TicketDataOption.All,
      name: null,
    });
  }

  public filterTickets(
    allSubscriptions: ICombinedTicket[],
    filter: TicketsOverviewFilterValue
  ): ICombinedTicket[] {
    return allSubscriptions
      .filter(s => this.nameIncludesSubstring(s, filter.name))
      .filter(s => this.typeMatchesFilter(s, filter.ticketType))
      .filter(s => this.statusMatchesFilter(s, filter.isActive))
      .filter(s => this.dataMatchesFilter(s, filter.hasData));
  }

  private deleteTicket(
    response: IDialogResponse<ICombinedTicket> | undefined
  ): void {
    if (response?.submit) {
      console.log('delete', response.data);
      //TODO: Folgeticket
    }
  }

  private nameIncludesSubstring(
    subscription: ICombinedTicket,
    subStringFilter: string | null | undefined
  ): boolean {
    return subStringFilter
      ? subscription.name.toLowerCase().includes(subStringFilter.toLowerCase())
      : true;
  }

  private hasObjectValue<T>(obj: T): boolean {
    if (!obj) {
      return false;
    }
    return Object.keys(obj).length > 0;
  }
  private dataMatchesFilter(
    subscription: ICombinedTicket,
    filter: TicketDataOption | null | undefined
  ): boolean {
    const matchBoolean = () => {
      return filter
        ? this.hasObjectValue(subscription.data)
        : !this.hasObjectValue(subscription.data);
    };
    return filter !== TicketDataOption.All ? matchBoolean() : true;
  }

  private statusMatchesFilter(
    subscription: ICombinedTicket,
    filter: TicketDataOption | null | undefined
  ): boolean {
    return filter !== TicketDataOption.All
      ? subscription.isActive === !!filter
      : true;
  }

  private typeMatchesFilter(
    subscription: ICombinedTicket,
    filter: TicketType | null | undefined
  ): boolean {
    return filter !== TicketType.All ? subscription.type === filter : true;
  }

  private toTicketFilterValue(
    value: TicketsOverviewFilterValue
  ): TicketsOverviewFilterValue {
    const compensateNull = <T>(value: T) => (value === 'null' ? null : value);

    return {
      hasData: value.hasData,
      isActive: value.isActive,
      name: compensateNull(value.name),
      ticketType: compensateNull(value.ticketType),
    };
  }

  ngOnDestroy(): void {
    this.componentSubscriptions.unsubscribe();
  }
}

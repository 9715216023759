import {
  Component,
  signal,
  WritableSignal,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ControlContainer,
  FormGroupDirective,
  FormsModule,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { SelectionOptionValueToNamePipe } from '../../../pipes/selection-option-value-to-name/selection-option-value-to-name.pipe';
import { ErrorMessageComponent } from '../../error-message/error-message.component';
import {
  FormInputSelectComponent,
  ObjectOption,
  SelectOption,
} from '../form-input-select.component';
import { ToSelectOptionAttributePipe } from '../../../pipes/to-select-option-attribute/to-select-option-attribute.pipe';
import { AppendTestIdPipe } from '../../../pipes/append-test-id/append-test-id.pipe';
import { fromEvent, map, startWith, Subscription, tap } from 'rxjs';
import { ToFormControlPipe } from '../../../pipes/to-form-control/to-form-control.pipe';

@Component({
  selector: 'app-drop-down',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SelectionOptionValueToNamePipe,
    ErrorMessageComponent,
    ToSelectOptionAttributePipe,
    AppendTestIdPipe,
    ToFormControlPipe,
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  styleUrl: '../form-inputs.css',
  templateUrl: './drop-down.component.html',
})
export class DropDownComponent
  extends FormInputSelectComponent
  implements OnInit, OnDestroy
{
  public selectedItem: WritableSignal<number | undefined> = signal(undefined);
  public showList: WritableSignal<boolean> = signal(false);

  private $handleKeyUpEvent!: Subscription;
  private $handleClickEvent!: Subscription;
  private $valueChanges!: Subscription;

  constructor(public override ngControl: NgControl) {
    super(ngControl);
  }

  ngOnInit(): void {
    this.setIndexFromValue(this.ngControl.control!.value);
    this.$valueChanges = this.ngControl.control!.valueChanges.subscribe(v => {
      this.setIndexFromValue(v);
    });
    this.$handleKeyUpEvent = fromEvent<
      KeyboardEvent & { target?: { id: string } }
    >(document, 'keyup').subscribe(event => {
      const options = this.options();
      const isCorrectTarget = event.target?.id === this.id();
      if (isCorrectTarget) {
        event.preventDefault();
        if (event.key === 'ArrowDown' && isCorrectTarget) {
          this.selectedItem.set(
            this.selectedItem() !== undefined
              ? this.clampSelectedItem(this.selectedItem()! + 1, options.length)
              : 0
          );
        } else if (event.key === 'ArrowUp' && isCorrectTarget) {
          this.selectedItem.set(
            this.selectedItem() !== 0 && this.selectedItem() !== undefined
              ? this.clampSelectedItem(this.selectedItem()! - 1, options.length)
              : undefined
          );
        } else if (
          event.key === 'Enter' &&
          isCorrectTarget &&
          this.selectedItem() !== undefined &&
          this.showList()
        ) {
          this.selectOptionAndSetItem(
            options?.[this.selectedItem()!],
            this.selectedItem()!
          );
        } else if (
          event.key === 'Backspace' &&
          isCorrectTarget &&
          '[object Object]'.includes(
            this.ngControl.control!.value.toString()
          ) &&
          !this.value()
        ) {
          this.ngControl.control!.setValue('');
        } else if (!isCorrectTarget && this.showList()) {
          this.showList.set(false);
        }
      }
    });

    this.$handleClickEvent = fromEvent<
      MouseEvent & { target?: { id: string } }
    >(document, 'click').subscribe(event => {
      const isCorrectTarget = event?.target?.id === this.id();
      if (!isCorrectTarget) {
        this.showList.set(false);
      } else if (isCorrectTarget && !this.showList()) {
        this.showList.set(true);
      }
    });
  }

  ngOnDestroy() {
    this.$handleKeyUpEvent.unsubscribe();
    this.$handleClickEvent.unsubscribe();
  }

  public selectOptionAndSetItem(option: SelectOption, index: number): void {
    super.selectOption(option);
    this.selectedItem.set(index);
    this.showList.set(false);
  }

  private clampSelectedItem(newValue: number, maxLength: number): number {
    if (newValue >= maxLength) {
      return maxLength - 1;
    } else if (newValue < 0) {
      return 0;
    }
    return newValue;
  }

  private setIndexFromValue(value: unknown): void {
    this.selectedItem.set(this.findIndexFromValue(value));
  }
}

@if (isLoading()) {
  <div [ngClass]="tableClasses()" class="py-5">
    <app-loading-animation></app-loading-animation>
  </div>
}
@if (data() && data()!.length > 0 && !isLoading()) {
  <div [attr.data-testid]="id()">
    <div [ngClass]="tableClasses()">
      <div
        [ngClass]="headerClasses()"
        [attr.data-testid]="id() | appendTestId: 'header'">
        <ng-container
          *ngTemplateOutlet="
            header();
            context: { sortBy: sortBy }
          "></ng-container>
      </div>
      @for (s of currentPageData(); track $index; let index = $index) {
        <div
          [ngClass]="rowClasses()"
          [attr.data-testid]="id() | appendTestId: 'row-' + index">
          <ng-container
            *ngTemplateOutlet="
              columns();
              context: { data: s, index: index }
            "></ng-container>
        </div>
      }
    </div>
    @if (data()) {
      <div
        class="flex flex-row items-center justify-between pt-4 text-sm"
        [attr.data-testid]="id() | appendTestId: 'dataInfo'">
        <div>
          {{ labels().entriesText }} {{ currentPage * pageSize() + 1 }}-{{
            data()!.length < currentPage * pageSize() + pageSize()
              ? data()!.length
              : currentPage * pageSize() + pageSize()
          }}
          {{ labels().ofEntriesText }}
          {{ data()!.length }}
        </div>
        <div
          class="flex flex-row items-center gap-4"
          [attr.data-testid]="id() | appendTestId: 'pageSize'">
          {{ labels().entriesPerPageText }}
          <div class="w-20">
            <app-drop-down
              [id]="id() | appendTestId: 'pageSizeSelect'"
              [options]="pageSizeOptions()"
              [formControl]="pageSizeControl"></app-drop-down>
          </div>
        </div>
        <div class="flex flex-row items-center gap-4">
          <div (click)="previousPage()">
            <app-button
              [type]="'button'"
              [style]="'secondary'"
              [disabled]="currentPage === 0"
              [id]="id() | appendTestId: 'previousPage'"
              [attr.data-testid]="id() | appendTestId: 'previousPage'">
              <span class="material-symbols-rounded"> arrow_back </span>
            </app-button>
          </div>

          <div [attr.data-testid]="id() | appendTestId: 'currentPage'">
            {{ currentPage + 1 }} von {{ maxPage() }}
          </div>
          <div (click)="nextPage()">
            <app-button
              [type]="'button'"
              [style]="'secondary'"
              [disabled]="currentPage === maxPage() - 1"
              [id]="id() | appendTestId: 'nextPage'"
              [attr.data-testid]="id() | appendTestId: 'nextPage'">
              <span class="material-symbols-rounded"> arrow_forward </span>
            </app-button>
          </div>
        </div>
      </div>
    }
  </div>
} @else if (!data() && !isLoading()) {
  <div
    [ngClass]="tableClasses()"
    class="flex flex-row justify-center p-4"
    [attr.data-testid]="id() | appendTestId: 'noEntries'">
    {{ labels().noEntriesFoundMessage }}
  </div>
}

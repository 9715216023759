import { AbstractControl, FormGroup } from '@angular/forms';

export abstract class Factory<
  FORM extends { [K in keyof FORM]: AbstractControl },
  ENTITY,
> {
  abstract buildForm(entity: ENTITY): FormGroup<FORM>;
  abstract buildEntity(form: FormGroup<FORM>): ENTITY;

  removeNullIds(obj: any): void {
    if (Array.isArray(obj)) {
      // If it's an array, process each element
      obj.forEach(item => this.removeNullIds(item));
    } else if (obj && typeof obj === 'object') {
      // If it's an object, iterate over its keys
      for (const key of Object.keys(obj)) {
        if (obj[key] === null && key === 'id') {
          delete obj[key]; // Delete key if value is null
        } else {
          this.removeNullIds(obj[key]); // Recursively process nested objects/arrays
        }
      }
    }
  }
}

import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { ErrorHandlingService } from '../../../services/errorHandling/error-handling.service';

@Component({
  selector: 'app-error-page',
  imports: [CommonModule],
  templateUrl: './error-page.component.html',
  styles: ``,
})
export class ErrorPageComponent {
  private router = inject(Router);
  public errorHandlingService = inject(ErrorHandlingService);
}

import {
  Component,
  effect,
  input,
  InputSignal,
  output,
  OutputEmitterRef,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { SortOrder, TableSortOrder, TableSortParams } from '../table.component';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
})
export class TableHeaderComponent<T> implements OnDestroy {
  public readonly sortParams: InputSignal<TableSortParams<T> | undefined> =
    input();
  public sortState: TableSortOrder = SortOrder.ASC;
  public readonly sort: OutputEmitterRef<TableSortParams<T>> =
    output<TableSortParams<T>>();
  protected readonly SortOrder = SortOrder;
  private readonly $handleKeyUpEvent: Subscription;
  @ViewChild('tableHeader')
  component!: ElementRef;

  constructor() {
    effect(() => {
      this.sortState = this.sortParams()?.order;
    });
    this.$handleKeyUpEvent = fromEvent<
      KeyboardEvent & { target?: { id: string } }
    >(document, 'keyup').subscribe(event => {
      event.preventDefault();
      const isCorrectTarget = this.component.nativeElement === event.target;
      if (event.key === 'Enter' && isCorrectTarget) {
        this.emitSort();
      }
    });
  }

  ngOnDestroy() {
    this.$handleKeyUpEvent.unsubscribe();
  }

  public emitSort(): void {
    if (this.sortParams()) {
      this.sortState = this.determineSortState();
      this.sort.emit({ ...this.sortParams()!, order: this.sortState });
    }
  }

  public resetParam(): void {
    this.sortState = undefined;
  }

  private determineSortState(): TableSortOrder {
    return this.sortState === SortOrder.ASC
      ? SortOrder.DESC
      : this.sortState === SortOrder.DESC
        ? undefined
        : SortOrder.ASC;
  }
}

<div class="grid h-screen grid-cols-4 gap-5">
  <div class="col-span-3">
    <ng-content></ng-content>
  </div>
  <div class="col-span-1">
    <div class="fixed border-l-4 border-blue-600">
      <ng-content select="app-sub-navigation"></ng-content>
    </div>
  </div>
</div>

@if (footer()) {
  <div [formGroup]="languageForm" class="flex w-full justify-end">
    <app-language-switcher
      formControlName="singleSelect"
      label="Single Select"
      id="single-select">
      <div>Here be Errors for the Single Select</div>
    </app-language-switcher>
  </div>

  <app-section [formGroup]="footerForm">
    <app-section-header>
      <app-section-headline> Footer </app-section-headline>
      <app-section-header-actions>
        <app-button
          [style]="'primary'"
          (click)="save()"
          [type]="'submit'"
          [disabled]="!footerForm.valid">
          Speichern
        </app-button>
        <app-button
          [style]="'secondary'"
          [type]="'reset'"
          (click)="showPopUpChanges()">
          Abbrechen
        </app-button>
      </app-section-header-actions>
    </app-section-header>

    <!-- Accessibility Hint-->
    <app-accessibility-banner />

    <app-section>
      <app-section-header>
        <app-section-headline> Farben </app-section-headline>
        <app-section-header-actions>
          <app-button [type]="'reset'" [style]="'tertiary'">
            Auf Werkseinstellungen zurücksetzen
          </app-button>
        </app-section-header-actions>
      </app-section-header>
      <span>
        Für Schriftgröße und Schriftfarbe werden die Farben aus den
        Einstellungen im Bereich Corporate Design übernommen. Sie können die
        Hintergrundfarbe des Footers hier selbst bestimmen. Achten Sie auf
        ausreichend Kontrast zwischen Schrift- und Hintergrundfarbe.
      </span>
      <div class="flex w-full items-center justify-end gap-5">
        <app-color-picker-input
          formControlName="backgroundColor"
          label="Hintergrundfarbe"
          id="backgroundColor" />
        <app-button-group
          id="primaryBorderColorButtonGroup"
          [options]="footerBorderColors | enumToButtonOptionArray"
          [formControl]="backgroundColorBtn"
          (valueChange)="onBackgroundColorChange($event)" />
      </div>
    </app-section>

    <app-section
      formGroupName="logoArea"
      [disabled]="!footerForm.controls.logoArea.controls.toggle.value">
      <app-section-header>
        <app-section-headline> Block 1 - Bilder </app-section-headline>
        <app-section-header-actions>
          <app-toggle
            formControlName="toggle"
            label="Aktiv setzen"
            id="imageToggle">
            <div>Here be Errors for Toggle</div>
          </app-toggle>
        </app-section-header-actions>
      </app-section-header>

      <app-translation-text-input
        formControlName="headlines"
        label="Titel (optional)"
        id="imageTitle">
        <div>Here be Errors for Text Input</div>
      </app-translation-text-input>
      @for (
        logoAreaForm of footerForm.controls.logoArea.controls.accessibleImages
          .controls;
        track $index;
        let index = $index
      ) {
        <div class="flex items-center gap-5">
          <div class="flex w-full gap-5">
            <app-image-select
              class="w-full"
              [id]="'logoImage' + index"
              [formControl]="logoAreaForm.controls.image"
              label="Bild" />
            <app-translation-accessibility-text-input
              class="w-full"
              [id]="'logoText' + index"
              [formControl]="logoAreaForm.controls.imageAlts"
              label="Beschreibung" />
            <app-text-input
              class="w-full"
              [id]="'logoAddress' + index"
              [formControl]="logoAreaForm.controls.url"
              label="Link-Adresse (optional)" />
          </div>
          <app-button
            [type]="'button'"
            (click)="deleteLogo(index)"
            [style]="'error'"
            class="w-12">
            <span class="material-symbols-rounded"> delete </span>
          </app-button>
        </div>
      }
      <app-button
        [type]="'button'"
        (click)="addLogo()"
        [style]="'secondary'"
        class="w-full">
        <span class="material-symbols-rounded"> add </span> Neuen Eintrag
        hinzufügen
      </app-button>
    </app-section>

    <app-section formGroupName="addressArea">
      <app-section-header>
        <app-section-headline> Block 2 - Freitext </app-section-headline>
        <app-section-header-actions>
          <app-toggle
            formControlName="toggle"
            label="Aktiv setzen"
            id="textToggle"
            ><div>Here be Errors for Toggle</div></app-toggle
          ></app-section-header-actions
        >
      </app-section-header>
      <app-translation-text-input
        formControlName="headlines"
        label="Titel (optional)"
        id="textTitle">
        <div>Here be Errors for Text Input</div>
      </app-translation-text-input>
      <app-translation-text-area
        formControlName="texts"
        label="Freitext"
        id="textAreaInput">
        <div>Here be Errors for TextArea</div>
      </app-translation-text-area>
    </app-section>

    <app-section formGroupName="paymentArea">
      <app-section-header>
        <app-section-headline> Block 3 - Zahlungsarten </app-section-headline>
        <app-section-header-actions>
          <app-toggle
            formControlName="toggle"
            label="Aktiv setzen"
            id="paymentToggle"
            ><div>Here be Errors for Toggle</div></app-toggle
          ></app-section-header-actions
        >
      </app-section-header>
      <app-translation-text-input
        formControlName="headlines"
        label="Titel (optional)"
        id="paymentTitle">
        <div>Here be Errors for Text Input</div>
      </app-translation-text-input>
      @for (
        paymentAreaForm of footerForm.controls.paymentArea.controls
          .accessibleImages.controls;
        track $index;
        let index = $index
      ) {
        <div class="flex items-center gap-5">
          <div class="flex w-full gap-5">
            <app-payment-select
              class="w-full"
              [id]="'paymentImage' + index"
              [formControl]="paymentAreaForm.controls.image"
              label="Bild" />
            <app-translation-accessibility-text-input
              class="w-full"
              [id]="'paymentAlt' + index"
              [formControl]="paymentAreaForm.controls.imageAlts"
              label="Beschreibung" />
            <app-text-input
              class="w-full"
              [id]="'paymentLink' + index"
              [formControl]="paymentAreaForm.controls.url"
              label="Link-Adresse" />
          </div>
          <app-button
            [type]="'button'"
            (click)="deletePayment(index)"
            [style]="'error'"
            class="w-12">
            <span class="material-symbols-rounded"> delete </span>
          </app-button>
        </div>
      }
      <app-button
        [type]="'button'"
        (click)="addPayment()"
        [style]="'secondary'"
        class="w-full">
        <span class="material-symbols-rounded"> add </span> Neuen Eintrag
        hinzufügen
      </app-button>
    </app-section>

    <app-section formGroupName="linkArea">
      <app-section-header>
        <app-section-headline> Block 4 - Verlinkungen </app-section-headline>
      </app-section-header>
      <app-translation-text-input
        formControlName="headlines"
        label="Titel (optional)"
        id="linkTitle">
        <div>Here be Errors for Text Input</div>
      </app-translation-text-input>
      @for (
        linkAreaForm of footerForm.controls.linkArea.controls.links.controls;
        track $index;
        let index = $index
      ) {
        <div class="flex items-center gap-5">
          <div class="flex w-full gap-5">
            <app-translation-text-input
              class="w-full"
              [id]="'linkText' + index"
              [formControl]="linkAreaForm.controls.texts"
              label="Link-Text" />
            <app-translation-text-input
              class="w-full"
              [id]="'linkAddress' + index"
              [formControl]="linkAreaForm.controls.urls"
              label="Link-Adresse" />
          </div>
          @if (index < 4) {
            <div
              class="flex items-center gap-2 rounded-md bg-gray-200 p-1 text-gray-500">
              <span class="material-symbols-rounded"> lock </span>
              <span>Dieser Link kann nicht entfernt werden.</span>
            </div>
          } @else {
            <app-button
              [type]="'button'"
              (click)="deleteLink(index)"
              [style]="'error'"
              class="w-12">
              <span class="material-symbols-rounded"> delete </span>
            </app-button>
          }
        </div>
      }
      <app-button
        [type]="'button'"
        (click)="addLink()"
        [style]="'secondary'"
        class="w-full">
        <span class="material-symbols-rounded"> add </span> Neuen Eintrag
        hinzufügen
      </app-button>
    </app-section>
  </app-section>
}

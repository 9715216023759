import { Injectable } from '@angular/core';
import { IFont } from '../../../entities/font-entity';
import { FontGateway } from './fontGateway';
import { CRUDService } from '../../crud/crud.service';

@Injectable({
  providedIn: 'root',
})
export class FontService extends CRUDService<
  IFont,
  IFont[],
  never,
  null,
  IFont,
  FormData
> {
  constructor() {
    super(new FontGateway());
  }
}

<div
  class="form-input__background"
  [ngClass]="{
    'form-input__disabled': ngControl.control!.disabled,
  }">
  @let hasError = ngControl.errors && ngControl.touched;
  <div class="form-input__container">
    <input
      [attr.data-testid]="id() | appendTestId: 'input'"
      autocomplete="off"
      [formControl]="ngControl.control! | toFormControl"
      class="form-input peer !pr-10"
      [ngClass]="{ 'form-input__error-ring': hasError }"
      [id]="id()"
      [placeholder]="label() ?? ''"
      (focus)="showList.set(true)"
      type="text" />
    <label
      [attr.data-testid]="id() | appendTestId: 'label'"
      class="form-input__floating-label"
      [ngClass]="{ 'form-input__error-text': hasError }"
      [for]="id()">
      {{ label() }}
    </label>
    @if (ngControl.value !== null && ngControl.value !== undefined) {
      <span
        [attr.data-testid]="id() | appendTestId: 'value'"
        class="pointer-events-none absolute left-1 right-5 top-2 truncate bg-inherit px-1">
        {{
          (ngControl.value
            | SelectionOptionValueToName: options() : name()! : value()) ||
            (name()
              ? (ngControl.value[name()!] | json)
              : (ngControl.value | json))
        }}
      </span>
    }
    @if (!ngControl.control!.disabled) {
      <span
        tabindex="0"
        [id]="id() | appendTestId: 'arrow-down'"
        [attr.data-testid]="id() | appendTestId: 'arrow-down'"
        class="material-symbols-rounded form-input__text pointer-events-none absolute bottom-1 right-2 top-1 h-8 text-2xl">
        keyboard_arrow_down
      </span>
    }
    <ul
      [attr.data-testid]="id() | appendTestId: 'options'"
      [ngClass]="{ hidden: !showList() }"
      class="form-input__dropdown-list">
      @for (option of filteredOptions(); track $index; let index = $index) {
        <li
          [attr.data-testid]="id() + index | appendTestId: 'option'"
          class="form-input__dropdown-list-item form-input__dropdown-list--hover flex cursor-pointer flex-row flex-wrap"
          [ngClass]="{
            'form-input__dropdown-list--selected': selectedItem() === index,
            'rounded-t-lg': index === 0,
            'rounded-b-lg': index === filteredOptions()!.length - 1,
          }"
          (click)="selectOptionAndSetItem(option, index)">
          {{ option | toSelectOptionAttribute: name() }}
        </li>
      }
      @if (filteredOptions()!.length === 0) {
        <li
          class="form-input__dropdown-list-item"
          [attr.data-testid]="id() | appendTestId: 'option'">
          Keine passenden Ergebnisse...
        </li>
      }
    </ul>
    @if (hasError && !errorTemplate()) {
      <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
        <ng-content></ng-content>
      </app-error-message>
    }
    @if (hasError && errorTemplate()) {
      <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
        <ng-container *ngTemplateOutlet="errorTemplate()!"></ng-container>
      </app-error-message>
    }
  </div>
</div>

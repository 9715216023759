import { Component, computed, inject, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigService } from '../../services/config/config.service';
import { ClientService } from '../../services/client/client.service';
import { BannerService } from '../../services/api/banner/banner.service';
import { LanguagePipe } from '../../pipes/language/language.pipe';
import { toSignal } from '@angular/core/rxjs-interop';
import { IBanner } from '../../entities/banner-entity';

@Component({
  selector: 'app-content-header',
  imports: [CommonModule, LanguagePipe],
  templateUrl: './banner.component.html',
  styles: ``,
})
export class BannerComponent {
  private bannerService: BannerService = inject(BannerService);
  private configService = inject(ConfigService);
  private clientService = inject(ClientService);

  private apiUrl = this.configService.getCmsUrl;
  private client = this.clientService.client;

  constructor() {
    this.bannerService.get$.next(null);
  }

  banner: Signal<IBanner> = toSignal(
    this.bannerService.$entity
  ) as Signal<IBanner>;

  bannerPath = computed(() => {
    return this.apiUrl + '/banner/' + this.client + '/banner';
  });
}

import { z, ZodSchema } from 'zod';
import { FormControl } from '@angular/forms';

export const LoginSchema: ZodSchema = z.object({
  id: z.string().uuid().nullable(),
  client: z.string(),
  email: z.string(),
  password: z.string(),
});

export interface ILogin {
  id?: string;
  client: string;
  email: string;
  password: string;
}

export const defaultLogin: ILogin = {
  id: '',
  client: 'bsvg',
  email: '',
  password: '',
};

export interface ILoginForm {
  email: FormControl<string>;
  password: FormControl<string>;
}

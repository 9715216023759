import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { PopupService } from '../../../services/popup/popup.service';
import { HeaderPageComponent } from '../header/header-page.component';

@Injectable({
  providedIn: 'root',
})
export class HeaderFormChangedGuard
  implements CanDeactivate<HeaderPageComponent>
{
  constructor(private popupService: PopupService) {}
  canDeactivate(
    component: HeaderPageComponent
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (component.headerForm.touched) {
      this.popupService.showPopupChanges();
      return false;
    }
    return true;
  }
}

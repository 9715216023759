import { z, ZodSchema } from 'zod';
import { IText, TextSchema } from './text-entity';
import { FormControl } from '@angular/forms';

export const LoginLogoutSchema: ZodSchema = z.object({
  id: z.string().uuid().nullable(),
  client: z.string(),
  loginHeader: z.array(TextSchema),
  email: z.array(TextSchema),
  password: z.array(TextSchema),
  loginButton: z.array(TextSchema),
  separation: z.array(TextSchema),
  register: z.array(TextSchema),
  passwordReset: z.array(TextSchema),
  passwordResetLink: z.array(TextSchema),
  logoutAltText: z.array(TextSchema),
});

export interface ILoginLogout {
  id?: string;
  client: string;
  loginHeader: IText[];
  email: IText[];
  password: IText[];
  loginButton: IText[];
  separation: IText[];
  register: IText[];
  passwordReset: IText[];
  passwordResetLink: IText[];
  logoutAltText: IText[];
}
export interface ILoginLogoutForm {
  id: FormControl<string | null>;
  client: FormControl<string>;
  loginButton: FormControl<IText[]>;
  loginHeader: FormControl<IText[]>;
  email: FormControl<IText[]>;
  password: FormControl<IText[]>;
  separation: FormControl<IText[]>;
  register: FormControl<IText[]>;
  passwordReset: FormControl<IText[]>;
  passwordResetLink: FormControl<IText[]>;
  logoutAltText: FormControl<IText[]>;
}

export const defaultLoginLogout: ILoginLogout = {
  client: '',
  loginButton: [
    {
      value: 'Anmelden',
      isoCode: 'deu',
    },
  ],
  loginHeader: [
    {
      value: 'Mit Kundenkonto anmelden',
      isoCode: 'deu',
    },
  ],
  email: [
    {
      value: 'E-Mail Adresse',
      isoCode: 'deu',
    },
  ],
  password: [
    {
      value: 'Passwort',
      isoCode: 'deu',
    },
  ],
  separation: [
    {
      value: 'oder',
      isoCode: 'deu',
    },
  ],
  register: [
    {
      value: 'Registrieren',
      isoCode: 'deu',
    },
  ],
  passwordReset: [
    {
      value: 'Passwort vergessen?',
      isoCode: 'deu',
    },
  ],
  passwordResetLink: [
    {
      value: 'Passwort zurücksetzen',
      isoCode: 'deu',
    },
  ],
  logoutAltText: [
    {
      value: 'Ausloggen',
      isoCode: 'deu',
    },
  ],
};

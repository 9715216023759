import { Component, OnDestroy, OnInit } from '@angular/core';
import { PopupService } from '../../../services/popup/popup.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reset-to-factory-settings-dialog',
  templateUrl: './reset-toFactorySettings-dialog.component.html',
  standalone: true,
})
export class ResetToFactorySettingsDialogComponent
  implements OnInit, OnDestroy
{
  public isPopupVisible = false;
  private sub = new Subscription();

  constructor(private popupService: PopupService) {}

  ngOnInit(): void {
    this.sub.add(
      this.popupService.showPopupToFactory$.subscribe(show => {
        this.isPopupVisible = show;
      })
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  cancel(): void {
    this.popupService.userActionToFactory(false);
  }

  confirm(): void {
    this.popupService.userActionToFactory(true);
  }
}

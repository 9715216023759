export interface ISubscriptionsRequest {
  guid: string;
  includeFixedPriceTickets: boolean;
  includeInActive: boolean;
}

export interface ISubscription {
  guid: string;
  name: string;
  overviewTextHTML: string;
  configurationId: string;
  aboConfigurationGuid: string;
  requireRegistration: boolean;
  aboType: AboType;
  requireBankDetails: boolean;
  category: IWebshopCategory;
  onlineSalePossibleFrom?: string;
}

export const defaultSubscription = {
  resultCount: 0,
  resultsPerPage: 0,
  page: 0,
  data: [],
};

enum AboType {
  Unknown = 0,
  RelationBoundOnlyStops = 4,
  RelationBoundOnlyTariffPoints = 5,
  RelationBoundOnlyTariffPointsAndStop = 6,
  NonRelationBoundFixPrice = 7,
  RelationBoundNetwork = 8,
  NonRelationshipOrTariffBound = 9,
}

interface IWebshopCategory {
  guid: string;
  name: string;
}

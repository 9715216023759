import { Injectable } from '@angular/core';
import { CRUDService } from '../../crud/crud.service';
import { ILogin } from '../../../entities/login.entity';
import { LoginGateway } from './login.gateway';

@Injectable({
  providedIn: 'root',
})
export class LoginService extends CRUDService<
  ILogin,
  never,
  null,
  never,
  never,
  FormData
> {
  constructor() {
    super(new LoginGateway());
  }

  loginUser(login: ILogin) {
    //TODO not final. Will be reworked in future tickets
    localStorage.setItem('jwtToken', login.email+login.password);
  }

  logout(): void {
    localStorage.removeItem('jwtToken');
  }

  isLoggedIn(): boolean {
    return !!localStorage.getItem('jwtToken');
  }

  getToken(): string | null {
    return localStorage.getItem('jwtToken');
  }
}

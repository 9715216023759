import {
  ChangeDetectorRef,
  Component,
  inject,
  input,
  InputSignal,
  OnDestroy,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { AppendTestIdPipe } from '../../pipes/append-test-id/append-test-id.pipe';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-extendable-text',
  imports: [NgClass, AppendTestIdPipe],
  templateUrl: './extendable-text.component.html',
})
export class ExtendableTextComponent implements OnDestroy {
  public id: InputSignal<string> = input.required();
  public title: InputSignal<string> = input('Details');
  public extended = false;
  public $handleKeyUpEvent!: Subscription;

  private readonly cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  constructor() {
    this.$handleKeyUpEvent = fromEvent<
      KeyboardEvent & { target?: { id: string } }
    >(document, 'keyup').subscribe(event => {
      const isCorrectTarget = event.target?.id === this.id();
      if (event.key === 'Enter' && isCorrectTarget) {
        event.preventDefault();
        this.extended = !this.extended;
        this.cdRef.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    this.$handleKeyUpEvent.unsubscribe();
  }
}

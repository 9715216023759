import { Injectable } from '@angular/core';
import { CRUDService } from '../../crud/crud.service';
import { BannerGateway } from './bannerGateway';
import { IBanner } from '../../../entities/banner-entity';

@Injectable({
  providedIn: 'root',
})
export class BannerService extends CRUDService<
  IBanner,
  never,
  null,
  never,
  never,
  never
> {
  constructor() {
    super(new BannerGateway());
  }
}

import { inject, Injectable } from '@angular/core';
import { Factory } from '../factory';
import { ClientService } from '../../services/client/client.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  defaultBanner,
  IBanner,
  IBannerForm,
} from '../../entities/banner-entity';
import { IAccessibleImageForm } from '../../entities/image-entity';

@Injectable({
  providedIn: 'root',
})
export class BannerFormsFactoryService extends Factory<IBannerForm, IBanner> {
  clientService = inject(ClientService);
  formBuilder = inject(FormBuilder);

  buildForm(banner: IBanner = defaultBanner): FormGroup<IBannerForm> {
    return this.formBuilder.group<IBannerForm>({
      id: this.formBuilder.control(banner.id || null),
      client: this.formBuilder.nonNullable.control(this.clientService.client, [
        Validators.required,
      ]),
      imageActive: this.formBuilder.nonNullable.control(banner.imageActive, [
        Validators.required,
      ]),
      textActive: this.formBuilder.nonNullable.control(
        banner.textActive,
        Validators.required
      ),
      accessibleImage: this.formBuilder.group<IAccessibleImageForm>({
        id: this.formBuilder.control(banner.accessibleImage.id || null),
        image: this.formBuilder.control(banner.accessibleImage.image, [
          Validators.required,
        ]),
        imageAlts: this.formBuilder.nonNullable.control(
          banner.accessibleImage.imageAlts,
          [Validators.required]
        ),
        url: this.formBuilder.control(banner.accessibleImage.url || null),
      }),
      imageHeight: this.formBuilder.nonNullable.control(
        banner.imageHeight,
        Validators.required
      ),
      headlines: this.formBuilder.nonNullable.control(banner.headlines, [
        Validators.required,
      ]),
      headlineFontSize: this.formBuilder.nonNullable.control<number>(
        banner.headlineFontSize,
        [Validators.required]
      ),
      subHeadlines: this.formBuilder.nonNullable.control(banner.subHeadlines, [
        Validators.required,
      ]),
      subHeadlineFontSize: this.formBuilder.nonNullable.control<number>(
        banner.subHeadlineFontSize,
        [Validators.required]
      ),
      textAlignmentHorizontal: this.formBuilder.nonNullable.control(
        banner.textAlignmentHorizontal,
        [Validators.required]
      ),
      textAlignmentVertical: this.formBuilder.nonNullable.control(
        banner.textAlignmentVertical,
        [Validators.required]
      ),
      textFont: this.formBuilder.control(banner.textFont, [
        Validators.required,
      ]),
      textColor: this.formBuilder.nonNullable.control(banner.textColor, [
        Validators.required,
      ]),
    });
  }

  buildEntity(form: FormGroup<IBannerForm>): IBanner {
    const banner = form.getRawValue() as unknown as IBanner;
    this.removeNullIds(banner);

    return banner as unknown as IBanner;
  }
}

<div class="border-1 m-4 rounded-lg border-gray-400 p-4">
  <div
    class="pb-4 text-2xl font-semibold"
    [attr.data-testid]="id() | appendTestId: 'title'">
    Tickets
  </div>
  <div
    [formGroup]="filterForm"
    class="mb-4"
    [attr.data-testid]="id() | appendTestId: 'filter'">
    <app-text-input
      id="ticket-name-filter"
      label="Suche"
      placeholder="Bezeichnung, Titel..."
      formControlName="name"></app-text-input>
    <div class="mt-6 flex flex-row gap-4">
      <app-drop-down
        id="ticket-status-filter"
        label="Aktiv"
        name="name"
        value="value"
        formControlName="isActive"
        [options]="ticketDataOptions"></app-drop-down>
      <app-drop-down
        id="ticket-data-filter"
        label="Ausgefüllt"
        name="name"
        value="value"
        formControlName="hasData"
        [options]="ticketDataOptions"></app-drop-down>
      <app-drop-down
        id="ticket-type-filter"
        label="Ticket-Art"
        name="name"
        value="value"
        formControlName="ticketType"
        [options]="ticketTypes"></app-drop-down>
      <div
        tabindex="0"
        [id]="id() | appendTestId: 'reset-filter'"
        [attr.data-testid]="id() | appendTestId: 'reset-filter'"
        class="flex w-full cursor-pointer flex-row items-center"
        (click)="resetFilter()">
        <span class="material-symbols-rounded"> close </span> Filter
        zurücksetzen
      </div>
    </div>
  </div>
  <app-table
    id="ticket-table"
    [data]="filteredTickets"
    [header]="header"
    [columns]="columns"
    (onPageChange)="filterForm.updateValueAndValidity()">
    <ng-template #header let-sortBy="sortBy">
      <app-table-header
        [sortParams]="{
          attribute: 'status',
          compare: ticketStatusFilter,
        }"
        class="w-1/12 max-w-24 overflow-hidden"
        [attr.data-testid]="id() | appendTestId: 'header-status'"
        [ngClass]="headerColumnClasses"
        (sort)="sortBy($event)">
        Aktiv
      </app-table-header>
      <app-table-header
        [sortParams]="{
          attribute: 'ticketData',
          compare: ticketDataFilter,
        }"
        class="w-2/12 max-w-24 overflow-hidden"
        [attr.data-testid]="id() | appendTestId: 'header-data'"
        [ngClass]="headerColumnClasses"
        (sort)="sortBy($event)">
        Ausgefüllt
      </app-table-header>
      <app-table-header
        [sortParams]="{
          attribute: 'ticketType',
          compare: ticketTypeFilter,
        }"
        class="w-3/12 overflow-hidden text-nowrap"
        [attr.data-testid]="id() | appendTestId: 'header-type'"
        [ngClass]="headerColumnClasses"
        (sort)="sortBy($event)">
        Ticket-Art
      </app-table-header>
      <app-table-header
        [sortParams]="{
          attribute: 'name',
          compare: nameFilter,
        }"
        class="w-full overflow-hidden"
        [attr.data-testid]="id() | appendTestId: 'header-name'"
        [ngClass]="headerColumnClasses"
        (sort)="sortBy($event)">
        Bezeichnung
      </app-table-header>
      <app-table-header
        class="w-2/12 max-w-48 overflow-hidden"
        [attr.data-testid]="id() | appendTestId: 'header-actions'"
        [ngClass]="headerColumnClasses">
        Aktionen
      </app-table-header>
    </ng-template>
    <ng-template #columns let-data="data">
      <div
        [ngClass]="columnClass"
        [attr.data-testid]="id() | appendTestId: 'data-' + data.id"
        class="w-1/12 max-w-24 justify-center">
        @if (!data.isActive) {
          <div class="border-5 h-5 w-5 rounded-3xl border-gray-500"></div>
        } @else {
          <div
            class="border-3 flex h-5 w-5 flex-row items-center justify-center rounded-3xl border-green-700">
            <div class="h-2.5 w-2.5 rounded-3xl bg-green-700"></div>
          </div>
        }
      </div>
      <div
        [ngClass]="columnClass"
        class="w-2/12 max-w-24 justify-center"
        [attr.data-testid]="id() | appendTestId: 'status-' + data.id">
        @if (!!data.data.title) {
          <span class="material-symbols-rounded"> check_circle </span>
        } @else {
          <span class="material-symbols-rounded"> do_not_disturb_on </span>
        }
      </div>
      <div
        class="w-3/12 text-nowrap"
        [attr.data-testid]="id() | appendTestId: 'type-' + data.id"
        [ngClass]="columnClass">
        {{ data.type === TicketType.Direct ? 'Direkt-Ticket' : 'Abonnement' }}
      </div>
      <div
        class="w-full"
        [ngClass]="columnClass"
        [attr.data-testid]="id() | appendTestId: 'name-' + data.id">
        {{ data.name }}
      </div>
      <div
        class="w-2/12 max-w-48"
        [attr.data-testid]="id() | appendTestId: 'actions-' + data.id"
        [ngClass]="columnClass">
        <span
          [attr.data-testid]="id() | appendTestId: 'edit-' + data.id"
          class="material-symbols-rounded"
          [ngClass]="actionIcon"
          [routerLink]="data.id">
          edit
        </span>
        <span
          [attr.data-testid]="id() | appendTestId: 'copy-' + data.id"
          class="material-symbols-rounded"
          [ngClass]="actionIcon"
          [routerLink]="data.id">
          file_copy
        </span>
        <span
          tabindex="0"
          [attr.data-testid]="id() | appendTestId: 'delete-' + data.id"
          class="material-symbols-rounded"
          [ngClass]="actionIcon + ' text-red-700'"
          (click)="openDeleteDialog(data)">
          delete
        </span>
      </div>
    </ng-template>
  </app-table>
</div>

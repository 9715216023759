import { Injectable } from '@angular/core';
import { FooterGateway } from './footerGateway';
import IFooter from '../../../entities/footer-entity';
import { CRUDService } from '../../crud/crud.service';

@Injectable({
  providedIn: 'root',
})
export class FooterService extends CRUDService<
  IFooter,
  never,
  null,
  never,
  never,
  never
> {
  constructor() {
    super(new FooterGateway());
  }
}

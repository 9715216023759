import { z, ZodSchema } from 'zod';
import { IText, TextSchema } from './text-entity';
import { FormControl } from '@angular/forms';

export const AddressAreaSchema: ZodSchema = z.object({
  id: z.string().uuid().nullable(),
  toggle: z.boolean(),
  headlines: z.array(TextSchema),
  texts: z.array(TextSchema),
});

export interface IAddressArea {
  id?: string;
  toggle: boolean;
  headlines: IText[];
  texts: IText[];
}

export interface IAddressAreaForm {
  id: FormControl<string | null>;
  toggle: FormControl<boolean>;
  headlines: FormControl<IText[]>;
  texts: FormControl<IText[]>;
}

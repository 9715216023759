@if (login() && loginForm) {
  <div [formGroup]="languageForm" class="flex w-full justify-end">
    <app-language-switcher
      formControlName="languageSelect"
      label="Single Select"
      id="single-select">
      <div>Here be Errors for the Single Select</div>
    </app-language-switcher>
  </div>
  <app-section [formGroup]="loginForm">
    <app-section-header>
      <app-section-headline> Login/Logout </app-section-headline>
      <app-section-header-actions>
        <app-button
          [type]="'button'"
          [style]="'tertiary'"
          (click)="showPopUpToFactorySettings()">
          Auf Werkseinstellungen zurücksetzen
        </app-button>
        <app-button [style]="'primary'" (click)="save()" [type]="'submit'">
          Speichern
        </app-button>
        <app-button
          [style]="'secondary'"
          [type]="'reset'"
          (click)="showPopUpChanges()">
          Abbrechen
        </app-button>
      </app-section-header-actions>
    </app-section-header>
    <app-section>
      <app-section-header>
        <app-section-headline>Login</app-section-headline>
      </app-section-header>
      <app-translation-text-input
        class="w-full"
        [id]="'loginButton'"
        formControlName="loginButton"
        label="Login Button Text" />
      <app-translation-text-input
        class="w-full"
        [id]="'loginHeader'"
        formControlName="loginHeader"
        label="Login Überschrift" />
      <app-translation-text-input
        class="w-full"
        [id]="'email'"
        formControlName="email"
        label="E-Mail Beschriftung" />
      <app-translation-text-input
        class="w-full"
        [id]="'password'"
        formControlName="password"
        label="Passwort Beschriftung" />
      <app-translation-text-input
        class="w-full"
        [id]="'separation'"
        formControlName="separation"
        label="Trennstrich" />
      <app-translation-text-input
        class="w-full"
        [id]="'registerButtonText'"
        formControlName="register"
        label="Registrieren Bezeichnung" />
      <app-translation-text-input
        class="w-full"
        [id]="'passwordReset'"
        formControlName="passwordReset"
        label="Passwort Reset Bezeichnung" />
      <app-translation-text-input
        class="w-full"
        [id]="'passwordResetLink'"
        formControlName="passwordResetLink"
        label="Passwort Reset Link Bezeichnung" />
    </app-section>
    <app-section>
      <app-section-headline>Logout</app-section-headline>
      <app-translation-text-input
        class="w-full"
        [id]="'logoutAltText'"
        formControlName="logoutAltText"
        label="Logout Alt Text" />
    </app-section>
  </app-section>
}

import { Component, input, InputSignal } from '@angular/core';
import { AppendTestIdPipe } from '../../pipes/append-test-id/append-test-id.pipe';
import { IText } from '../../entities/text-entity';
import { LanguagePipe } from '../../pipes/language/language.pipe';

export interface ISimpleListStyling {
  iconColor: string;
}

export interface ISimpleListItem {
  icon?: string;
  text: IText[];
}

@Component({
  selector: 'app-simple-list',
  imports: [AppendTestIdPipe, LanguagePipe],
  templateUrl: './simple-list.component.html',
})
export class SimpleListComponent {
  public readonly id: InputSignal<string> = input.required();
  public readonly items: InputSignal<ISimpleListItem[]> = input.required();
}

<div
  class="form-input__background"
  [ngClass]="{
    'form-input__disabled': ngControl.control!.disabled,
  }">
  @let hasError = ngControl.errors && ngControl.touched;
  <div class="form-input__container">
    <input
      [attr.data-testid]="id() | appendTestId: 'input'"
      [formControl]="ngControl.control! | toFormControl"
      class="form-input peer"
      [ngClass]="{
        'form-input__error-ring': hasError,
      }"
      [id]="id()"
      [placeholder]="label() ?? ''"
      type="text" />
    <label
      [attr.data-testid]="id() | appendTestId: 'label'"
      class="form-input__floating-label"
      [ngClass]="{
        'form-input__floating-label--static': placeholder(),
        'form-input__floating-label': !placeholder(),
        'form-input__error-text': hasError,
      }"
      [for]="id()">
      {{ label() }}
    </label>
    @if (placeholder() && !ngControl.control!.value) {
      <span
        [attr.data-testid]="id() | appendTestId: 'placeholder'"
        [attr.aria-label]="placeholder()"
        class="form-input__text pointer-events-none absolute bottom-1 left-2 top-2 h-8">
        {{ placeholder() }}
      </span>
    }
    @if (hasError && !errorTemplate()) {
      <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
        <ng-content></ng-content>
      </app-error-message>
    }
    @if (hasError && errorTemplate()) {
      <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
        <ng-container *ngTemplateOutlet="errorTemplate()!"></ng-container>
      </app-error-message>
    }
  </div>
</div>

import { Observable } from 'rxjs';
import { IListGateway } from '../../gateways';
import { RestCallBuilder } from '../../../builder/rest/rest.builder';
import { IWrapper, WrapperSchema } from '../../../entities/sort-groups-entity';
import { ConfigService } from '../../config/config.service';
import { inject } from '@angular/core';

export enum PreSaleValidateSelectionType {
  ListDate = 1,
  ListTime = 2,
  DateTime = 3,
  Date = 4,
  Week = 5,
  Month = 6,
  Year = 7,
}

export interface Stop {
  name: string;
  reference: string;
}

export interface PreSaleValidity {
  selectionType: PreSaleValidateSelectionType;
  earliestDate: string;
  latestDate: string;
  defaultDate: string;
  listDates: string[];
}

export interface ProductGroup {
  name: string;
  id: number;
  defaultPriceInCents: number;
  details: string[];
  previews: string[];
  externalRelationId: string;
  externalSortId: string;
  preSaleValidity: PreSaleValidity;
}

export interface ProductGroupResponse {
  destination?: Stop;
  origin?: Stop;
  via?: string;
  productGroups: ProductGroup[];
}

export class ProductGroupsGateway
  implements IListGateway<never, IWrapper<ProductGroupResponse>>
{
  private configService: ConfigService = inject(ConfigService);
  private restCallBuilder: RestCallBuilder<IWrapper<ProductGroupResponse>> =
    new RestCallBuilder<IWrapper<ProductGroupResponse>>(
      {} as IWrapper<ProductGroupResponse>
    );
  private apiUrl: string = this.configService.externalApi;

  list(): Observable<IWrapper<ProductGroupResponse>> {
    return this.restCallBuilder
      .getExternal(`${this.apiUrl}api/v3/tariff/productGroups/fixPrice`, {
        serviceArea: this.configService.serviceArea,
      })
      .handleErrors()
      .validate(WrapperSchema)
      .build();
  }
}

import { z, ZodSchema } from 'zod';

export interface ISortGroups {
  sortGroupNames: string[];
}

export const defaultSortGroups = {
  resultCount: 0,
  resultsPerPage: 0,
  page: 0,
  data: [],
};

export const WrapperSchema: ZodSchema = z.object({
  resultCount: z.number().optional(),
  resultsPerPage: z.number().optional(),
  page: z.number().optional(),
  data: z.array(z.object({})).optional(),
});

export interface IWrapper<T> {
  resultCount: number;
  resultsPerPage: number;
  page: number;
  data: T[];
}

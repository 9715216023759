import { Component, inject, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { IImage } from '../../../entities/image-entity';
import { ImageService } from '../../../services/api/image/image.service';
import { TextInputComponent } from '../../../components/inputs/textInput/text-input.component';
import { ButtonComponent } from '../../../components/button/button.component';
import { ConfigService } from '../../../services/config/config.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';
import { SectionComponent } from '../../../components/section/section.component';
import { SectionHeaderActionsComponent } from '../../../components/section/section-header/section-header-actions/section-header-actions.component';
import { SectionHeaderComponent } from '../../../components/section/section-header/section-header.component';
import { SectionHeadlineComponent } from '../../../components/section/section-header/section-headline/section-headline.component';
import { DropZoneComponent } from '../../../components/drop-zone/drop-zone.component';

@Component({
  selector: 'app-images-page',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TextInputComponent,
    ButtonComponent,
    SectionComponent,
    SectionHeaderActionsComponent,
    SectionHeaderComponent,
    SectionHeadlineComponent,
    DropZoneComponent,
  ],
  templateUrl: './image-page.component.html',
  styles: ``,
})
export class ImagePageComponent {
  imagesService = inject(ImageService);
  private configService: ConfigService = inject(ConfigService);
  public imageForm = new FormGroup({
    images: new FormControl<File[]>([]),
  });

  images: Signal<IImage[]> = toSignal(this.imagesService.$entity) as Signal<
    IImage[]
  >;
  savedImages: Signal<IImage[]> = toSignal(
    this.imagesService.$savedEntity.pipe(
      tap(() => this.imagesService.get$.next(null))
    )
  ) as Signal<IImage[]>;
  uploadImages: Signal<IImage[]> = toSignal(
    this.imagesService.$uploadedFile.pipe(
      tap(() => this.imagesService.get$.next(null))
    )
  ) as Signal<IImage[]>;
  deleteImages: Signal<IImage[]> = toSignal(
    this.imagesService.$deletedEntity.pipe(
      tap(() => this.imagesService.get$.next(null))
    )
  ) as Signal<IImage[]>;

  private apiUrl = this.configService.getCmsUrl;

  filename = new FormControl();

  constructor() {
    this.imagesService.get$.next(null);
  }

  save(): void {
    const files = this.imageForm.controls.images.value;
    if (files && files.length > 0) {
      const formData = new FormData();

      for (const file of files) {
        formData.append('newImages', file, file.name);
      }
      formData.append('images', JSON.stringify(this.images()));

      this.imageForm.controls.images.setValue([]);
      this.filename.reset();

      this.imagesService.upload$.next(formData);
    } else {
      this.imagesService.save$.next(this.images());
    }
  }

  deleteImage(image: IImage) {
    if (image.id) this.imagesService.delete$.next(image);
  }

  onImageNameChange(value: string, image: IImage) {
    const img = this.images().find(img => image.id === img.id);
    if (img) {
      img.filename = value;
    }
  }

  reload() {
    window.location.reload();
  }

  getThumbnailPath(id: string | undefined) {
    return this.apiUrl + '/images/' + id + '/thumbnail';
  }
}

<div
  class="flex w-full cursor-pointer flex-col items-start justify-between rounded-2xl outline"
  [style.outline-color]="mainColor"
  style="outline-offset: 3px; outline-width: 3px"
  (click)="toggleText()">
  <div
    class="flex w-full items-center justify-between p-2"
    [ngClass]="{ 'rounded-2xl': !showText, 'rounded-t-2xl': showText }"
    [style.background-color]="mainColor"
    [style.color]="mainColor">
    <div class="flex items-center gap-2">
      <span
        [style.color]="textColorTop"
        class="material-symbols-rounded text-2xl">
        accessibility_new
      </span>
      <span
        [style.color]="textColorTop"
        class="text-standard-text-primary text-2xl"
        style="color: white">
        Barrierefreiheit
      </span>
    </div>
    <span
      [style.color]="textColorTop"
      class="material-symbols-rounded text-base"
      style="color: white">
      {{ showText ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
    </span>
  </div>
  <div
    [style.color]="textColorBottom"
    *ngIf="showText"
    class="w-full rounded-b-2xl p-4">
    <p>
      Das Icon weist auf wichtige Barrierefreiheitsanforderungen hin, um Inhalte
      für alle zugänglich zu machen:
    </p>
    <ul class="list-disc pl-5">
      <li>Formulieren Sie aussagekräftig, klar und verständlich.</li>
      <li>
        Bildbeschreibungen müssen so formuliert sein, dass mithilfe von
        Assistenz-Werkzeugen alle relevanten Informationen zugänglich sind.
      </li>
      <li>
        Bilder mit Verlinkungen haben keine konkrete Bildbeschreibung, erklären
        Sie stattdessen wohin der Bild-Link führt.
      </li>
      <li>
        Bei rein dekorativen Bildern sollte die Beschreibung leer gelassen
        werden. Das gilt auch, wenn relevante Informationen aus dem Bild in
        einem beistehenden Text wiederholt werden (Das Bild ist somit
        dekorativ.)
      </li>
    </ul>
  </div>
</div>

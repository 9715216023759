import { Component, inject, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NgControl, ReactiveFormsModule } from '@angular/forms';
import { IFont } from '../../../entities/font-entity';
import { FontService } from '../../../services/api/font/font.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormInputComponent } from '../form-input.component';
import { ToFormControlPipe } from '../../../pipes/to-form-control/to-form-control.pipe';
import { DropDownComponent } from '../drop-down/drop-down.component';

@Component({
  selector: 'app-font-select',
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ToFormControlPipe,
    DropDownComponent,
  ],
  templateUrl: './font-select.component.html',
  styles: ``,
})
export class FontSelectComponent extends FormInputComponent {
  fontsService = inject(FontService);
  fonts: Signal<IFont[]> = toSignal(this.fontsService.$entities) as Signal<
    IFont[]
  >;

  constructor(public override ngControl: NgControl) {
    super(ngControl);
    this.fontsService.list$.next(null);
  }
}

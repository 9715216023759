import { Component, effect, inject, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigService } from '../../services/config/config.service';
import { CorporateDesignService } from '../../services/api/corporateDesign/corporate-design.service';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from '../../components/footer/footer.component';
import { HeaderComponent } from '../../components/header/header.component';
import { IFont } from '../../entities/font-entity';
import { FontService } from '../../services/api/font/font.service';
import { ICorporateDesign } from '../../entities/coporate-design-entity';
import { toSignal } from '@angular/core/rxjs-interop';
import { ClientService } from '../../services/client/client.service';
import { LanguagePipe } from '../../pipes/language/language.pipe';

@Component({
  selector: 'app-shop',
  imports: [CommonModule, RouterOutlet, HeaderComponent, FooterComponent],
  templateUrl: './shop-page.component.html',
  styles: ``,
})
export class ShopPageComponent {
  private languagePipe = inject(LanguagePipe);
  private configService = inject(ConfigService);
  private fontsService = inject(FontService);
  private clientService: ClientService = inject(ClientService);
  private corporateDesignService: CorporateDesignService = inject(
    CorporateDesignService
  );

  private apiUrl = this.configService.getCmsUrl;
  private client: string = this.clientService.client;

  corporateDesign: Signal<ICorporateDesign> = toSignal(
    this.corporateDesignService.$entity
  ) as Signal<ICorporateDesign>;
  fonts: Signal<IFont[]> = toSignal(this.fontsService.$entities) as Signal<
    IFont[]
  >;

  constructor() {
    this.corporateDesignService.get$.next(null);
    this.fontsService.list$.next(null);
    effect(() => {
      if (this.fonts()) {
        this.fonts()
          .filter(font => font.name !== '')
          .forEach(font => this.loadCustomFont(font));
      }
    });
    effect(() => {
      if (this.corporateDesign() && this.corporateDesign().font?.name !== '') {
        this.loadStandardFont(this.corporateDesign());
      }
    });
    effect(() => {
      if (
        this.corporateDesign() &&
        this.corporateDesign().faviconFilename &&
        this.corporateDesign().faviconFilename !== ''
      )
        this.loadFavicon();
    });
    effect(() => {
      if (
        this.corporateDesign() &&
        this.corporateDesign().tabs &&
        this.corporateDesign().tabs.length > 0
      )
        this.loadTab(this.corporateDesign());
    });
  }

  loadCustomFont(font: IFont): void {
    const fontUrl = `${this.apiUrl}/fonts/${font.name}/font`;
    const style = document.createElement('style');
    style.innerHTML = `
    @font-face {
      font-family: '${font.name}';
      src: url('${fontUrl}') format('truetype'); /* Adjust format if needed */
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }
  `;
    document.head.appendChild(style);
  }

  loadStandardFont(corporateDesign: ICorporateDesign): void {
    const style = document.createElement('style');
    style.innerHTML = `
       body {
      font-family: '${corporateDesign.font?.name}', sans-serif;
      font-size: ${corporateDesign.fontSizeText}px;
    }
  `;
    document.head.appendChild(style);
  }

  loadFavicon(): void {
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = this.apiUrl + '/corporate-design/' + this.client + '/favicon';
    link.type = 'image/x-icon';
    document.head.appendChild(link);
  }

  loadTab(corporateDesign: ICorporateDesign): void {
    const title = document.head.querySelector('title');
    if (title) {
      title.innerHTML = this.languagePipe.transform(corporateDesign.tabs);
    }
  }
}

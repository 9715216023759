@if (header() && corporateDesign() && login()) {
  <div
    class="flex min-h-10 items-center gap-2 p-2"
    [style.background-color]="
      header().toggle
        ? header().backgroundColor
        : corporateDesign().secondaryColor
    "
    [style.color]="
      header().toggle ? header().fontColor : corporateDesign().primaryColor
    "
    [style.font-size]="header().headlineFontSize + 'pt'">
    <div class="hidden flex-grow-0 md:flex">
      <img
        [src]="leftPath()"
        [alt]="header().leftImage.imageAlts | language"
        class="max-h-10" />
    </div>
    <div class="flex flex-grow justify-between">
      <div class="flex items-center gap-2">
        <img
          [src]="logoPath()"
          [alt]="header().logo.imageAlts | language"
          class="max-h-10" />
        <span
          class="hidden md:block"
          [attr.aria-label]="header().headlines | language">
          {{ header().headlines | language | template: { name: 'Amon' } }}</span
        >
      </div>
      <div class="flex items-center">
        @if (!this.loginService.isLoggedIn()) {
          <a
            [routerLink]="'/login'"
            [style.color]="corporateDesign().primaryFontColor"
            [style.background-color]="corporateDesign().primaryColor"
            [style.border-radius]="'0.5rem'">
            <app-button [style]="'custom'" [type]="'button'">
              <span
                class="material-symbols-rounded"
                [style.fontSize]="header().headlineFontSize + 'pt'"
                [style.lineHeight]="'1'">
                person
              </span>
              <span
                [style.fontSize]="header().headlineFontSize + 'pt'"
                class="leading-none"
                [attr.aria-label]="login().loginButton | language">
                {{ login().loginButton | language }}
              </span>
            </app-button>
          </a>
        } @else {
          <app-button
            [style.background-color]="corporateDesign().primaryColor"
            [style.border-radius]="'0.5rem'"
            [style.color]="corporateDesign().primaryFontColor"
            [style]="'custom'"
            [type]="'button'"
            (click)="this.loginService.logout()">
            <span
              class="material-symbols-rounded"
              [style.fontSize]="header().headlineFontSize + 'pt'"
              [style.lineHeight]="'1'"
              [attr.aria-label]="'Logout'">
              logout
            </span>
          </app-button>
        }
      </div>
    </div>
    <div class="hidden flex-grow-0 md:flex">
      <img
        [src]="rightPath()"
        [alt]="header().rightImage.imageAlts | language"
        class="max-h-10" />
    </div>
  </div>
}

<div class="flex">
  @for (option of options(); track option; let index = $index) {
    <button
      type="button"
      [value]="option"
      [disabled]="ngControl.control!.disabled"
      [ngClass]="{
        'pointer-events-none border-gray-400 bg-gray-200':
          ngControl.control!.disabled,
        'rounded-l-lg': index === 0,
        'rounded-r-lg': index === options().length - 1,
        'active-btn': ngControl.control!.value === option.value && !darkMode(),
        'button-dark': ngControl.control!.value !== option.value && darkMode(),
        'active-dark': ngControl.control!.value === option.value && darkMode(),
      }"
      (click)="changeActive(option)"
      class="btn-base w-52 px-4 py-2 text-base text-black hover:bg-blue-100">
      <div class="flex items-center justify-center">
        @if (ngControl.control!.value === option.value) {
          <span class="material-symbols-rounded"> check </span>
        }
        {{ option.label }}
      </div>
      <ng-content></ng-content>
    </button>
  }
</div>

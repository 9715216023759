@if (header()) {
  <div [formGroup]="languageForm" class="flex w-full justify-end">
    <app-language-switcher
      formControlName="languageSelect"
      label="Single Select"
      id="single-select">
      <div>Here be Errors for the Single Select</div>
    </app-language-switcher>
  </div>

  <app-section [formGroup]="headerForm">
    <app-section-header>
      <app-section-headline> Header </app-section-headline>
      <app-section-header-actions>
        <app-button
          [style]="'primary'"
          (click)="save()"
          [type]="'submit'"
          [disabled]="!headerForm.valid"
          [text]="'Speichern'"></app-button>
        <app-button
          [style]="'secondary'"
          [type]="'reset'"
          [text]="'Abbrechen'"></app-button>
      </app-section-header-actions>
    </app-section-header>

    <!-- Accessibility Hint-->
    <app-accessibility-banner />

    <app-section>
      <app-section-header>
        <app-section-headline> Farben </app-section-headline>
        <app-section-header-actions>
          <app-toggle
            formControlName="toggle"
            label="Eigene Farben nutzen"
            id="imageToggle">
            <div>Here be Errors for Toggle</div>
          </app-toggle>
        </app-section-header-actions>
      </app-section-header>
      <span>
        Grundsätzlich werden die Farben aus den Einstellungen im Bereich
        Corporate Design übernommen. Sie können die Farben hier überschreiben.
        Achten Sie auf ausreichend Kontrast zwischen Vorder- und
        Hintergrundfarbe.
      </span>
      <div class="flex w-full items-center justify-end gap-5">
        <app-color-picker-input
          formControlName="fontColor"
          label="Schriftfarbe"
          id="fontColor" />
        <app-button-group
          id="fontColorButtonGroup"
          [options]="fontColors | enumToButtonOptionArray"
          [formControl]="fontColorBtn"
          (valueChange)="onFontColorChange($event)" />
      </div>
      <div class="flex w-full items-center justify-end gap-5">
        <app-color-picker-input
          formControlName="backgroundColor"
          label="Hintergrundfarbe"
          id="backgroundColor" />
        <app-button-group
          id="backgroundColorButtonGroup"
          [options]="fontColors | enumToButtonOptionArray"
          [formControl]="backgroundColorBtn"
          (valueChange)="onBackgroundColorChange($event)" />
      </div>
    </app-section>

    <app-section>
      <app-section-header>
        <app-section-headline> Startseite - Button </app-section-headline>
      </app-section-header>
      <span>
        Im Header kann man über das hier angegebene Bild, als Button, zur
        Startseite gelangen.
      </span>
      <div class="flex w-full items-center justify-end gap-5">
        <app-translation-text-input
          formControlName="headlines"
          label="Titel (optional)"
          id="headlines">
          <div>Here be Errors for Text Input</div>
        </app-translation-text-input>
      </div>
      <div class="flex w-full items-center justify-end gap-5">
        <div class="flex w-full gap-5">
          <app-image-select
            class="w-full"
            [id]="'logoImage'"
            [formControl]="headerForm.controls.logo.controls.image"
            label="Bild" />
          <app-translation-accessibility-text-input
            class="w-full"
            [id]="'logoText'"
            [formControl]="headerForm.controls.logo.controls.imageAlts"
            label="Beschreibung" />
        </div>
      </div>
      <div class="flex w-full items-center justify-end gap-5">
        <app-number-input
          [formControl]="headerForm.controls.headlineFontSize"
          label="Schriftgröße (in der Einheit pt)"
          id="headlineFontSize" />
        <app-button-group
          id="headlineFontSizeButtonGroup"
          [options]="fontSizes | enumToButtonOptionArray"
          [formControl]="headlineFontSizeBtn"
          (valueChange)="onHeadlinesFontSizeChange($event)" />
      </div>
    </app-section>

    <app-section>
      <app-section-header>
        <app-section-headline> Weitere Bilder </app-section-headline>
      </app-section-header>
      <span>
        Fügen Sie Bilder am linken oder rechten Rand des Headers hinzu. Diese
        Bilder werden in der mobilen Ansicht nicht angezeigt.
      </span>
      <div class="flex w-full items-center justify-end gap-5">
        <div class="flex w-full gap-5">
          <app-image-select
            class="w-full"
            [id]="'ImageLeft'"
            [formControl]="headerForm.controls.leftImage.controls.image"
            label="Bild links" />
          <app-translation-accessibility-text-input
            class="w-full"
            [id]="'logoText'"
            [formControl]="headerForm.controls.leftImage.controls.imageAlts"
            label="Beschreibung" />
          <app-button
            [type]="'button'"
            (click)="deleteImageLeft()"
            [style]="'error'"
            class="w-12">
            <span class="material-symbols-rounded"> mop </span>
          </app-button>
        </div>
      </div>
      <div class="flex w-full items-center justify-end gap-5">
        <div class="flex w-full gap-5">
          <app-image-select
            class="w-full"
            [id]="'ImageRight'"
            [formControl]="headerForm.controls.rightImage.controls.image"
            label="Bild rechts" />
          <app-translation-accessibility-text-input
            class="w-full"
            [id]="'logoText'"
            [formControl]="headerForm.controls.rightImage.controls.imageAlts"
            label="Beschreibung" />
          <app-button
            [type]="'button'"
            (click)="deleteImageRight()"
            [style]="'error'"
            class="w-12">
            <span class="material-symbols-rounded"> mop </span>
          </app-button>
        </div>
      </div>
    </app-section>
  </app-section>
}

import { Injectable } from '@angular/core';
import { IWrapper } from '../../../entities/sort-groups-entity';
import {
  ISubscription,
  ISubscriptionsRequest,
} from '../../../entities/subscription-entity';
import { SubscriptionsGateway } from './subscriptionsGateway';
import { CRUDService } from '../../crud/crud.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService extends CRUDService<
  ISubscription,
  IWrapper<ISubscription>,
  never,
  ISubscriptionsRequest,
  never,
  never
> {
  constructor() {
    super(new SubscriptionsGateway());
  }
}

import { Injectable } from '@angular/core';
import { CRUDService } from '../../crud/crud.service';
import { ILoginLogout } from '../../../entities/login-logout.entity';
import { LoginLogoutGateway } from './login-logout.gateway';

@Injectable({
  providedIn: 'root',
})
export class LoginLogoutService extends CRUDService<
  ILoginLogout,
  never,
  null,
  never,
  never,
  never
> {
  constructor() {
    super(new LoginLogoutGateway());
  }
}

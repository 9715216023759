import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorMessageComponent } from '../../error-message/error-message.component';
import {
  ControlContainer,
  FormGroupDirective,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  FormInputSelectComponent,
  ObjectOption,
  SelectOption,
} from '../form-input-select.component';
import { ToSelectOptionAttributePipe } from '../../../pipes/to-select-option-attribute/to-select-option-attribute.pipe';
import { AppendTestIdPipe } from '../../../pipes/append-test-id/append-test-id.pipe';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-multi-select',
  imports: [
    CommonModule,
    ErrorMessageComponent,
    ReactiveFormsModule,
    ToSelectOptionAttributePipe,
    AppendTestIdPipe,
  ],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  styleUrl: '../form-inputs.css',
  templateUrl: './multi-select.component.html',
})
export class MultiSelectComponent
  extends FormInputSelectComponent
  implements OnDestroy
{
  private readonly $handleKeyUpEvent: Subscription;

  constructor(public override ngControl: NgControl) {
    super(ngControl);
    this.$handleKeyUpEvent = fromEvent<
      KeyboardEvent & { target?: { id: string; value: SelectOption } }
    >(document, 'keyup').subscribe(event => {
      const index: number = +event.target?.id?.split('-')[2];
      const isCorrectTarget = event.target?.id === this.id() + '-' + index;
      if (event.key === 'Enter' && isCorrectTarget) {
        event.preventDefault();
        this.selectOption(this.options()[index]);
      }
    });
  }

  public override selectOption(option: SelectOption): void {
    if (!this.ngControl.control!.disabled) {
      const findValue = (option: SelectOption, value?: string) => {
        return option && value && (option as ObjectOption)[value]
          ? (option as ObjectOption)[value]
          : option;
      };

      const value: SelectOption[] = this.ngControl.control?.value ?? [];
      const valueToAdd = findValue(option, this.value());
      const newValue = value.includes(valueToAdd)
        ? value.filter(v => v != valueToAdd)
        : value.concat([valueToAdd]);

      if (this.ngControl.control) {
        this.ngControl.control!.setValue(newValue);
        this.ngControl.control!.markAsTouched();
      }
    }
  }

  ngOnDestroy() {
    this.$handleKeyUpEvent.unsubscribe();
  }
}

<aside
  [ngClass]="{ 'w-64': isExpanded, 'w-20': !isExpanded }"
  class="transition-width duration-50 border-r-1 relative flex h-screen flex-col overflow-auto border-gray-200 ease-in-out">
  <div
    [ngClass]="{ 'flex-col': !isExpanded, 'flex-row': isExpanded }"
    class="flex">
    @if (isExpanded) {
      <img
        class="h-1/8 ml-4 mt-4 w-1/2"
        alt="Test"
        src="images/amcon_logo.png" />
    }

    @if (!isExpanded) {
      <button
        (click)="toggleSidebar()"
        class="ml-3 mt-2 h-12 w-12 p-2 focus:outline-none"
        type="button">
        <span class="text-standard-icon material-symbols-rounded text-2xl">
          {{ showCollapseIcon() }}
        </span>
      </button>
    }

    <span
      [ngClass]="{
        'bg-yellow-400 text-black': stage === 'TEST',
        'bg-blue-400': stage === 'RC',
        'bg-red-600': stage === 'PROD',
        'bg-blue-100': stage === 'DEV',
        'ml-2': isExpanded,
        'mx-3 mt-2': !isExpanded,
      }"
      class="border-1 mt-6 rounded-xl px-1 py-1 text-center text-white">
      {{ stage }}
    </span>

    @if (isExpanded) {
      <button
        (click)="toggleSidebar()"
        class="absolute right-0 mt-4 h-12 w-12 p-2 focus:outline-none"
        type="button">
        <span class="text-standard-icon material-symbols-rounded text-2xl">
          {{ showCollapseIcon() }}
        </span>
      </button>
    }
  </div>

  <nav
    [ngClass]="{
      'mt-sidebarImageSeparation': !isExpanded,
      'mt-16': isExpanded,
    }"
    class="ml-4 flex-1"
    role="navigation">
    <ul class="flex h-full flex-col">
      <li
        *ngFor="let menuTab of sidebarItems.tabs; let index = index"
        [ngClass]="{
          'mb-5 mt-auto': isLastItem(index),
          'mb-8': !isLastItem(index),
        }">
        <app-menutab [expanded]="isExpanded" [menutab]="menuTab"></app-menutab>
      </li>
    </ul>
  </nav>
</aside>

import { z, ZodSchema } from 'zod';

export const PaymentProviderSchema: ZodSchema = z.object({
  id: z.string().uuid().nullable(),
  filename: z.string(),
  fileExtension: z.string(),
});
export interface IPaymentProvider {
  id?: string;
  filename: string;
  fileExtension: string;
}
export const defaultPaymentProvider = { filename: '', fileExtension: '' };

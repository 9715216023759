import { Observable, of } from 'rxjs';
import { IListGateway } from '../../gateways';
import { RestCallBuilder } from '../../../builder/rest/rest.builder';
import { IWrapper, WrapperSchema } from '../../../entities/sort-groups-entity';
import {
  defaultSubscription,
  ISubscription,
  ISubscriptionsRequest,
} from '../../../entities/subscription-entity';
import { ConfigService } from '../../config/config.service';
import { inject } from '@angular/core';

export class SubscriptionsGateway
  implements IListGateway<ISubscriptionsRequest, IWrapper<ISubscription>>
{
  private configService: ConfigService = inject(ConfigService);
  private restCallBuilder: RestCallBuilder<IWrapper<ISubscription>> =
    new RestCallBuilder<IWrapper<ISubscription>>(defaultSubscription);
  private apiUrl: string = this.configService.externalApi;

  list(query: ISubscriptionsRequest): Observable<IWrapper<ISubscription>> {
    return this.restCallBuilder
      .getExternal(
        `${this.apiUrl}api/v1/subscription/subscriptions/${query.guid}`,
        {
          includeFixedpriceticket: query.includeFixedPriceTickets,
          includeInActive: query.includeInActive,
        }
      )
      .validate(WrapperSchema)
      .handleErrors()
      .build();
  }
}

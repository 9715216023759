<div
  [style.background-color]="'#BA1A1A'"
  class="relative -mx-6 px-6 py-1 text-base text-white"
  [attr.aria-label]="viaAriaLabel">
  @for (viaStation of via(); track $index; let index = $index) {
    <span>{{ viaStation + (index < via().length - 1 ? ' - ' : '') }}</span>
  }
  <div
    class="absolute top-0 h-full border-l-2"
    [style.border-color]="'#BA1A1A'"
    [style.border-left-width]="bannerOverflow()"
    [style.left]="' calc( -1 * (' + bannerOverflow() + ' - 0px) )'"></div>
  <div
    class="absolute top-0 h-full border-l-2"
    [style.border-color]="'#BA1A1A'"
    [style.border-right-width]="bannerOverflow()"
    [style.right]="'-' + bannerOverflow()"></div>
</div>

<div
  class="form-input__background"
  [ngClass]="{
    'form-input__disabled': ngControl.control!.disabled,
  }">
  @let hasError = ngControl.errors && ngControl.touched;
  <div class="form-input__container">
    <input
      [attr.data-testid]="id() | appendTestId: 'input'"
      [id]="id()"
      type="file"
      class="form-input peer absolute -left-0 opacity-0"
      [ngClass]="{
        'form-input__error-ring': hasError,
      }"
      [formControl]="ngControl.control! | toFormControl"
      (change)="selectFilesEvent($event)" />
    <label
      [ngClass]="{
        'form-input__error-ring form-input__error-text': hasError,
      }"
      [for]="id()"
      class="peer flex h-10 cursor-pointer items-center justify-between rounded-lg bg-inherit px-2 placeholder-transparent ring-2 ring-gray-500 focus:outline-none peer-focus-visible:ring-blue-600">
      <span class="material-symbols-rounded !w-[32px] text-2xl text-gray-400">
        cloud_upload
      </span>
      <span
        class="form-input__floating-label"
        [ngClass]="{
          'form-input__error-text': hasError,
          'form-input__disabled-background-color': ngControl.control!.disabled,
        }"
        [attr.data-testid]="id() | appendTestId: 'label'"
        >{{ label() }}</span
      >
      <span class="flex w-full flex-row items-center">
        @if (ngControl.control!.value) {
          <span
            [ngClass]="{
              'form-input__disabled-text': ngControl.control!.disabled,
            }"
            class="form-input__focus-text underline"
            [attr.data-testid]="id() | appendTestId: 'value'"
            >{{ ngControl.control!.value.length }} Dateien ausgewählt.</span
          >
          <span
            class="material-symbols-rounded z-10 ml-4 cursor-pointer text-xl"
            [ngClass]="{
              'pointer-events-none cursor-not-allowed':
                ngControl.control!.disabled,
            }"
            [attr.data-testid]="id() | appendTestId: 'clear'"
            (click)="ngControl.control!.setValue(undefined)">
            close
          </span>
        } @else {
          <span
            [ngClass]="{
              'form-input__disabled-text': ngControl.control!.disabled,
            }"
            [attr.data-testid]="id() | appendTestId: 'upload'"
            class="form-input__focus-text underline"
            >Auswählen</span
          >
        }
      </span>
    </label>

    @if (hasError && !errorTemplate()) {
      <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
        <ng-content></ng-content>
      </app-error-message>
    }
    @if (hasError && errorTemplate()) {
      <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
        <ng-container *ngTemplateOutlet="errorTemplate()!"></ng-container>
      </app-error-message>
    }
  </div>
</div>

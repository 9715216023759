import { ChangeDetectionStrategy, Component, input, InputSignal } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-chip',
  imports: [CommonModule],
  templateUrl: './chip.component.html',
  styles: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipComponent {
  public readonly id: InputSignal<string> = input.required();
  public readonly text: InputSignal<string> = input.required();
}

import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import * as Sentry from '@sentry/angular';
import { enableProdMode } from '@angular/core';

Sentry.init({
  dsn: 'https://59dc9af04e09a7101d4fc82a9c431757@sentry.amcon.de/2',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  ignoreErrors: ['NG04002'],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

enableProdMode();

// Bootstrap the Angular application using standalone components
bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));

import {
  Component,
  effect,
  inject,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontService } from '../../../services/api/font/font.service';
import { IFont } from '../../../entities/font-entity';
import { toSignal } from '@angular/core/rxjs-interop';
import { ButtonComponent } from '../../../components/button/button.component';

@Component({
  selector: 'app-fonts-page',
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ButtonComponent],
  templateUrl: './font-page.component.html',
  styles: ``,
})
export class FontPageComponent {
  fontsService = inject(FontService);

  fonts: Signal<IFont[]> = toSignal(this.fontsService.$entities) as Signal<
    IFont[]
  >;
  deletedFont: Signal<IFont> = toSignal(
    this.fontsService.$deletedEntity
  ) as Signal<IFont>;
  uploadedFont: Signal<IFont> = toSignal(
    this.fontsService.$uploadedFile
  ) as Signal<IFont>;
  selectedFile: WritableSignal<File | undefined> = signal(undefined);

  filename = new FormControl();

  constructor() {
    this.fontsService.list$.next(null);
    effect(() => {
      if (this.uploadedFont() || this.deletedFont()) {
        this.fontsService.list$.next(null);
      }
    });
  }

  save(): void {
    const file = this.selectedFile();
    if (file) {
      const formData = new FormData();
      formData.append('font', file, file.name);

      this.selectedFile.set(undefined);
      this.filename.reset();

      this.fontsService.upload$.next(formData);
    }
  }

  selectFile($event: Event) {
    const target = $event.target as HTMLInputElement;
    const files = target.files as FileList;
    if (files && files.length > 0) {
      const file = files[0];

      const allowedTypes = ['font/ttf', 'font/otf', 'font/woff', 'font/woff2'];
      if (!allowedTypes.includes(file.type)) {
        throw new Error(`File type ${file.type} is not allowed`);
      }
      this.selectedFile.set(file);
    }
  }

  deleteFont(font: IFont) {
    this.fontsService.delete$.next(font);
  }
}

<div
  class="form-input__background"
  [ngClass]="{
    'form-input__disabled': ngControl.control!.disabled,
  }">
  @let hasError = ngControl.errors && ngControl.touched;
  <div class="form-input__container">
    <input
      [attr.data-testid]="id() | appendTestId: 'input'"
      [formControl]="ngControl.control! | toFormControl"
      class="form-input peer"
      [ngClass]="{
        'form-input__error-ring': hasError,
      }"
      [id]="id()"
      [placeholder]="label() ?? ''"
      [type]="isPasswordVisible() ? 'text' : 'password'" />
    <label
      [attr.data-testid]="id() | appendTestId: 'label'"
      class="form-input__floating-label"
      [ngClass]="{ 'form-input__error-text': hasError }"
      [for]="id()">
      {{ label() }}
    </label>

    @if (!ngControl.control!.disabled) {
      <span
        tabindex="0"
        [id]="id() | appendTestId: 'visibility-switch'"
        [attr.data-testid]="id() | appendTestId: 'visibility-switch'"
        [attr.aria-label]="isPasswordVisible() ? 'sichtbar' : 'nicht sichtbar'"
        class="material-symbols-rounded form-input__text absolute bottom-1 right-2 top-1 h-8 cursor-pointer text-2xl"
        (click)="isPasswordVisible.set(!isPasswordVisible())">
        {{ isPasswordVisible() ? 'visibility_off' : 'visibility' }}
      </span>
    }
    @if (hasError && !errorTemplate()) {
      <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
        <ng-content></ng-content>
      </app-error-message>
    }
    @if (hasError && errorTemplate()) {
      <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
        <ng-container *ngTemplateOutlet="errorTemplate()!"></ng-container>
      </app-error-message>
    }
  </div>
</div>

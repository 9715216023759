import { inject, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  defaultHeader,
  IHeader,
  IHeaderForm,
} from '../../entities/header-entity';
import { Factory } from '../factory';
import { ClientService } from '../../services/client/client.service';
import { IAccessibleImageForm } from '../../entities/image-entity';

@Injectable({
  providedIn: 'root',
})
export class HeaderFormsFactoryService extends Factory<IHeaderForm, IHeader> {
  clientService = inject(ClientService);
  formBuilder = inject(FormBuilder);

  buildForm(entity: IHeader = defaultHeader): FormGroup<IHeaderForm> {
    return this.formBuilder.group<IHeaderForm>({
      id: this.formBuilder.control(entity.id || null),
      client: this.formBuilder.nonNullable.control(this.clientService.client, [
        Validators.required,
      ]),
      toggle: this.formBuilder.nonNullable.control<boolean>(entity.toggle),
      backgroundColor: this.formBuilder.nonNullable.control(
        entity.backgroundColor,
        [Validators.required]
      ),
      fontColor: this.formBuilder.nonNullable.control(entity.fontColor, [
        Validators.required,
      ]),
      logo: this.formBuilder.group<IAccessibleImageForm>({
        id: this.formBuilder.control(entity.logo.id || null),
        image: this.formBuilder.control(entity.logo.image, [
          Validators.required,
        ]),
        imageAlts: this.formBuilder.nonNullable.control(entity.logo.imageAlts, [
          Validators.required,
        ]),
        url: this.formBuilder.control(entity.logo.url || null),
      }),
      headlines: this.formBuilder.nonNullable.control(entity.headlines, [
        Validators.required,
      ]),
      headlineFontSize: this.formBuilder.nonNullable.control<number>(
        entity.headlineFontSize,
        [Validators.required]
      ),
      leftImage: this.formBuilder.group<IAccessibleImageForm>({
        id: this.formBuilder.control(entity.leftImage.id || null),
        image: this.formBuilder.control(entity.leftImage.image),
        imageAlts: this.formBuilder.nonNullable.control(
          entity.leftImage.imageAlts
        ),
        url: this.formBuilder.control(entity.leftImage.url || null),
      }),
      rightImage: this.formBuilder.group<IAccessibleImageForm>({
        id: this.formBuilder.control(entity.rightImage.id || null),
        image: this.formBuilder.control(entity.rightImage.image),
        imageAlts: this.formBuilder.nonNullable.control(
          entity.rightImage.imageAlts
        ),
        url: this.formBuilder.control(entity.rightImage.url || null),
      }),
    });
  }

  buildEntity(form: FormGroup<IHeaderForm>): IHeader {
    /*
      getRawValue() also gives the values of disabled controls
      value only gives the value of enables controls
      since we disable controls with standard values from buttongroups
      we obviously want to keep and sent them
    */
    const header = form.getRawValue() as unknown as IHeader;
    this.removeNullIds(header);

    return header as unknown as IHeader;
  }
}

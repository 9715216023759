@if (banner() && this.bannerForm) {
  <!-- LanguageSwitcher -->
  <div [formGroup]="languageForm" class="flex w-full justify-end">
    <app-language-switcher
      formControlName="language"
      label="Sprachauswahl"
      id="language-select">
      <div>Fehler bei der Sprachauswahl</div>
    </app-language-switcher>
  </div>

  <app-section [formGroup]="bannerForm">
    <!-- BannerHeader -->
    <app-section-header>
      <app-section-headline>Banner</app-section-headline>
      <app-section-header-actions>
        <app-button
          [style]="'primary'"
          (click)="save()"
          [disabled]="!bannerForm.valid"
          [type]="'submit'"
          [text]="'Speichern'">
        </app-button>
        <app-button
          [style]="'secondary'"
          [type]="'reset'"
          (click)="showPopUpChanges()"
          [text]="'Abbrechen'">
        </app-button>
      </app-section-header-actions>
    </app-section-header>

    <!-- Accessibility Hint-->
    <app-accessibility-banner />

    <!-- BannerImageSection -->
    <app-section [disabled]="!bannerForm.controls.imageActive.value">
      <app-section-header>
        <app-section-headline> Banner </app-section-headline>
        <app-section-header-actions>
          <app-toggle
            [formControl]="bannerForm.controls.imageActive"
            id="imageActive"
            label="Aktiv setzen" />
        </app-section-header-actions>
      </app-section-header>
      <span>
        Mit einem Bannerbild können Sie Aufmerksamkeit generieren und Ihren Shop
        bewerben. Bilder sorgen für größere Datenmengen, was bei Handy-Nutzern
        zu längeren Ladezeiten und einem erhöhten Datenverbrauch führt.
      </span>
      <div class="flex w-full gap-5">
        <app-image-select
          [formControl]="bannerForm.controls.accessibleImage.controls.image"
          label="Bild"
          id="image" />
        <app-number-input
          [formControl]="bannerForm.controls.imageHeight"
          label="Höhe in Pixel"
          id="imageHeight" />
        <app-translation-accessibility-text-input
          [formControl]="bannerForm.controls.accessibleImage.controls.imageAlts"
          label="Beschreibung"
          id="imageAlts" />
      </div>
    </app-section>

    <!-- BannerTextSection -->
    <app-section [disabled]="!bannerForm.controls.textActive.value">
      <app-section-header>
        <app-section-headline>Werbetext</app-section-headline>
        <app-section-header-actions>
          <app-toggle
            [formControl]="bannerForm.controls.textActive"
            id="textActive"
            label="Aktiv setzen" />
        </app-section-header-actions>
      </app-section-header>
      <span>
        Wählen Sie ein Bild, auf dem Ihr Text gut lesbar ist. Oder verwenden Sie
        eine Hintergrundform, um den Text vom Bannerbild abzuheben. Achten Sie
        auf ausreichend Kontrast zwischen Text und Hintergrund.
      </span>

      <!-- Title-->
      <div class="flex flex-col gap-5">
        <app-translation-text-input
          class="w-full"
          [formControl]="bannerForm.controls.headlines"
          label="Titel"
          id="headlines" />
        <div class="flex w-full gap-5">
          <app-number-input
            [formControl]="bannerForm.controls.headlineFontSize"
            label="Schriftgröße (in der Einheit pt)"
            id="headlinesFontSize" />
          <app-button-group
            id="headlineFontSizeButtonGroup"
            [options]="fontSizes | enumToButtonOptionArray"
            [formControl]="headlineFontSizeBtn"
            (valueChange)="onHeadlinesFontSizeChange($event)" />
        </div>
      </div>

      <!-- Subtitle-->
      <div class="flex flex-col gap-5">
        <app-translation-text-input
          [formControl]="bannerForm.controls.subHeadlines"
          label="Untertitel"
          id="subHeadlines" />
        <div class="flex w-full gap-5">
          <app-number-input
            [formControl]="bannerForm.controls.subHeadlineFontSize"
            label="Schriftgröße (in der Einheit pt)"
            id="subHeadlinesFontSize" />
          <app-button-group
            id="subHeadlineFontSizeButtonGroup"
            [options]="fontSizes | enumToButtonOptionArray"
            [formControl]="subHeadlineFontSizeBtn"
            (valueChange)="onSubHeadlinesFontSizeChange($event)" />
        </div>
      </div>

      <!-- Alignment-->
      <div class="flex gap-5">
        <app-drop-down
          id="textAlignmentHorizontal"
          label="Horizontale Ausrichtung"
          name="label"
          value="value"
          [formControl]="bannerForm.controls.textAlignmentHorizontal"
          [options]="horizontalAlignments | enumToButtonOptionArray">
        </app-drop-down>
        <app-drop-down
          id="textAlignmentVertical"
          label="Vertikale Ausrichtung"
          name="label"
          value="value"
          [formControl]="bannerForm.controls.textAlignmentVertical"
          [options]="verticalAlignments | enumToButtonOptionArray">
        </app-drop-down>
      </div>

      <!-- Text Color-->
      <div class="flex w-full gap-5">
        <app-color-picker-input
          [formControl]="bannerForm.controls.textColor"
          label="Schriftfarbe"
          id="textColor" />
        <app-button-group
          id="textColorButtonGroup"
          [options]="fontColors | enumToButtonOptionArray"
          [formControl]="textColorBtn"
          (valueChange)="onFontColorChange($event)" />
      </div>

      <!-- Font -->
      <div class="flex w-full gap-5">
        <app-font-select
          [formControl]="bannerForm.controls.textFont"
          label="Schriftart"
          id="textFont" />
        <app-button-group
          id="textFontButtonGroup"
          [options]="fontSizes | enumToButtonOptionArray"
          [formControl]="textFontBtn"
          (valueChange)="onFontChange($event)" />
      </div>
    </app-section>
  </app-section>
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ViaSupportService {
  buildViaString(via: string[]): string {
    const first = `von ${via[0]}`;
    const middle = via
      .slice(1, -1)
      .map(word => `via ${word}`)
      .join(' ');
    const last = `nach ${via[via.length - 1]}`;

    return [first, middle, last].filter(part => part).join(' ');
  }
}

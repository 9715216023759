<div
  [ngClass]="{
    'text-gray-400': disabled(),
    'bg-black text-[#F7FAFDDE]': dark(),
    'text-standard-text-primary border-standard-outline bg-white':
      !dark() && !disabled(),
  }"
  class="border-1 text-standard-text-primary flex flex-col gap-5 rounded-2xl p-4">
  <div class="section-header">
    <div class="flex flex-row justify-between gap-5">
      <div class="w-full">
        <ng-content select="app-section-header"></ng-content>
      </div>
      @if (collapsible()) {
        @if (isCollapsed) {
          <app-button
            [type]="'button'"
            (click)="toggleCollapse()"
            [style]="'custom'"
            class="w-12"
            ><span class="material-symbols-rounded"> keyboard_arrow_down </span>
          </app-button>
        } @else {
          <app-button
            [type]="'button'"
            (click)="toggleCollapse()"
            [style]="'custom'"
            class="w-12"
            ><span class="material-symbols-rounded"> keyboard_arrow_up </span>
          </app-button>
        }
      }
    </div>
  </div>
  @if (!isCollapsed) {
    <ng-content></ng-content>
  }
</div>

import { inject, Injectable } from '@angular/core';
import { ClientService } from '../../services/client/client.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { IText } from '../../entities/text-entity';
import { defaultLink, ILink, ILinkForm } from '../../entities/link-entity';

@Injectable({
  providedIn: 'root',
})
export class LinkFormsFactoryService {
  clientService = inject(ClientService);
  formBuilder = inject(FormBuilder);

  buildFormArray(
    entity: ILink[] = [defaultLink]
  ): FormArray<FormGroup<ILinkForm>> {
    return this.formBuilder.array<FormGroup<ILinkForm>>(
      entity.map((link: ILink): FormGroup<ILinkForm> => {
        return this.formBuilder.group<ILinkForm>({
          id: this.formBuilder.control<string | null>(link.id || null),
          urls: this.formBuilder.nonNullable.control<IText[]>(link.urls),
          texts: this.formBuilder.nonNullable.control<IText[]>(link.texts),
        });
      })
    );
  }
}

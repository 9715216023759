import { Injectable } from '@angular/core';
import { IImage } from '../../../entities/image-entity';
import { CRUDService } from '../../crud/crud.service';
import { PaymentProviderGateway } from './paymentProviderGateway';

@Injectable({
  providedIn: 'root',
})
export class PaymentProviderService extends CRUDService<
  IImage[],
  IImage[],
  null,
  never,
  never,
  never
> {
  constructor() {
    super(new PaymentProviderGateway());
  }
}

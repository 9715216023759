<div class="flex flex-row" [attr.aria-label]="viaAriaLabel">
  @for (viaStation of via(); track $index; let index = $index) {
    <div class="flex flex-row items-center">
      <div class="text-base">
        {{ viaStation }}
      </div>
      @if (index < via().length - 1) {
        <div class="mx-2 rounded-3xl bg-red-700 p-1"></div>
      }
    </div>
  }
</div>

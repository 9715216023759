import {
  Component,
  input,
  InputSignal,
  output,
  OutputEmitterRef,
  TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from '../../button/button.component';
import { IDialogResponse } from '../dialog';

@Component({
  selector: 'app-dialog',
  imports: [CommonModule, ButtonComponent],
  templateUrl: './dialog.component.html',
})
export class DialogComponent<T> {
  public readonly title: InputSignal<string> = input.required<string>();
  public readonly data: InputSignal<T> = input.required();

  public readonly content: InputSignal<TemplateRef<unknown>> =
    input.required<TemplateRef<unknown>>();
  public readonly footer: InputSignal<TemplateRef<unknown> | undefined> =
    input<TemplateRef<unknown>>();

  public readonly onClose: OutputEmitterRef<IDialogResponse<T>> =
    output<IDialogResponse<T>>();

  public cancel(): void {
    this.onClose.emit({ submit: false, data: this.data() });
  }

  public submit(): void {
    this.onClose.emit({ submit: true, data: this.data() });
  }
}

import { Component, inject, OnDestroy, OnInit, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CorporateDesignService } from '../../../services/api/corporateDesign/corporate-design.service';
import { IFont } from '../../../entities/font-entity';
import {
  blackHexCode,
  byFontColor,
  byPrimaryColor,
  bySecondaryColor,
  defaultCorporateDesign,
  defaultFontSize,
  ICorporateDesign,
  ICorporateDesignForm,
  standard,
} from '../../../entities/coporate-design-entity';
import { ClientService } from '../../../services/client/client.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { ButtonComponent } from '../../../components/button/button.component';
import { ColorPickerInputComponent } from '../../../components/form-inputs/color-picker-input/color-picker-input.component';
import { SectionComponent } from '../../../components/section/section.component';
import { ButtonGroupComponent } from '../../../components/form-inputs/button-group/button-group.component';
import { NumberInputComponent } from '../../../components/form-inputs/number-input/number-input.component';
import { FontService } from '../../../services/api/font/font.service';
import { PopupService } from '../../../services/popup/popup.service';
import { Subscription, tap } from 'rxjs';
import { DropZoneComponent } from '../../../components/drop-zone/drop-zone.component';

import { CorporateDesignFormsFactory } from '../../../factory/corporateDesign/corporateDesign-forms-factory.service';
import { TranslationTextInputComponent } from '../../../components/form-inputs/translation-text-input/translation-text-input.component';
import { SectionHeaderActionsComponent } from '../../../components/section/section-header/section-header-actions/section-header-actions.component';
import { SectionHeaderComponent } from '../../../components/section/section-header/section-header.component';
import { SectionHeadlineComponent } from '../../../components/section/section-header/section-headline/section-headline.component';
import { EnumToButtonOptionArrayPipe } from '../../../pipes/enum-to-button-option-array/enum-to-button-option-array.pipe';
import {
  CMSAlternateColors,
  CMSBorderColors,
  CMSBorderRounding,
  CMSDarkColors,
  CMSDarkIconColors,
  CMSFontColors,
  CMSFontSize,
  CMSIconColors,
} from '../../../constants/buttonGroups.enums';
import { ButtonGroupSupportService } from '../../../services/support/button-group-support.service';
import { FontSelectComponent } from '../../../components/form-inputs/font-select/font-select.component';

@Component({
  selector: 'app-corporate-page',
  imports: [
    CommonModule,
    FontSelectComponent,
    FormsModule,
    ReactiveFormsModule,
    NumberInputComponent,
    ButtonComponent,
    ColorPickerInputComponent,
    SectionComponent,
    ButtonGroupComponent,
    NumberInputComponent,
    DropZoneComponent,
    TranslationTextInputComponent,
    SectionHeaderActionsComponent,
    SectionHeaderComponent,
    SectionHeadlineComponent,
    EnumToButtonOptionArrayPipe,
    FontSelectComponent,
  ],
  templateUrl: './corporate-page.component.html',
  styles: ``,
})
export class CorporatePageComponent implements OnInit, OnDestroy {
  buttonGroupSupportService = inject(ButtonGroupSupportService);
  popupService = inject(PopupService);
  clientService = inject(ClientService);
  corporateDesignService = inject(CorporateDesignService);
  fontsService = inject(FontService);
  corporateDesignFormsFactory = inject(CorporateDesignFormsFactory);

  buttonStates: Record<string, string> = {};
  public corporateDesignForm!: FormGroup<ICorporateDesignForm>;

  public faviconForm = new FormGroup({
    favicon: new FormControl<File[]>([]),
  });

  protected readonly fontColors = CMSFontColors;
  protected readonly borderColors = CMSBorderColors;
  protected readonly iconColors = CMSIconColors;
  protected readonly darkIconColors = CMSDarkIconColors;
  protected readonly alternateColors = CMSAlternateColors;
  protected readonly darkColors = CMSDarkColors;
  protected readonly fontSizes = CMSFontSize;
  protected readonly buttonCornerRoundingArray = CMSBorderRounding;

  public primaryFontColorBtn: FormControl<string> = new FormControl();
  public primaryBorderColorBtn: FormControl<string> = new FormControl();
  public primaryAlternativeColorBtn: FormControl<string> = new FormControl();
  public secondaryFontColorBtn: FormControl<string> = new FormControl();
  public secondaryBorderColorBtn: FormControl<string> = new FormControl();
  public secondaryAlternativeColorBtn: FormControl<string> = new FormControl();
  public linkIconColorBtn: FormControl<string> = new FormControl();
  public primaryDarkColorBtn: FormControl<string> = new FormControl();
  public primaryDarkFontColorBtn: FormControl<string> = new FormControl();
  public linkIconDarkColorBtn: FormControl<string> = new FormControl();
  public fontSizeTitleBtn: FormControl<string> = new FormControl();
  public textFontSizeColorBtn: FormControl<string> = new FormControl();
  public smallTextBtn: FormControl<string> = new FormControl();
  public fontStyleBtn: FormControl<string> = new FormControl();
  public buttonCornerRoundingBtn: FormControl<string> = new FormControl();

  fonts: Signal<IFont[]> = toSignal(this.fontsService.$entities) as Signal<
    IFont[]
  >;
  savedEntity: Signal<ICorporateDesign | undefined> = toSignal(
    this.corporateDesignService.$savedEntity.pipe(
      tap(corporateDesign => {
        this.colorSyncSub?.unsubscribe();
        this.corporateDesignForm =
          this.corporateDesignFormsFactory.buildForm(corporateDesign);
        this.setInitialButtonStates(corporateDesign);
        this.colorSyncSub = this.createColorSyncSub();
      })
    )
  ) as Signal<ICorporateDesign>;
  uploadedFile: Signal<ICorporateDesign | undefined> = toSignal(
    this.corporateDesignService.$uploadedFile
  );
  corporateDesign: Signal<ICorporateDesign> = toSignal(
    this.corporateDesignService.$entity.pipe(
      tap(corporateDesign => {
        this.colorSyncSub?.unsubscribe();
        this.corporateDesignForm =
          this.corporateDesignFormsFactory.buildForm(corporateDesign);
        this.setInitialButtonStates(corporateDesign);
        this.colorSyncSub = this.createColorSyncSub();
      })
    )
  ) as Signal<ICorporateDesign>;

  protected showPopupChanges = false;
  protected showPopupToFactorySettings = false;

  private readonly sub = new Subscription();
  private colorSyncSub = new Subscription();

  constructor() {
    this.corporateDesignService.get$.next(null);
    this.fontsService.list$.next(null);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  ngOnInit(): void {
    window.onbeforeunload = (event: BeforeUnloadEvent) => {
      if (this.corporateDesignForm.dirty) {
        event.preventDefault();
      }
    };
    this.sub.add(
      this.popupService.popupActionChanges$.subscribe(action => {
        this.resetFormChanges(action);
      })
    );
    this.sub.add(
      this.popupService.popupActionToFactory$.subscribe(() => {
        this.resetToFactorySettings();
      })
    );
  }

  save(): void {
    const primaryAlternativeColorButtonValue =
      this.buttonStates['primaryAlternativeColorButtonState'];
    const primaryAlternativeColor =
      primaryAlternativeColorButtonValue === byFontColor
        ? (this.corporateDesignForm.controls.primaryFontColor.value ??
          blackHexCode)
        : primaryAlternativeColorButtonValue === byPrimaryColor
          ? (this.corporateDesignForm.controls.primaryColor.value ??
            blackHexCode)
          : primaryAlternativeColorButtonValue === blackHexCode
            ? blackHexCode
            : blackHexCode;

    const secondaryAlternativeColorButtonValue =
      this.buttonStates['secondaryAlternativeColorButtonState'];

    const secondaryAlternativeColor =
      secondaryAlternativeColorButtonValue === byFontColor
        ? (this.corporateDesignForm.get('secondaryFontColor')?.value ??
          blackHexCode)
        : secondaryAlternativeColorButtonValue === bySecondaryColor
          ? (this.corporateDesignForm.get('secondaryColor')?.value ??
            blackHexCode)
          : secondaryAlternativeColorButtonValue === blackHexCode
            ? blackHexCode
            : blackHexCode;

    this.corporateDesignForm.controls.primaryAlternativeColor.setValue(
      primaryAlternativeColor
    );
    this.corporateDesignForm.controls.secondaryAlternativeColor?.setValue(
      secondaryAlternativeColor
    );

    if (
      this.faviconForm.controls.favicon.value &&
      this.faviconForm.controls.favicon.value.length > 0
    ) {
      const faviconFile = this.faviconForm.controls.favicon.value[0];
      const newCorporateDesign = this.corporateDesignFormsFactory.buildEntity(
        this.corporateDesignForm
      );
      const faviconFileExtension = faviconFile.name.split('.').pop() as string;
      newCorporateDesign.faviconFileExtension = faviconFileExtension;
      newCorporateDesign.faviconFilename = faviconFile.name.replace(
        faviconFileExtension,
        ''
      );

      const formData = new FormData();
      formData.append('favicon', faviconFile, faviconFile.name);
      formData.append('corporateDesign', JSON.stringify(newCorporateDesign));
      this.corporateDesignService.upload$.next(formData);
    } else {
      this.corporateDesignService.save$.next(
        this.corporateDesignFormsFactory.buildEntity(this.corporateDesignForm)
      );
    }
  }

  onFontColorChange(value: string, control: string): void {
    switch (value) {
      case CMSFontColors.Benutzerdefiniert:
        this.corporateDesignForm.get(control)?.enable();
        break;
      default:
        this.corporateDesignForm.get(control)?.setValue(value);
        this.corporateDesignForm.get(control)?.disable();
        break;
    }
  }

  onBorderColorChange(value: string, control: string): void {
    switch (value) {
      case CMSBorderColors.Benutzerdefiniert:
        this.corporateDesignForm.get(control)?.enable();
        break;
      case CMSBorderColors.Keine:
        this.corporateDesignForm.get(control)?.setValue(CMSBorderColors.Keine);
        this.corporateDesignForm.get(control)?.disable();
        break;
    }
  }

  onLinkIconColorChange(value: string, control: string): void {
    switch (value) {
      case CMSIconColors.Benutzerdefiniert:
        this.corporateDesignForm.get(control)?.enable();
        break;
      default: {
        const icon = this.searchObjectByKey(defaultCorporateDesign, control);
        this.corporateDesignForm.get(control)?.setValue(icon);
        this.corporateDesignForm.get(control)?.disable();
        break;
      }
    }
  }

  searchObjectByKey<T extends Record<string, unknown>>(
    obj: T,
    key: keyof T
  ): T[keyof T] | null {
    if (key in obj) {
      return obj[key];
    }
    return null;
  }

  onDarkColorChange(value: string, control: string) {
    switch (value) {
      case CMSDarkColors.Benutzerdefiniert:
        this.corporateDesignForm
          .get(control)
          ?.setValue(defaultCorporateDesign.primaryDarkColor);
        this.corporateDesignForm.get(control)?.enable();
        break;
      case CMSDarkColors.Primärfarbe:
        this.corporateDesignForm
          .get(control)
          ?.setValue(this.corporateDesignForm.controls.primaryColor.value);
        this.corporateDesignForm.get(control)?.disable();
        break;
    }
  }

  onFontSizeChange(value: string, control: string) {
    if (value === standard) {
      this.corporateDesignForm.get(control)?.setValue(defaultFontSize);
      this.corporateDesignForm.get(control)?.disable();
    } else {
      this.corporateDesignForm.get(control)?.enable();
    }
  }

  onFontStyleChange(value: string, control: string) {
    if (value === CMSFontSize.Benutzerdefiniert) {
      this.corporateDesignForm.get(control)?.enable();
    } else {
      this.corporateDesignForm
        .get(control)
        ?.setValue(defaultCorporateDesign.font);
      this.corporateDesignForm.get(control)?.disable();
    }
  }

  resetToFactorySettings() {
    this.corporateDesignForm.reset({
      id: this.corporateDesign().id,
      ...defaultCorporateDesign,
    });
    this.showPopupToFactorySettings = false;
  }

  resetFormChanges(reset: boolean) {
    if (reset) {
      this.corporateDesignForm.reset({
        primaryColor: this.corporateDesign().primaryColor,
        primaryFontColor: this.corporateDesign().primaryFontColor,
        primaryBorderColor: this.corporateDesign().primaryBorderColor,
        secondaryColor: this.corporateDesign().secondaryColor,
        secondaryFontColor: this.corporateDesign().secondaryFontColor,
        secondaryBorderColor: this.corporateDesign().secondaryBorderColor,
        linkIcon: this.corporateDesign().linkIcon,
        primaryDarkColor: this.corporateDesign().primaryDarkColor,
        primaryDarkFontColor: this.corporateDesign().primaryDarkFontColor,
        linkIconDark: this.corporateDesign().linkIconDark,
        fontSizeTitle: this.corporateDesign().fontSizeTitle,
        fontSizeText: this.corporateDesign().fontSizeText,
        fontSizeSmall: this.corporateDesign().fontSizeSmall,
        font: this.corporateDesign().font,
        buttonBorderRounding: this.corporateDesign().buttonBorderRounding,
        tabs: this.corporateDesign().tabs,
        faviconFilename: this.corporateDesign().faviconFilename,
        faviconFileExtension: this.corporateDesign().faviconFileExtension,
      });
    }
    this.showPopupChanges = false;
  }

  showPopUpToFactorySettings() {
    this.popupService.showPopupToFactory();
  }

  showPopUpChanges() {
    this.popupService.showPopupChanges();
  }

  private setInitialButtonStates(corporateDesign: ICorporateDesign) {
    this.primaryFontColorBtn.setValue(
      this.buttonGroupSupportService.findValueOnEnum(
        corporateDesign.primaryFontColor.toString(),
        this.fontColors,
        CMSFontColors.Benutzerdefiniert
      )
    );
    this.onFontColorChange(this.primaryFontColorBtn.value, 'primaryFontColor');

    this.primaryBorderColorBtn.setValue(
      this.buttonGroupSupportService.findValueOnEnum(
        corporateDesign.primaryBorderColor.toString(),
        this.borderColors,
        CMSBorderColors.Benutzerdefiniert
      )
    );
    this.onBorderColorChange(
      this.primaryBorderColorBtn.value,
      'primaryBorderColor'
    );

    this.primaryAlternativeColorBtn.setValue(
      this.buttonGroupSupportService.findValueOnEnum(
        corporateDesign.primaryAlternativeColor.toString(),
        this.alternateColors,
        CMSAlternateColors.Benutzerdefiniert
      )
    );
    this.onBorderColorChange(
      this.primaryBorderColorBtn.value,
      'primaryBorderColor'
    );

    this.secondaryFontColorBtn.setValue(
      this.buttonGroupSupportService.findValueOnEnum(
        corporateDesign.secondaryFontColor.toString(),
        this.fontColors,
        CMSFontColors.Benutzerdefiniert
      )
    );
    this.onFontColorChange(
      this.primaryBorderColorBtn.value,
      'secondaryFontColor'
    );

    this.secondaryBorderColorBtn.setValue(
      this.buttonGroupSupportService.findValueOnEnum(
        corporateDesign.secondaryBorderColor.toString(),
        this.borderColors,
        CMSBorderColors.Benutzerdefiniert
      )
    );
    this.onBorderColorChange(
      this.secondaryBorderColorBtn.value,
      'secondaryBorderColor'
    );

    this.secondaryAlternativeColorBtn.setValue(
      this.buttonGroupSupportService.findValueOnEnum(
        corporateDesign.secondaryAlternativeColor.toString(),
        this.alternateColors,
        CMSAlternateColors.Benutzerdefiniert
      )
    );

    this.linkIconColorBtn.setValue(
      this.buttonGroupSupportService.findValueOnEnum(
        corporateDesign.linkIcon.toString(),
        this.iconColors,
        CMSIconColors.Benutzerdefiniert
      )
    );
    this.onLinkIconColorChange(this.linkIconColorBtn.value, 'linkIcon');

    this.primaryDarkColorBtn.setValue(
      corporateDesign.primaryDarkColor === corporateDesign.primaryColor
        ? CMSDarkColors.Primärfarbe
        : CMSDarkColors.Benutzerdefiniert
    );
    this.onDarkColorChange(this.primaryDarkColorBtn.value, 'onDarkColorChange');

    this.primaryDarkFontColorBtn.setValue(
      this.buttonGroupSupportService.findValueOnEnum(
        corporateDesign.primaryDarkFontColor.toString(),
        this.fontColors,
        defaultCorporateDesign.primaryDarkFontColor
      )
    );

    this.onFontColorChange(
      this.primaryDarkFontColorBtn.value,
      'primaryDarkFontColor'
    );

    this.linkIconDarkColorBtn.setValue(
      this.buttonGroupSupportService.findValueOnEnum(
        corporateDesign.linkIconDark.toString(),
        this.darkIconColors,
        CMSDarkIconColors.Benutzerdefiniert
      )
    );
    this.onLinkIconColorChange(this.linkIconDarkColorBtn.value, 'linkIconDark');

    this.fontSizeTitleBtn.setValue(
      this.buttonGroupSupportService.findValueOnEnum(
        corporateDesign.fontSizeTitle.toString(),
        this.fontSizes,
        CMSFontSize.Standard
      )
    );
    this.onFontSizeChange(this.fontSizeTitleBtn.value, 'fontSizeTitle');

    this.textFontSizeColorBtn.setValue(
      this.buttonGroupSupportService.findValueOnEnum(
        corporateDesign.fontSizeText.toString(),
        this.fontSizes,
        CMSFontSize.Standard
      )
    );
    this.onFontSizeChange(this.textFontSizeColorBtn.value, 'fontSizeText');

    this.smallTextBtn.setValue(
      this.buttonGroupSupportService.findValueOnEnum(
        corporateDesign.fontSizeSmall.toString(),
        this.fontSizes,
        CMSFontSize.Standard
      )
    );
    this.onFontSizeChange(this.smallTextBtn.value, 'fontSizeSmall');
    this.fontStyleBtn.setValue(
      corporateDesign.font
        ? CMSFontSize.Benutzerdefiniert
        : CMSFontSize.Standard
    );
    this.onFontStyleChange(this.fontStyleBtn.value, 'font');

    this.buttonCornerRoundingBtn.setValue(
      this.buttonGroupSupportService.findValueOnEnum(
        corporateDesign.buttonBorderRounding.toString(),
        this.buttonCornerRoundingArray,
        CMSBorderRounding.Eckig
      )
    );
  }

  private createColorSyncSub(): Subscription {
    return this.corporateDesignForm.controls.primaryColor.valueChanges.subscribe(
      v => {
        if (this.primaryDarkColorBtn.value === CMSDarkColors.Primärfarbe) {
          this.corporateDesignForm.controls.primaryDarkColor.setValue(v);
        }
      }
    );
  }
}

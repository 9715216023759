import { inject, Injectable } from '@angular/core';
import { ClientService } from '../../services/client/client.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import {
  defaultAccessibleImage,
  IAccessibleImage,
  IAccessibleImageForm,
  IImage,
} from '../../entities/image-entity';
import { IText } from '../../entities/text-entity';

@Injectable({
  providedIn: 'root',
})
export class ImageFormsFactoryService {
  clientService = inject(ClientService);
  formBuilder = inject(FormBuilder);

  buildFormArray(
    entity: IAccessibleImage[] = [defaultAccessibleImage]
  ): FormArray<FormGroup<IAccessibleImageForm>> {
    return this.formBuilder.array<FormGroup<IAccessibleImageForm>>(
      entity.map((img: IAccessibleImage): FormGroup<IAccessibleImageForm> => {
        return this.formBuilder.group<IAccessibleImageForm>({
          id: this.formBuilder.control<string | null>(img.id || null),
          imageAlts: this.formBuilder.nonNullable.control<IText[]>(
            img.imageAlts
          ),
          image: this.formBuilder.control<IImage | null>(img.image),
          url: this.formBuilder.control<string | null>(img.url || null),
        });
      })
    );
  }
}

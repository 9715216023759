import { Observable } from 'rxjs';
import { inject } from '@angular/core';
import { ClientService } from '../../client/client.service';
import { IGetGateway, ISaveGateway } from '../../gateways';
import { RestCallBuilder } from '../../../builder/rest/rest.builder';
import IFooter, {
  defaultFooter,
  FooterSchema,
} from '../../../entities/footer-entity';

export class FooterGateway
  implements IGetGateway<null, IFooter>, ISaveGateway<IFooter, IFooter>
{
  private clientService = inject(ClientService);
  private restCallBuilder: RestCallBuilder<IFooter> =
    new RestCallBuilder<IFooter>(defaultFooter);
  private client = this.clientService.client;

  get(): Observable<IFooter> {
    return this.restCallBuilder
      .get('/footer/' + this.client)
      .validate(FooterSchema)
      .handleErrors()
      .build();
  }

  save(query: IFooter): Observable<IFooter> {
    return this.restCallBuilder
      .post('/footer/', query)
      .validate(FooterSchema)
      .handleErrors()
      .build();
  }
}

import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterOutlet } from '@angular/router';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ResetChangesDialogComponent } from '../../components/dialogs/reset-changes-dialog/reset-changes-dialog.component';
import { ResetToFactorySettingsDialogComponent } from '../../components/dialogs/reset-to-factory-settings-dialog/reset-toFactorySettings-dialog.component';

@Component({
  selector: 'app-cms',
  imports: [
    CommonModule,
    FormsModule,
    RouterOutlet,
    SidebarComponent,
    ResetChangesDialogComponent,
    ResetToFactorySettingsDialogComponent,
  ],
  templateUrl: './cms-page.component.html',
  styles: ``,
})
export class CmsPageComponent implements OnInit {
  ngOnInit() {
    this.resetDomChanges();
  }

  resetDomChanges(): void {
    // Reset fonts
    const styles = Array.from(document.head.querySelectorAll('style'));
    styles.forEach(style => {
      if (
        style.innerHTML.includes('@font-face') ||
        style.innerHTML.includes('font-family')
      ) {
        style.remove();
      }
    });

    // Reset favicon
    const favicons = document.head.querySelectorAll('link[rel="icon"]');
    favicons.forEach(favicon => favicon.remove());

    // Reset title
    const title = document.head.querySelector('title');
    if (title) {
      title.innerHTML = 'Default Title';
    }
  }
}

import { inject, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  defaultHeader,
  IHeader,
  IHeaderForm,
} from '../../entities/header-entity';
import { Factory } from '../factory';
import { ClientService } from '../../services/client/client.service';
import { IAccessibleImageForm } from '../../entities/image-entity';
import {
  defaultLoginLogout,
  ILoginLogout,
  ILoginLogoutForm,
} from '../../entities/login-logout.entity';
import { undefined } from 'zod';

@Injectable({
  providedIn: 'root',
})
export class LoginLogoutFormsFactoryService extends Factory<
  ILoginLogoutForm,
  ILoginLogout
> {
  clientService = inject(ClientService);
  formBuilder = inject(FormBuilder);

  buildForm(
    entity: ILoginLogout = defaultLoginLogout
  ): FormGroup<ILoginLogoutForm> {
    return this.formBuilder.group<ILoginLogoutForm>({
      id: this.formBuilder.control(entity.id || null),
      client: this.formBuilder.nonNullable.control(this.clientService.client, [
        Validators.required,
      ]),
      loginButton: this.formBuilder.nonNullable.control(entity.loginButton, [
        Validators.required,
      ]),
      email: this.formBuilder.nonNullable.control(entity.email, [
        Validators.required,
      ]),
      password: this.formBuilder.nonNullable.control(entity.password, [
        Validators.required,
      ]),
      loginHeader: this.formBuilder.nonNullable.control(entity.loginHeader, [
        Validators.required,
      ]),
      separation: this.formBuilder.nonNullable.control(entity.separation, [
        Validators.required,
      ]),
      register: this.formBuilder.nonNullable.control(entity.register, [
        Validators.required,
      ]),
      passwordReset: this.formBuilder.nonNullable.control(
        entity.passwordReset,
        [Validators.required]
      ),
      passwordResetLink: this.formBuilder.nonNullable.control(
        entity.passwordResetLink,
        [Validators.required]
      ),
      logoutAltText: this.formBuilder.nonNullable.control(
        entity.logoutAltText,
        [Validators.required]
      ),
    });
  }

  buildEntity(form: FormGroup<ILoginLogoutForm>): ILoginLogout {
    /*
      getRawValue() also gives the values of disabled controls
      value only gives the value of enables controls
      since we disable controls with standard values from buttongroups
      we obviously want to keep and sent them
    */
    const login = form.getRawValue() as unknown as ILoginLogout;
    this.removeNullIds(login);

    return login as unknown as ILoginLogout;
  }
}

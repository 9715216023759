<div class="text-initial box-border w-full rounded-lg bg-white">
  @let hasError = ngControl.errors && ngControl.touched;
  <div class="flex flex-row items-center">
    @if (labelLeft()) {
      <span class="pr-2" [attr.data-testid]="id() | appendTestId: 'label'">{{
        label()
      }}</span>
    }
    <input
      [attr.data-testid]="id() | appendTestId: 'input'"
      [id]="id()"
      [formControl]="ngControl.control! | toFormControl"
      type="checkbox"
      class="peer sr-only opacity-0" />
    <label
      [attr.data-testid]="id() | appendTestId: 'toggle'"
      (click)="toggle()"
      [for]="id()"
      class="form-input__toggle"
      [ngClass]="{
        'form-input__disabled !cursor-not-allowed': ngControl.control!.disabled,
      }">
      <span class="sr-only"></span>
    </label>
    @if (!labelLeft()) {
      <span class="pl-2" [attr.data-testid]="id() | appendTestId: 'label'">{{
        label()
      }}</span>
    }
  </div>
  @if (hasError && !errorTemplate()) {
    <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
      <ng-content></ng-content>
    </app-error-message>
  }
  @if (hasError && errorTemplate()) {
    <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
      <ng-container *ngTemplateOutlet="errorTemplate()!"></ng-container>
    </app-error-message>
  }
</div>

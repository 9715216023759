import { Component, inject, OnDestroy, OnInit, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { HeaderService } from '../../../services/api/header/header.service';
import {
  defaultHeader,
  IHeader,
  IHeaderForm,
} from '../../../entities/header-entity';
import { ClientService } from '../../../services/client/client.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { AccessibilityBannerComponent } from '../../../components/accessibility-banner/accessibility-banner-component';
import { ButtonComponent } from '../../../components/button/button.component';
import { ColorPickerInputComponent } from '../../../components/form-inputs/color-picker-input/color-picker-input.component';
import { LanguageSwitcherComponent } from '../../../components/form-inputs/language-switcher/language-switcher.component';
import { SectionComponent } from '../../../components/section/section.component';
import { SectionHeaderActionsComponent } from '../../../components/section/section-header/section-header-actions/section-header-actions.component';
import { SectionHeaderComponent } from '../../../components/section/section-header/section-header.component';
import { SectionHeadlineComponent } from '../../../components/section/section-header/section-headline/section-headline.component';
import { Subscription, tap } from 'rxjs';
import { HeaderFormsFactoryService } from '../../../factory/header/header-forms-factory.service';
import { TranslationTextInputComponent } from '../../../components/form-inputs/translation-text-input/translation-text-input.component';
import { ImageSelectComponent } from '../../../components/form-inputs/image-select/image-select.component';
import { TranslationAccessibilityTextInputComponent } from '../../../components/form-inputs/translation-accessibility-text-input/translation-accessibility-text-input.component';
import { NumberInputComponent } from '../../../components/form-inputs/number-input/number-input.component';
import { ButtonGroupComponent } from '../../../components/form-inputs/button-group/button-group.component';
import { EnumToButtonOptionArrayPipe } from '../../../pipes/enum-to-button-option-array/enum-to-button-option-array.pipe';
import { ToggleComponent } from '../../../components/form-inputs/toggle/toggle.component';
import {
  CMSFontColors,
  CMSFontSize,
} from '../../../constants/buttonGroups.enums';
import { ButtonGroupSupportService } from '../../../services/support/button-group-support.service';
import { PopupService } from '../../../services/popup/popup.service';

@Component({
  selector: 'app-header-page',
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AccessibilityBannerComponent,
    ButtonComponent,
    ColorPickerInputComponent,
    LanguageSwitcherComponent,
    SectionComponent,
    SectionHeaderActionsComponent,
    SectionHeaderComponent,
    SectionHeadlineComponent,
    TranslationTextInputComponent,
    ImageSelectComponent,
    TranslationAccessibilityTextInputComponent,
    NumberInputComponent,
    ButtonGroupComponent,
    EnumToButtonOptionArrayPipe,
    ToggleComponent,
  ],
  templateUrl: './header-page.component.html',
  styles: ``,
})
export class HeaderPageComponent implements OnInit, OnDestroy {
  buttonGroupSupportService = inject(ButtonGroupSupportService);
  popupService = inject(PopupService);
  clientService = inject(ClientService);
  headerService: HeaderService = inject(HeaderService);
  headerFormsFactory = inject(HeaderFormsFactoryService);

  public headerForm!: FormGroup<IHeaderForm>;
  public languageForm: FormGroup = new FormGroup({
    languageSelect: new FormControl('deu', [Validators.required]),
  });
  public readonly headlineFontSizeBtn: FormControl<string> = new FormControl();
  public readonly fontColorBtn: FormControl<string> = new FormControl();
  public readonly backgroundColorBtn: FormControl<string> = new FormControl();

  protected readonly fontColors = CMSFontColors;
  protected readonly fontSizes = CMSFontSize;

  header: Signal<IHeader> = toSignal(
    this.headerService.$entity.pipe(
      tap(header => {
        this.headerForm = this.headerFormsFactory.buildForm(header);
        this.setInitialButtonStates(header);
      })
    )
  ) as Signal<IHeader>;
  savedHeader: Signal<IHeader> = toSignal(
    this.headerService.$savedEntity
  ) as Signal<IHeader>;

  protected showPopupChanges = false;
  protected showPopupToFactorySettings = false;
  private readonly sub = new Subscription();

  constructor() {
    this.headerService.get$.next(null);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  ngOnInit(): void {
    window.onbeforeunload = (event: BeforeUnloadEvent) => {
      if (this.headerForm.dirty) {
        event.preventDefault();
      }
    };
    this.sub.add(
      this.popupService.popupActionChanges$.subscribe(action => {
        this.resetFormChanges(action);
      })
    );
    this.sub.add(
      this.popupService.popupActionToFactory$.subscribe(() => {
        this.resetToFactorySettings();
      })
    );
  }

  save(): void {
    this.headerService.save$.next(
      this.headerFormsFactory.buildEntity(this.headerForm)
    );
  }

  private setInitialButtonStates(header: IHeader) {
    if (header.headlineFontSize === defaultHeader.headlineFontSize) {
      this.headlineFontSizeBtn.setValue(CMSFontSize.Standard);
      this.headerForm.controls.headlineFontSize.disable();
    } else {
      this.headlineFontSizeBtn.setValue(CMSFontSize.Benutzerdefiniert);
    }

    this.fontColorBtn.setValue(
      this.buttonGroupSupportService.findValueOnEnum(
        header.fontColor.toString(),
        this.fontColors,
        CMSFontColors.Benutzerdefiniert
      )
    );
    this.onFontColorChange(this.fontColorBtn.value);

    this.backgroundColorBtn.setValue(
      this.buttonGroupSupportService.findValueOnEnum(
        header.backgroundColor.toString(),
        this.fontColors,
        CMSFontColors.Benutzerdefiniert
      )
    );
    this.onBackgroundColorChange(this.backgroundColorBtn.value);
  }

  onFontColorChange(value: string): void {
    switch (value) {
      case CMSFontColors.Benutzerdefiniert:
        this.headerForm.controls.fontColor.enable();
        break;
      default:
        this.headerForm.controls.fontColor.setValue(value);
        this.headerForm.controls.fontColor.disable();
        break;
    }
  }

  onBackgroundColorChange(value: string): void {
    switch (value) {
      case CMSFontColors.Benutzerdefiniert:
        this.headerForm.controls.backgroundColor.enable();
        break;
      default:
        this.headerForm.controls.backgroundColor.setValue(value);
        this.headerForm.controls.backgroundColor.disable();
        break;
    }
  }

  onHeadlinesFontSizeChange(value: string): void {
    switch (value) {
      case CMSFontSize.Benutzerdefiniert:
        this.headerForm.controls.headlineFontSize.enable();
        break;
      case CMSFontSize.Standard:
        this.headerForm.controls.headlineFontSize.setValue(
          defaultHeader.headlineFontSize
        );
        this.headerForm.controls.headlineFontSize.disable();
        break;
    }
  }

  deleteImageRight() {
    this.headerForm.controls.rightImage.setValue({
      id: this.headerForm.controls.rightImage.controls.id.value,
      image: null,
      imageAlts: [],
      url: null,
    });
  }

  deleteImageLeft() {
    this.headerForm.controls.leftImage.setValue({
      id: this.headerForm.controls.leftImage.controls.id.value,
      image: null,
      imageAlts: [],
      url: null,
    });
  }

  showPopUpChanges() {
    this.popupService.showPopupChanges();
  }

  resetToFactorySettings() {
    this.headerForm.reset({
      id: this.header().id,
      ...defaultHeader,
    });
    this.showPopupToFactorySettings = false;
  }

  resetFormChanges(reset: boolean) {
    if (reset) {
      this.headerForm.reset({
        client: this.header().client,
        toggle: this.header().toggle,
        logo: this.header().logo,
        backgroundColor: this.header().backgroundColor,
        fontColor: this.header().fontColor,
        headlines: this.header().headlines,
        headlineFontSize: this.header().headlineFontSize,
        leftImage: this.header().leftImage,
        rightImage: this.header().rightImage,
      });
    }
    this.showPopupChanges = false;
  }
}

import { inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../services/config/config.service';
import { catchError, map, Observable, of } from 'rxjs';
import { ErrorHandlingService } from '../../services/errorHandling/error-handling.service';
import { ValidatorService } from '../../services/validator/validator.service';
import { ZodSchema } from 'zod';

type RequestParams = {
  [param: string]:
    | string
    | number
    | boolean
    | ReadonlyArray<string | number | boolean>;
};

export class RestCallBuilder<T> {
  private http = inject(HttpClient);
  private configService = inject(ConfigService);
  private errorHandlingService = inject(ErrorHandlingService);
  private validatorService = inject(ValidatorService);

  private apiUrl: string | undefined = this.configService.getCmsUrl;

  private entityObservable: Observable<T>;
  private readonly defaultEntity: T;

  constructor(defaultEntity: T) {
    this.entityObservable = of(defaultEntity);
    this.defaultEntity = defaultEntity;
  }

  //TODO: Workaround till cms service also under ApiGateway
  getExternal(url: string, params?: RequestParams) {
    this.entityObservable = this.http.get<T>(url, {
      headers: { clientId: '842D4A5C-5242-4CC2-A853-468CACCDA9D5' },
      params,
    });
    return this;
  }

  get(url: string, params?: RequestParams) {
    this.entityObservable = this.http.get<T>(this.apiUrl + url, {
      params,
    });
    return this;
  }

  post(url: string, query: T | FormData) {
    this.entityObservable = this.http.post<T>(this.apiUrl + url, query);
    return this;
  }

  delete(url: string) {
    this.entityObservable = this.http.delete<T>(this.apiUrl + url);
    return this;
  }

  validate(schema: ZodSchema<T>) {
    this.entityObservable = this.entityObservable?.pipe(
      map(entity => this.validatorService.validate(entity, schema))
    );
    return this;
  }

  handleErrors() {
    this.entityObservable = this.entityObservable?.pipe(
      catchError(err =>
        this.errorHandlingService.handle<T>(err, this.defaultEntity)
      )
    );
    return this;
  }

  build() {
    return this.entityObservable;
  }
}

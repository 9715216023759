export enum CMSBorderColors {
  Benutzerdefiniert = 'Benutzerdefiniert',
  Keine = '',
}
export enum CMSIconColors {
  Benutzerdefiniert = 'Benutzerdefiniert',
  Schwarz = '#000000',
}
export enum CMSDarkIconColors {
  Benutzerdefiniert = 'Benutzerdefiniert',
  Weiß = '#FFFFFF',
}
export enum CMSAlternateColors {
  Benutzerdefiniert = 'Benutzerdefiniert',
  Schriftfarbe = 'Nach Schriftfarbe',
  Primärfarbe = 'Nach Primärfarbe',
}
export enum CMSDarkColors {
  Benutzerdefiniert = 'Benutzerdefiniert',
  Primärfarbe = 'Nach Primärfarbe',
}

export enum CMSBorderRounding {
  Eckig = 'none',
  Abgerundet = 'middle',
  Rund = 'large',
}

export enum CMSFontSize {
  Benutzerdefiniert = 'Benutzerdefiniert',
  Standard = 'Standard',
}

export enum CMSFontColors {
  Benutzerdefiniert = 'Benutzerdefiniert',
  Weiß = '#FFFFFF',
  Schwarz = '#000000',
}

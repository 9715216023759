<div
  class="form-input__background"
  [ngClass]="{
    'form-input__disabled': ngControl.control!.disabled,
  }">
  @let hasError = ngControl.errors && ngControl.touched;
  <div class="form-input__accessibility peer">
    <span class="material-symbols-rounded"> accessibility_new </span>
  </div>
  <div class="form-input__container !rounded-l-none">
    <input
      [attr.data-testid]="id() | appendTestId: 'input'"
      [formControl]="ngControl.control! | toFormControl"
      [ngClass]="{
        'form-input__error-ring': hasError,
      }"
      [id]="id()"
      [placeholder]="label() ?? ''"
      class="form-input peer h-10 w-full !rounded-l-none rounded-r-lg border-0 bg-transparent px-2 placeholder-transparent ring-2 ring-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-600"
      type="text" />
    <label
      [attr.data-testid]="id() | appendTestId: 'label'"
      class="form-input__floating-label"
      [ngClass]="{ 'form-input__error-text': hasError }"
      [for]="id()">
      {{ label() }}
    </label>
    @if (hasError && !errorTemplate()) {
      <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
        <ng-content></ng-content>
      </app-error-message>
    }
    @if (hasError && errorTemplate()) {
      <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
        <ng-container *ngTemplateOutlet="errorTemplate()!"></ng-container>
      </app-error-message>
    }
  </div>
</div>

<div
  class="text-initial rounded-lg bg-white"
  [ngClass]="{
    'form-input__disabled': ngControl.control!.disabled,
  }">
  @let hasError = ngControl.errors && ngControl.touched;
  <div class="relative w-full bg-inherit">
    <input
      [attr.data-testid]="id() | appendTestId: 'input'"
      [formControl]="ngControl.control! | toFormControl"
      class="peer h-10 w-full rounded-lg border-0 bg-transparent px-2 placeholder-transparent ring-2 ring-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-600"
      [ngClass]="{
        'ring-red-600': hasError,
      }"
      [id]="id()"
      [placeholder]="label() ?? ''"
      type="datetime" />
    <label
      [attr.data-testid]="id() | appendTestId: 'label'"
      class="absolute -top-3 left-0 mx-1 cursor-text bg-inherit px-1 text-sm text-gray-500 transition-all peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-focus:-top-3 peer-focus:text-sm peer-focus:text-sky-600"
      [ngClass]="{ 'text-red-600': hasError }"
      [for]="id()">
      {{ label() }}
    </label>
    @if (hasError && !errorTemplate()) {
      <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
        <ng-content></ng-content>
      </app-error-message>
    }
    @if (hasError && errorTemplate()) {
      <app-error-message [attr.data-testid]="id() | appendTestId: 'error'">
        <ng-container *ngTemplateOutlet="errorTemplate()!"></ng-container>
      </app-error-message>
    }
  </div>
</div>

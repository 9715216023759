import { z, ZodSchema } from 'zod';
import { IText, TextSchema } from './text-entity';
import { FormControl } from '@angular/forms';

export const LinkSchema: ZodSchema = z.object({
  id: z.string().uuid().nullable(),
  urls: z.array(TextSchema),
  texts: z.array(TextSchema),
});

export interface ILink {
  id?: string;
  urls: IText[];
  texts: IText[];
}
export interface ILinkForm {
  id: FormControl<string | null>;
  urls: FormControl<IText[]>;
  texts: FormControl<IText[]>;
}

export const defaultLink: ILink = {
  urls: [],
  texts: [],
};

<div
  class="flex h-full w-full flex-row justify-center text-blue-600"
  [style.color]="color()">
  <svg class="mr-3 size-12 animate-spin" viewBox="0 0 24 24">
    <circle
      class="opacity-25"
      cx="12"
      cy="12"
      r="10"
      fill="transparent"
      stroke="currentColor"
      stroke-width="4"></circle>
    <path
      class="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import {
  ObjectOption,
  SelectOption,
} from '../../components/form-inputs/form-input-select.component';

@Pipe({
  name: 'SelectionOptionValueToName',
  standalone: true,
})
export class SelectionOptionValueToNamePipe implements PipeTransform {
  transform(
    value: unknown,
    options: SelectOption[],
    nameAttr: string,
    valueAttr?: string
  ): SelectOption {
    if (valueAttr && nameAttr) {
      const match = options.find(
        o => (o as ObjectOption)?.[valueAttr] == value
      ) as ObjectOption;

      return match && Object.prototype.hasOwnProperty.call(match, nameAttr)
        ? match[nameAttr]
        : value;
    } else if (nameAttr) {
      const match = options.find(
        o =>
          (o as ObjectOption)[nameAttr] == (value as ObjectOption)?.[nameAttr]
      ) as ObjectOption;
      return match && Object.prototype.hasOwnProperty.call(match, nameAttr)
        ? match?.[nameAttr]
        : value;
    }
    return value;
  }

  public findOptionByValue(
    value: unknown,
    options: SelectOption[],
    nameAttr: string,
    valueAttr?: string
  ): SelectOption {
    if (valueAttr && nameAttr) {
      return (
        (options.find(
          o =>
            Object.prototype.hasOwnProperty.call(o, nameAttr) &&
            Object.prototype.hasOwnProperty.call(o, valueAttr) &&
            (o as ObjectOption)?.[valueAttr] == value
        ) as ObjectOption) || value
      );
    } else if (nameAttr) {
      return (
        (options.find(
          o =>
            Object.prototype.hasOwnProperty.call(o, nameAttr) &&
            (o as ObjectOption)[nameAttr] == (value as ObjectOption)?.[nameAttr]
        ) as ObjectOption) || value
      );
    }
    return options.find(o => o == value) as ObjectOption;
  }
}

import {
  Component,
  input,
  InputSignal,
  OnDestroy,
  OnInit,
  output,
  OutputEmitterRef,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormInputComponent } from '../form-input.component';
import { Subscription } from 'rxjs';

export interface IButtonOption<T> {
  label: string;
  value: T;
}

@Component({
  selector: 'app-button-group',
  templateUrl: './button-group.component.html',
  styleUrl: './button-group.component.css',
  imports: [NgClass, FormsModule, ReactiveFormsModule],
})
export class ButtonGroupComponent<T>
  extends FormInputComponent
  implements OnInit, OnDestroy
{
  options: InputSignal<IButtonOption<T>[]> =
    input.required<IButtonOption<T>[]>();
  darkMode = input<boolean>(false);
  public readonly valueChange: OutputEmitterRef<string> = output<string>();
  private readonly sub: Subscription = new Subscription();

  ngOnInit() {
    this.sub.add(
      this.ngControl.control!.valueChanges.subscribe(value => {
        this.valueChange.emit(value);
      })
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  changeActive(option: IButtonOption<T>) {
    this.ngControl.control!.setValue(option.value);
    this.ngControl.control!.updateValueAndValidity();
  }
}
